import React, { useMemo, useState } from "react";
import { TooltipFormatterContextObject } from "highcharts";

import LineChart from "@/components/charts/HighChart.component";
import {
  COLORS,
  makeChartOptions,
} from "@/domain/patient/view/charts/constants";
import { parseTooltipDate } from "@/domain/patient/view/tabs/monitoring/helpers";
import {
  getAlertLevelColor,
  getSeverityAlertLevel,
} from "@/domain/notes/model/constants";
import { Props } from "./types";
import { useSelector } from "react-redux";
import {
  selectMonitoringEndDate,
  selectMonitoringStartDate,
} from "@/domain/patient/redux/selectors";
import { ObservationType } from "@/domain/observations/types";
import { displayObsUnit } from "@/domain/observations/helpers";

export const SpirometryChart: React.FC<Props> = (props) => {
  const { spirometries, patientDetail } = props;
  const isoStartDate = useSelector(selectMonitoringStartDate);
  const isoEndDate = useSelector(selectMonitoringEndDate);
  const [isPrinting, setIsPrinting] = useState(false);

  const commonChartConfig = {
    events: {
      beforePrint: async () => {
        setIsPrinting(true);
      },
      afterPrint: () => {
        setIsPrinting(false);
      },
    },
    chart: {
      type: "line",
      scrollablePlotArea: {
        minWidth: 600,
        scrollPositionX: 1,
      },
      animation: false,
    },
    xAxis: {
      min: isoStartDate,
      max: isoEndDate,
      labels: {
        overflow: "justify",
      },
    },
    colorAxis: [
      {
        showInLegend: false,
        reversed: true,
        labels: {
          format: "{value}L",
        },
      },
      {
        showInLegend: false,
        labels: {
          format: "{value}L",
        },
      },
    ],
    caption: {
      text: isPrinting ? patientDetail : "",
    },
    time: {
      useUTC: false,
    },
  };

  const fevChartConfig = useMemo(() => {
    return makeChartOptions({
      ...commonChartConfig,
      tooltip: {
        useHTML: true,
        valueSuffix: " (L)",
        formatter: function (this: TooltipFormatterContextObject) {
          return parseTooltipDate(ObservationType.Spirometry, this, "L");
        },
      },
      yTitle: (
        displayObsUnit(ObservationType.Spirometry) as {
          pef: string;
          fev1: string;
        }
      ).fev1,
      series: [
        {
          name: "FEV1",
          color: COLORS.primary.line,
          colorAxis: 0,
          data: spirometries.map((sp: any) => {
            const { severityScore, timeOfObservation, _meta } = sp;
            const { created } = _meta;
            let x;
            if (timeOfObservation) {
              x = Date.parse(new Date(timeOfObservation).toISOString());
            }
            if (created) {
              x = Date.parse(new Date(created).toISOString());
            }
            if (timeOfObservation || created) {
              return {
                x,
                y: sp.fev1?.value,
                marker: {
                  fillColor: getAlertLevelColor(
                    getSeverityAlertLevel(severityScore)
                  ),
                },
              };
            }
          }),
        },
      ],
    });
  }, [spirometries, patientDetail, isPrinting]);

  const pefChartConfig = useMemo(() => {
    return makeChartOptions({
      ...commonChartConfig,
      tooltip: {
        useHTML: true,
        valueSuffix: " (L/min)",
        formatter: function (this: TooltipFormatterContextObject) {
          return parseTooltipDate(ObservationType.Spirometry, this, "L/min");
        },
      },
      yTitle: (
        displayObsUnit(ObservationType.Spirometry) as {
          pef: string;
          fev1: string;
        }
      ).pef,
      series: [
        {
          name: "PEF",
          color: COLORS.primary.line,
          colorAxis: 0,
          data: spirometries.map((sp) => {
            const { severityScore, timeOfObservation, _meta } = sp;
            const { created } = _meta;
            let x;
            if (timeOfObservation) {
              x = Date.parse(new Date(timeOfObservation).toISOString());
            }
            if (created) {
              x = Date.parse(new Date(created).toISOString());
            }
            if (timeOfObservation || created) {
              return {
                x,
                y: sp.pef?.value,
                marker: {
                  fillColor: getAlertLevelColor(
                    getSeverityAlertLevel(severityScore)
                  ),
                },
              };
            }
          }),
        },
      ],
    });
  }, [spirometries, patientDetail, isPrinting]);

  return (
    <>
      <LineChart
        containerProps={{ id: "spirometry-chart-pef" }}
        options={pefChartConfig.options}
      />
      <LineChart
        containerProps={{ id: "spirometry-chart-fev" }}
        options={fevChartConfig.options}
      />
    </>
  );
};

export default SpirometryChart;
