import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectFirstPatient } from "@/domain/patient/redux/selectors";
import { Note } from "@/domain/notes/model/types";
import { DateTime } from "luxon";
import { PATIENT_BILLING_REPORT_TIMEZONE } from "@/library/constants";
import { getCurrentDate } from "@/domain/patient/view/tabs/summary/constants";
import { TimeLogTable } from "@/domain/patient/view/tabs/audit/timeLog.component";
import { AuditTimeLogTableHeader } from "@/domain/patient/view/tabs/audit/constants";
import { PaginationConfig } from "@/library/types";
import { PatientModel } from "@/domain/patient/model";
import { DEFAULT_PAGINATION_CONFIG } from "@/components/pagination/constants";
import { dateFormatter } from "@/pipes/date";
import { padNumber } from "@/components/pathway/automations";
import { PatientNoteType } from "@/library/types/note";

type Props = {
  setNotePageLimit?: (limit: number) => void;
  setNotesPaginationConfig?: (paginationConfig: PaginationConfig) => void;
};

export interface TimeLogData {
  id: number;
  timeStamp: string;
  user: string;
  timeDuration: string;
  billable: boolean;
  billableAccumulatedTime: string;
  totalAccumulatedTime: string;
}

export const AuditTab: React.FC<Props> = (props) => {
  const patient = useSelector(selectFirstPatient);
  const [pageLimit, setPageLimit] = useState<number>(50);
  const [paginationConfig, setPaginationConfig] = useState<PaginationConfig>(
    DEFAULT_PAGINATION_CONFIG
  );
  const [allTimeLogNotes, setAllTimeLogNotes] = useState([] as TimeLogData[]);
  const [paginatedTimeLogNotes, setPaginatedTimeLogNotes] = useState(
    [] as TimeLogData[]
  );
  const getBillingPeriod = () => {
    const start = DateTime.fromJSDate(getCurrentDate, {
      zone: PATIENT_BILLING_REPORT_TIMEZONE,
    }).startOf("month");
    const end = start.plus({ month: 1 });
    return [start, end];
  };
  const getTimeLogNotes = async (
    query: Record<string, unknown>
  ): Promise<[TimeLogData[], PaginationConfig]> => {
    const [startDate, endDate] = getBillingPeriod();
    const [notes, paginationConfig] = await PatientModel.getNotes(
      patient?._meta?.id!,
      startDate.toISO(),
      endDate.toISO(),
      [
        PatientNoteType.Timelog,
        PatientNoteType.Clinical,
        PatientNoteType.Assessment,
        PatientNoteType.Timelog,
        PatientNoteType.Assessment,
        PatientNoteType.Clinical,
        PatientNoteType.Observation,
        PatientNoteType.Reviewed,
      ],
      { ...query, limit: 500 }
    );
    const sorted = notes
      .sort((a, b) => {
        return a._meta?.created &&
          b._meta?.created &&
          a._meta?.created > b._meta?.created
          ? -1
          : 1;
      })
      .filter((note) => note.author !== undefined && note.seconds !== 0);
    let index = 0;
    let totalSeconds = 0;
    let totalBillableSeconds = 0;

    let cumulativeHours = 0;
    let cumulativeMinutes = 0;
    let cumulativeSeconds = 0;

    let cumulativeBillableHours = 0;
    let cumulativeBillableMinutes = 0;
    let cumulativeBillableSeconds = 0;

    const timeLogData = sorted.map((note: Note) => {
      totalSeconds =
        totalSeconds + (note.minutes ?? 0) * 60 + (note.seconds ?? 0);
      cumulativeHours = Math.floor(totalSeconds / 3600);
      cumulativeMinutes = Math.floor((totalSeconds % 3600) / 60);
      cumulativeSeconds = totalSeconds % 60;

      if (note.author?.canAccumulateBillableTime) {
        totalBillableSeconds =
          totalBillableSeconds + (note.minutes ?? 0) * 60 + (note.seconds ?? 0);

        cumulativeBillableHours = Math.floor(totalBillableSeconds / 3600);
        cumulativeBillableMinutes = Math.floor(
          (totalBillableSeconds % 3600) / 60
        );
        cumulativeBillableSeconds = totalBillableSeconds % 60;
      }
      const timeStamp = dateFormatter({
        date: note._meta?.created,
        includeTime: true,
        toLocalTimezone: { enabled: true },
      });
      const user = note.author?.name;
      const timeDuration =
        padNumber(
          Math.floor(((note.minutes ?? 0) * 60 + (note.seconds ?? 0)) / 60)
        ) +
        ":" +
        padNumber((note.seconds ?? 0) % 60);
      const billable = note.author?.canAccumulateBillableTime ? "yes" : "no";
      const billableAccumulatedTime =
        padNumber(cumulativeBillableHours) +
        ":" +
        padNumber(cumulativeBillableMinutes) +
        ":" +
        padNumber(cumulativeBillableSeconds);
      const totalAccumulatedTime =
        padNumber(cumulativeHours) +
        ":" +
        padNumber(cumulativeMinutes) +
        ":" +
        padNumber(cumulativeSeconds);
      return {
        id: index++,
        timeStamp,
        user,
        timeDuration,
        billable,
        billableAccumulatedTime,
        totalAccumulatedTime,
      } as unknown as TimeLogData;
    });
    return [timeLogData, paginationConfig];
  };

  const getChunkedNotes = (notes: TimeLogData[], limit: number) => {
    const recordsPerPage = [];
    for (let i = 0; i < notes.length; i += limit) {
      recordsPerPage.push(notes.slice(i, i + limit));
    }
    return recordsPerPage;
  };

  useEffect(() => {
    (async () => {
      const [allTimeLogNotes, config] = await getTimeLogNotes({});
      setAllTimeLogNotes(allTimeLogNotes);
      // split note records by the number of records to be displayed per page
      const recordsPerPage = getChunkedNotes(allTimeLogNotes, pageLimit);
      setPaginatedTimeLogNotes(recordsPerPage[0]);
      setPaginationConfig({
        ...config,
        lastPage: recordsPerPage.length,
      });
    })();
  }, []);
  useEffect(() => {
    (async () => {
      await execTimeLogSearch({ currentPage: 1 }, pageLimit);
    })();
  }, [pageLimit]);
  const execTimeLogSearch = async (
    query: Record<string, unknown>,
    limit?: number
  ): Promise<void> => {
    const recordsPerPage = getChunkedNotes(allTimeLogNotes, limit || pageLimit);
    const currentPage = query["currentPage"] as number;
    if (query.hasOwnProperty("currentPage")) {
      const index = currentPage - 1;
      const notes = recordsPerPage[index];
      setPaginatedTimeLogNotes(notes);
    }
    setPaginationConfig({
      ...paginationConfig,
      currentPage,
      lastPage: recordsPerPage.length,
    });
  };
  const handlePageLimitChange = async (limit: number) => {
    await setPageLimit(limit);
  };
  return (
    <>
      <div>
        <TimeLogTable
          headers={AuditTimeLogTableHeader}
          data={paginatedTimeLogNotes}
          onNavigate={execTimeLogSearch}
          onPageLimitChange={handlePageLimitChange}
          pageLimit={pageLimit}
          paginationConfig={paginationConfig}
        />
      </div>
    </>
  );
};
