import React, { useCallback, useEffect, useState } from "react";
import { useStopwatch } from "react-timer-hook";
import { CountdownProps, TimerColors, TimerTextColor } from "./types";

import cx from "clsx";
import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";
import { convertToTwoDigits, IDLE_TIME, INACTIVE_TIME } from "./constants";
import { TimerState } from "./types";
import { useEventListener } from "@/hooks/useEventListener";

const Countdown: React.FC<CountdownProps> = ({
  running,
  offsetTimestamp,
  timerActivityState,
  timerColor,
  onClick,
  onIdle,
  onInactiveStopped,
  connectToTimerState,
  onActiveUser,
  ...props
}) => {
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({
      autoStart: running,
      offsetTimestamp: offsetTimestamp || new Date(),
    });

  const timerState: TimerState = {
    seconds,
    minutes,
    hours,
    isRunning,
  };

  const [idleTime, setIdleTime] = useState(0);

  const onTimerClick = () => {
    setIdleTime(0);
    onClick(timerState);
  };

  /**
   * Handle Inactivity
   */
  const handleMouseMovements = () => {
    if (idleTime < INACTIVE_TIME && idleTime > IDLE_TIME - 10) {
      setIdleTime(0);
      if (timerColor === TimerColors.Yellow) {
        onActiveUser(timerState);
      }
    }
  };

  useEventListener("mousemove", handleMouseMovements);

  const handleMouseClick = (e: any) => {
    setIdleTime(0);
    if (e.target.id === "timer") return;
    onActiveUser(timerState);
  };

  useEventListener("click", handleMouseClick);

  const handleKeyboardPress = () => {
    setIdleTime(0);
    onActiveUser(timerState);
  };

  useEventListener("keydown", handleKeyboardPress);

  useEffect(() => {
    // negating this to allow for idleTime to start counting when timer starts
    setIdleTime(-1);
    onActiveUser(timerState);
  }, []);

  useEffect(() => {
    if (idleTime < INACTIVE_TIME) {
      setIdleTime((prev) => prev + 1);
    }

    connectToTimerState(timerState);
  }, [seconds]);

  useEffect(() => {
    if (idleTime === INACTIVE_TIME) {
      onInactiveStopped(timerState);
    }

    if (idleTime === IDLE_TIME) {
      onIdle(timerState);
    }
  }, [idleTime]);

  useEffect(() => {
    if (running) start();
    if (!running) pause();
  }, [running]);

  /**
   * Set timer color
   * @returns
   */

  const colorsWithBlackText = [TimerColors.Red, TimerColors.Yellow];

  const getTextColor = () => {
    if (colorsWithBlackText.includes(timerColor)) {
      return TimerTextColor.Black;
    }
    return TimerTextColor.White;
  };

  const timerStyle: React.CSSProperties = {
    background: timerColor,
    color: getTextColor(),
  };

  return (
    <>
      <button
        className={cx(
          globalStyles.buttonReset,
          styles.timerFont,
          "badge",
          "p-2"
        )}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onTimerClick();
        }}
        style={timerStyle}
        type="button"
        id="timer"
      >
        {convertToTwoDigits(minutes)}:{convertToTwoDigits(seconds)}
      </button>
    </>
  );
};

export default Countdown;
