import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { PATIENT_TABS } from "@/library/constants";

//Styles
import "./styles.module.scss";
import { PatientTab } from "@/domain/patient/view/PatientDetails/types";

const PatientTabs = ({ handleTabClick, activeTab }: any) => {
  const [value, setValue] = useState(PatientTab.Profile);

  useEffect(() => {
    setValue(activeTab);
  }, [activeTab]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} variant="scrollable">
          {PATIENT_TABS.map((data: any, index: any) => (
            <Tab
              label={data?.label}
              value={data?.tab}
              key={index}
              onClick={() => handleTabClick(data?.tab)}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default PatientTabs;
