import { useEffect, useState } from "react";
import { format } from "date-fns";
import { Patient, PatientPriority } from "../../model/types";
import styles from "./styles.module.scss";
import Tooltip from "@mui/material/Tooltip";
import { Menu, MenuItem } from "@mui/material";
import cx from "clsx";
import {
  ShowConfirmModal,
  currentUserInPatientWatchList,
  getAssessmentBG,
  getObservationsBG,
  getToolTip,
  handleAccept,
  handleCommentChange,
  handleReturnKitModalSubmit,
  onWatchlistClick,
} from "./helpers";
import { truncateObservationValue } from "@/domain/observations/helpers";
import { ObservationType } from "@/domain/observations/types";
import { AlertLevelColor } from "@/domain/notes/model/types";
import {
  getEncounterDays,
  getRecentStatusDate,
} from "../PatientBannerDetails/helpers";
import { calculateAge } from "../tabs/summary/constants";
import { ServiceLevel } from "@/domain/kits/models/device.interface";
import { CreateNoteModal, NoteFormType } from "../CreateNoteModal";
import { Modal } from "@/components/modal";
import { Notification } from "@/components/notification/notification";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { KitReturnModal } from "@/domain/kits/view/KitReturnModal";
import { SUCCESS } from "@/library/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchDeselectAllPatients,
  dispatchSelectPatient,
  dispatchSetPatients,
  dispatchUpdatePatient,
} from "../../redux/actions";
import { PatientModel } from "../../model";
import { RootState } from "@/types";
import { PatientState, getPatientNextState } from "../form/manageState/types";
import { KitShipmentFormValues } from "@/domain/kits/view/KitShipmentForm/types";
import { FormikHelpers } from "formik";
import { dateFormatter } from "@/pipes/date";
import {
  STALE_READING_TOOLTIP_MESSAGE,
  getBannerColorByLastObservation,
} from "../PatientBanners/constants";

const TIME_FORMAT = "HH:mm";
const DATE_FORMAT = "MM/dd/yy";
const PatientStateText: {
  ADDED: string;
  PREACTIVE: string;
  ACTIVE: string;
  INACTIVE: string;
  COMPLETED: string;
} = {
  ADDED: "Mark as Added",
  PREACTIVE: "Mark as Pre-Active",
  ACTIVE: "Mark as Active",
  INACTIVE: "Mark as Inactive",
  COMPLETED: "Mark as Completed",
};
interface ObservationTile {
  title: string;
  subTitle: string;
  img: any;
  primaryBG: string;
  secondaryBG: string;
  toolTip: string;
}

const PatientBannerRow = ({
  patient,
  gotoProfile,
  gotoMonitoring,
  onVirtualVisit,
  includeTimer,
}: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { provider, demographics } = patient || {};
  const { legalName, dateOfBirth, preferredName } = demographics || {};
  const { firstName, lastName } = legalName || {};
  const [videoCallStatus, setVideoCallStatus] = useState(false);
  const [showClinicalNoteModal, setShowClinicalNoteModal] = useState(false);
  const [showBiometricModal, setShowBiometricModal] = useState(false);
  const [showWatchlistModal, setShowWatchlistModal] = useState(false);
  const [showRiskLevelModal, setShowRiskLevelModal] = useState(false);
  const [selectedRiskLevel, setSelectedRiskLevel] = useState(
    PatientPriority.None
  );
  const [showPathwayAssessmentModal, setShowPathwayAssessmentModal] =
    useState(false);
  const [showReturnKitModal, setShowReturnKitModal] = useState(false);
  const hasTimerInNotes = !includeTimer;
  const dispatch = useDispatch();
  const patients = useSelector((state: RootState) => state.patient.patients);
  const selectedPatient = useSelector(
    (state: RootState) => state.patient.selectedPatients
  );
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const [selectedStatus, setSelectedStatus] = useState<
    PatientState | undefined
  >();
  const [disableAcceptBtn, setDisableAcceptBtn] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  let dateDob = new Date(dateOfBirth).toLocaleDateString("es-CL");
  let medicalConditions = patient?.conditions
    ?.map((item: any) => item.displayName)
    .join(", ");
  let serviceLevel = patient?.deviceInfo
    ?.map((item: any) => (item.msn || "-").slice(-5))
    .join(", ");
  const profileInfo1 = [
    `DOB: ${dateDob.replaceAll("-", "/")}`,
    `${calculateAge(dateOfBirth)} Y`,
    `${medicalConditions}`,
  ];
  const profileInfo2 = [
    `RCL - ${serviceLevel ?? "Not available"}`,
    `${patient?.practice?.name}`,
  ];
  const assessmentBG = getAssessmentBG(patient);
  const heartRateBG = getObservationsBG(
    patient?.health?.observations?.heartRate
  );
  const bloodPressureBG = getObservationsBG(
    patient?.health?.observations?.bloodPressure
  );
  const pulseBG = getObservationsBG(
    patient?.health?.observations?.pulseOximetry
  );
  const weightBG = getObservationsBG(patient?.health?.observations?.weight);
  const glucoseBG = getObservationsBG(patient?.health?.observations?.glucose);
  const temperatureBG = getObservationsBG(
    patient?.health?.observations?.temperature
  );
  const spirometryBG = getObservationsBG(
    patient?.health?.observations?.spirometry
  );
  const videoCallDisabled =
    !patient.deviceInfo ||
    (patient.deviceInfo &&
      patient.deviceInfo[0]?.serviceLevel !== ServiceLevel.RemoteCareLive);
  const patientNotes =
    patient.notes?.filter((note: any) => {
      if ("requestType" in note) {
        if (
          !note.isComplete &&
          note.type === "PATIENT_REQUEST" &&
          note.requestType === "VIDEO"
        ) {
          return note;
        }
      }
    }) || [];

  useEffect(() => {
    setVideoCallStatus(patientNotes.length >= 1);
  }, [patientNotes]);

  const patientObservations = [
    {
      title: patient?.health?.assessment?.time
        ? format(new Date(patient?.health?.assessment?.time), TIME_FORMAT)
        : "-",
      subTitle: patient?.health?.assessment?.time
        ? format(new Date(patient?.health?.assessment?.time), DATE_FORMAT)
        : "-",
      img: require("../../../../assets/icons/observations/icassessment.svg"),
      primaryBG: assessmentBG.primary,
      secondaryBG: assessmentBG.secondary,
      toolTip: patient?.health?.assessment ? (
        <>
          <div>{patient?.health?.assessment?.name ?? ""}</div>
          <div>
            {dateFormatter({
              date: patient?.health?.assessment?.time,
              includeTime: true,
              toLocalTimezone: { enabled: true },
            })}{" "}
          </div>
        </>
      ) : (
        ""
      ),
    },
    {
      title: patient?.health?.observations?.bloodPressure
        ? Math.trunc(
            patient?.health?.observations?.bloodPressure?.observation?.systolic
              ?.value
          ) +
          "/" +
          Math.trunc(
            patient?.health?.observations?.bloodPressure?.observation?.diastolic
              ?.value
          )
        : "-",
      subTitle: "mmHg",
      img: require("../../../../assets/icons/observations/icHR.svg"),
      primaryBG: bloodPressureBG.primary,
      secondaryBG: bloodPressureBG.secondary,
      toolTip: getToolTip(patient?.health?.observations?.bloodPressure?.time),
    },
    {
      title: truncateObservationValue(
        ObservationType.HeartRate,
        patient?.health?.observations?.heartRate?.observation?.value
      ),
      subTitle: "bpm",
      img: require("../../../../assets/icons/observations/icBP.svg"),
      primaryBG: heartRateBG.primary,
      secondaryBG: heartRateBG.secondary,
      toolTip: getToolTip(patient?.health?.observations?.heartRate?.time),
    },
    {
      title: truncateObservationValue(
        ObservationType.PulseOximetry,
        patient?.health?.observations?.pulseOximetry?.observation?.value
      ),
      subTitle: "%",
      img: require("../../../../assets/icons/observations/icOL.svg"),
      primaryBG: pulseBG.primary,
      secondaryBG: pulseBG.secondary,
      toolTip: getToolTip(patient?.health?.observations?.pulseOximetry?.time),
    },
    {
      title: truncateObservationValue(
        ObservationType.Weight,
        patient?.health?.observations?.weight?.observation?.value
      ),
      subTitle: "lbs",
      img: require("../../../../assets/icons/observations/icWT.svg"),
      primaryBG: weightBG.primary,
      secondaryBG: weightBG.secondary,
      toolTip: getToolTip(patient?.health?.observations?.weight?.time),
    },
    {
      title: truncateObservationValue(
        ObservationType.GlucoseLevel,
        patient?.health?.observations?.glucose?.observation?.value
      ),
      subTitle: "mg/dL",
      img: require("../../../../assets/icons/observations/icBG.svg"),
      primaryBG: glucoseBG.primary,
      secondaryBG: glucoseBG.secondary,
      toolTip: getToolTip(patient?.health?.observations?.glucose?.time),
    },
    {
      title: truncateObservationValue(
        ObservationType.Temperature,
        patient?.health?.observations?.temperature?.observation?.value
      ),
      subTitle: `${"\u00b0"}F`,
      img: require("../../../../assets/icons/observations/icTP.svg"),
      primaryBG: temperatureBG.primary,
      secondaryBG: temperatureBG.secondary,
      toolTip: getToolTip(patient?.health?.observations?.temperature?.time),
    },
    {
      // TODO: UX not finalized yet
      // title: `PEF: ${observations?.spirometry?.observation?.pef?.value ?? '-'}, FEV1: ${observations?.spirometry?.observation?.fev1?.value?.toFixed(2) ?? '-'}`,
      title: "-",
      subTitle: "L/min - L",
      img: require("../../../../assets/icons/observations/icSP.svg"),
      primaryBG: spirometryBG.primary,
      secondaryBG: spirometryBG.secondary,
      toolTip: getToolTip(patient?.health?.observations?.spirometry?.time),
    },
  ];

  const options = [
    {
      ic: require("../../../../assets/icons/observations/icCreateNote.svg"),
      label: "Create Chart Note",
      handleClick: () => setShowClinicalNoteModal(true),
    },
    {
      ic: require("../../../../assets/icons/observations/icManualBiometricEntry.svg"),
      label: "Manual Biometric Entry",
      handleClick: () => setShowBiometricModal(true),
    },
    {
      ic: require("../../../../assets/icons/observations/icManualPathway.svg"),
      label: "Manual Pathway Assessment",
      handleClick: () => setShowPathwayAssessmentModal(true),
    },
    {
      ic: require("../../../../assets/icons/observations/icKit.svg"),
      label: "Return Kit",
      handleClick: () => setShowReturnKitModal(true),
    },
    {
      ic: require("../../../../assets/icons/observations/icRiskLevel.svg"),
      label: "Risk Level",
    },
    {
      label: "Mark",
    },
  ];

  const updatePatientRiskLevel = async (priority: PatientPriority) => {
    LoadingIndicator.fire.show();
    try {
      const patientPriorty = priority === "NONE" ? null : priority;
      await PatientModel.make(patient).modifyPatient<Patient, "priority">({
        priority: patientPriorty as string,
      });
      await updateReduxState(patientPriorty);
      Notification.notify(SUCCESS, `Risk level updated successfully. `);
    } catch (e) {}

    LoadingIndicator.fire.hide();
  };

  const updateReduxState = async (priority: PatientPriority | null) => {
    const patientToBeEdited = patients.find(
      (entity: any) => entity.id === patient.id
    );
    const editedPatient = { ...patientToBeEdited, ...{ priority } };

    updatePatientDetailState({
      ...patient,
      priority: priority as string,
    });
    const updated = patients.map((patient: Patient) => {
      return patient.id === editedPatient.id ? editedPatient : patient;
    });
    dispatch(dispatchSetPatients(updated as Patient[]));
  };

  const updatePatientDetailState = (editedPatient: Partial<Patient>) => {
    if (selectedPatient && selectedPatient.length > 0) {
      selectedPatient.forEach((patient: Patient) => {
        if (patient.id === editedPatient.id) {
          patient = { ...patient, ...editedPatient };
          dispatch(dispatchDeselectAllPatients());
          dispatch(dispatchSelectPatient(patient));
        }
      });
    }
  };

  const patientNextState = getPatientNextState(
    patient.lifecycle.state as PatientState
  );

  const handleChooseStatus = (selectedState: PatientState): void => {
    setSelectedStatus(selectedState);
    selectedState === PatientState.Completed && setShowReturnKitModal(true);
    setShowConfirmModal(selectedState !== PatientState.Completed);
    setDisableAcceptBtn(false);
  };

  return (
    <li key={patient.id}>
      <div
        className={styles.patientRow}
        style={{
          borderColor:
            patient?.priority === PatientPriority.Low
              ? "#00853E"
              : patient?.priority === PatientPriority.Medium
              ? "#E8BB1C"
              : patient?.priority === PatientPriority.High
              ? "#F76363"
              : "#030575",
        }}
      >
        <button
          type="button"
          className={cx("col-4 ", styles.patientRowInfo)}
          onClick={gotoProfile}
        >
          <div className={"d-flex"}>
            <div className={styles.ptName}>
              {`${firstName} ${lastName}`}
              {preferredName &&
                preferredName !== "null" &&
                ", " + preferredName}
            </div>
          </div>
          <div className={cx("d-flex", "flex-wrap")}>
            <div className={styles.ptStatusDiv1}>
              <div className={styles.ptText12}>{`ID - ${
                patient?.encounter?.id || "-"
              }`}</div>
            </div>
            {profileInfo1.map((pract) => profileDetails(pract))}
          </div>
          <div className={cx("d-flex", "flex-wrap")}>
            <Tooltip
              placement={"top"}
              arrow={true}
              title={`${getRecentStatusDate(
                patient?.lifecycle
              )} / ${getEncounterDays(patient?.lifecycle)}`}
            >
              <div
                className={
                  patient?.lifecycle?.state === PatientState.Active
                    ? styles.ptStatusDiv2
                    : patient?.lifecycle?.state === PatientState.Inactive
                    ? styles.ptStatusDiv3
                    : styles.ptStatusDiv1
                }
              >
                <div
                  className={
                    patient?.lifecycle?.state === PatientState.Active
                      ? styles.ptText12Active
                      : patient?.lifecycle?.state === PatientState.Inactive
                      ? styles.ptText12Inactive
                      : styles.ptText12
                  }
                >
                  {patient?.lifecycle?.state}
                </div>
              </div>
            </Tooltip>
            {profileInfo2.map((pract) => profileDetails(pract))}
          </div>
          <div className="d-flex">
            <div className={cx(styles.ptStatusDiv1)}>
              <div className={styles.ptText12}>
                {`${provider?.firstName} ${provider?.lastName}, ${provider?.credentials}`}
              </div>
              <div
                className={styles.ptText12}
              >{`${provider?.phoneNumber}`}</div>
            </div>
            <div className={styles.reviewDiv}>
              <button className={styles.defaultBg}>
                <img
                  className={styles.checkDiv}
                  style={{
                    background: !patient?.isReviewed ? "#ffffff" : "#030575",
                  }}
                  src={
                    require("../../../../assets/icons/observations/check.svg")
                      .default
                  }
                  alt={"check"}
                />
              </button>
              <div className={cx(styles.ptText12, " ms-2 mb-1")}>Reviewed</div>
            </div>
          </div>
        </button>
        <div className="d-flex">
          {patientObservations.map((Observation) =>
            showPatientObservations(
              Observation as ObservationTile,
              gotoMonitoring
            )
          )}
        </div>
        <div className="d-flex">
          <div className={cx(styles.sideBorder)} style={{ display: "grid" }}>
            <Tooltip
              placement={"top"}
              arrow={true}
              title={"Feature coming soon."}
            >
              <button
                className={styles.bgCallDis}
                style={{ cursor: "not-allowed" }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <img
                  src={
                    require("../../../../assets/icons/observations/icCallH.svg")
                      .default
                  }
                  alt={"call"}
                />
                <img
                  className={styles.war}
                  src={
                    require("../../../../assets/icons/observations/icWR.svg")
                      .default
                  }
                  alt={"warning"}
                />
              </button>
            </Tooltip>
            <Tooltip
              placement={"top"}
              arrow={true}
              title={
                videoCallDisabled
                  ? "Patient does not have a video-capable device"
                  : ""
              }
            >
              <button
                className={cx(
                  videoCallDisabled ? styles.bgCallDis : styles.bgWhite,
                  styles.topBorder
                )}
                style={{
                  cursor: videoCallDisabled ? "not-allowed" : "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onVirtualVisit();
                }}
              >
                <img
                  className={cx(styles.vcall)}
                  src={
                    require("../../../../assets/icons/observations/icVcalls.svg")
                      .default
                  }
                  alt={"videocall"}
                />
                {videoCallDisabled && (
                  <img
                    className={styles.callWar}
                    src={
                      require("../../../../assets/icons/observations/icWR.svg")
                        .default
                    }
                    alt={"warning"}
                  />
                )}
              </button>
            </Tooltip>
          </div>
          <textarea
            defaultValue={patient?.comments}
            className={styles.textComment}
            placeholder="Enter comment..."
            maxLength={250}
            name="comment"
            rows={5}
            onBlur={(e) => handleCommentChange(e.target.value || "", patient)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <div className={cx("mr-2")} style={{ display: "grid" }}>
            <div className="align-content-center">
              <button className={styles.bgWhite} onClick={handleClick}>
                <img
                  className={cx(styles.paddingIC, " align-self-center")}
                  src={
                    require("../../../../assets/icons/observations/icmore.svg")
                      .default
                  }
                  alt={"more"}
                />
              </button>
            </div>
            <Menu
              disableAutoFocusItem
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.label}
                  onClick={handleClose}
                  disableRipple
                  sx={[{ "&:hover": { backgroundColor: "transparent" } }]}
                >
                  {option.label === "Mark" &&
                  patient?.lifecycle?.state !== PatientState.Completed ? (
                    <div className={cx("d-flex", styles.fullWidth)}>
                      {patientNextState.map((state, index) => {
                        return (
                          <button
                            key={state}
                            className={cx(
                              styles.menuOptionText,
                              index === 0 ? styles.borderRight : ""
                            )}
                            onClick={() => handleChooseStatus(state)}
                          >
                            {PatientStateText[state]}
                          </button>
                        );
                      })}
                    </div>
                  ) : option.label === "Risk Level" ? (
                    <div className={styles.borderTopBt}>
                      <div className="d-flex mb-1">
                        <img
                          className={styles.menuIc}
                          src={option.ic.default}
                          alt={"more"}
                        />
                        <div className={styles.menuTitle}>{option.label}</div>
                      </div>
                      <div className="d-flex">
                        <button
                          className={cx("d-flex", styles.radioButton)}
                          onClick={() => {
                            setSelectedRiskLevel(PatientPriority.High);
                            setShowRiskLevelModal(true);
                          }}
                        >
                          <img
                            className={styles.menuIc}
                            alt={"raido"}
                            src={
                              patient?.priority === PatientPriority.High
                                ? require("../../../../assets/icons/observations/radioOn.svg")
                                    .default
                                : require("../../../../assets/icons/observations/radioOff.svg")
                                    .default
                            }
                          />
                          <div className={styles.menuSbTitle}>High</div>
                        </button>
                        <button
                          className={cx("d-flex", styles.radioButton)}
                          onClick={() => {
                            setSelectedRiskLevel(PatientPriority.Medium);
                            setShowRiskLevelModal(true);
                          }}
                        >
                          <img
                            className={styles.menuIc}
                            alt={"raido"}
                            src={
                              patient?.priority === PatientPriority.Medium
                                ? require("../../../../assets/icons/observations/radioOn.svg")
                                    .default
                                : require("../../../../assets/icons/observations/radioOff.svg")
                                    .default
                            }
                          />
                          <div className={styles.menuSbTitle}>Medium</div>
                        </button>
                        <button
                          className={cx("d-flex", styles.radioButton)}
                          onClick={() => {
                            setSelectedRiskLevel(PatientPriority.Low);
                            setShowRiskLevelModal(true);
                          }}
                        >
                          <img
                            className={styles.menuIc}
                            alt={"raido"}
                            src={
                              patient?.priority === PatientPriority.Low
                                ? require("../../../../assets/icons/observations/radioOn.svg")
                                    .default
                                : require("../../../../assets/icons/observations/radioOff.svg")
                                    .default
                            }
                          />
                          <div className={styles.menuSbTitle}>Low</div>
                        </button>
                        <button
                          className={cx("d-flex", styles.radioButton)}
                          onClick={() => {
                            setSelectedRiskLevel(PatientPriority.None);
                            setShowRiskLevelModal(true);
                          }}
                        >
                          <img
                            className={styles.menuIc}
                            alt={"raido"}
                            src={
                              patient?.priority == null
                                ? require("../../../../assets/icons/observations/radioOn.svg")
                                    .default
                                : require("../../../../assets/icons/observations/radioOff.svg")
                                    .default
                            }
                          />
                          <div className={styles.menuSbTitle}>None</div>
                        </button>
                      </div>
                    </div>
                  ) : option.label !== "Mark" ? (
                    <button
                      className={cx("d-flex p-0", styles.bgWhite)}
                      onClick={option.handleClick}
                    >
                      <img
                        className={styles.menuIc}
                        src={option.ic.default}
                        alt={"info"}
                      />
                      <div className={styles.menuTitle}>{option.label}</div>
                    </button>
                  ) : null}
                </MenuItem>
              ))}
            </Menu>
            <button
              className={styles.watchListIc}
              onClick={(e: any) => {
                e.stopPropagation();
                setShowWatchlistModal(true);
              }}
            >
              <img
                className=" align-self-center"
                src={
                  require("../../../../assets/icons/observations/icWatchlist.svg")
                    .default
                }
                alt={"next"}
                style={{
                  opacity: currentUserInPatientWatchList(currentUser, patient)
                    ? 1
                    : 0.3,
                }}
              />
            </button>
          </div>
        </div>
      </div>
      <ShowConfirmModal
        open={showWatchlistModal}
        label={
          currentUserInPatientWatchList(currentUser, patient)
            ? "Are you sure you want to remove this patient from watchlist?"
            : "Are you sure you want to add this patient to watchlist?"
        }
        onCancel={() => setShowWatchlistModal(false)}
        onProceed={() => {
          onWatchlistClick(dispatch, currentUser, patient, patients);
          setShowWatchlistModal(false);
        }}
      />
      <ShowConfirmModal
        open={showRiskLevelModal}
        label={`Are you sure you want to change risk level of this patient to '${selectedRiskLevel}'?`}
        onCancel={() => setShowRiskLevelModal(false)}
        onProceed={() => {
          updatePatientRiskLevel(selectedRiskLevel);
          setShowRiskLevelModal(false);
        }}
      />
      <CreateNoteModal
        formType={NoteFormType.ClinicalNote}
        includeNoteTimer={hasTimerInNotes}
        show={showClinicalNoteModal}
        patient={patient}
        handleClose={() => setShowClinicalNoteModal(false)}
      />
      <CreateNoteModal
        formType={NoteFormType.ObservationNote}
        includeNoteTimer={hasTimerInNotes}
        show={showBiometricModal}
        patient={patient}
        handleClose={() => setShowBiometricModal(false)}
      />
      <CreateNoteModal
        formType={NoteFormType.AssessmentNote}
        includeNoteTimer={hasTimerInNotes}
        show={showPathwayAssessmentModal}
        patient={patient}
        handleClose={() => setShowPathwayAssessmentModal(false)}
      />
      {showReturnKitModal && (
        <KitReturnModal
          open={showReturnKitModal}
          onClose={() => setShowReturnKitModal(false)}
          onSubmit={(
            values: KitShipmentFormValues,
            form: FormikHelpers<KitShipmentFormValues>
          ) =>
            handleReturnKitModalSubmit(
              values,
              form,
              patient,
              async () => setShowReturnKitModal(false),
              dispatch
            )
          }
          patient={patient}
        />
      )}
      <Modal
        size={"xs"}
        handleClose={() => setShowConfirmModal(false)}
        show={showConfirmModal}
        heading={"Confirm change status"}
        button={"Accept"}
      >
        {showConfirmModal && (
          <>
            <p
              style={{
                padding: "10px",
                fontSize: "17px",
              }}
            >
              You are about to change the patient's status to{" "}
              <b>{selectedStatus}</b>.
            </p>
            <div className="row">
              <div className="col-12 mt-4 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={() => setShowConfirmModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={disableAcceptBtn}
                  onClick={() =>
                    handleAccept(
                      setDisableAcceptBtn,
                      selectedStatus,
                      patient,
                      setShowConfirmModal,
                      dispatch,
                      patients
                    )
                  }
                  className="btn btn-primary mr-2"
                >
                  Accept
                </button>
              </div>
            </div>
          </>
        )}
      </Modal>
    </li>
  );
};

const showPatientObservations = (
  Observation: ObservationTile,
  gotoMonitoring: () => void
) => {
  return (
    <Tooltip placement={"top"} arrow={true} title={Observation.toolTip}>
      <button
        className={
          Observation.primaryBG === AlertLevelColor.NO_ALERT
            ? cx(styles.patientRowInf, styles.borderBlack)
            : Observation.primaryBG === AlertLevelColor.BLACK_ALERT
            ? cx(styles.patientRowInf, styles.borderDoted)
            : styles.patientRowInf
        }
        onClick={gotoMonitoring}
      >
        <div
          className={styles.rowInfoIc}
          style={{
            background:
              Observation.primaryBG === AlertLevelColor.BLACK_ALERT
                ? "white"
                : Observation.primaryBG,
          }}
        >
          <img
            className={cx(styles.actIc, " align-self-center")}
            src={Observation.img.default}
            alt={"next"}
          />
        </div>
        <div
          style={{
            background:
              Observation.secondaryBG === AlertLevelColor.BLACK_ALERT
                ? "white"
                : Observation.secondaryBG,
            borderRadius: 4,
            height: "60%",
            textAlign: "center",
            placeContent: "center",
          }}
        >
          <div className={styles.patientTopTitle}>{Observation.title}</div>
          <div className={styles.resultLbl}>{Observation.subTitle}</div>
        </div>
      </button>
    </Tooltip>
  );
};

export const profileDetails = (label: string) => {
  return label !== "" ? (
    <div className={styles.ptStatusDiv1}>
      <div className={styles.ptText12}>{label}</div>
    </div>
  ) : null;
};

export default PatientBannerRow;
