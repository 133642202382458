import { Selector } from "react-redux";
import { createSelector } from "reselect";
import { DateTime } from "luxon";

import { Patient, PatientObservation } from "@/domain/patient/model/types";
import {
  selectFirstPatient,
  selectMonitoringEndDate,
  selectMonitoringStartDate,
} from "@/domain/patient/redux/selectors";
import { PatientId } from "@/domain/patient/redux/types";
import { RootState } from "@/types";
import { Note, NoteDisposition } from "@/domain/notes/model/types";
import { PatientNoteTypes } from "@/domain/notes/model/constants";

export const selectNotes = (state: RootState) => state.notes.notes;
export const selectNotesByPatientId = (state: RootState) =>
  state.notes.notesByPatientId;
export const selectUnresolvedNotesByPatientId = (state: RootState) =>
  state.notes.unresolvedNotesByPatientId;
export const selectRejectedNoteIds = (state: RootState) =>
  state.notes.rejectedNoteIds;

export const selectNotesForPatient = (id: PatientId) => (state: RootState) =>
  state.notes.notesByPatientId[id] || [];
export const selectUnresolvedNotesForPatient =
  (id: PatientId) => (state: RootState) =>
    state.notes.unresolvedNotesByPatientId[id] || [];

export const selectFirstPatientNotes = createSelector<
  [
    Selector<RootState, Patient | null>,
    Selector<RootState, Record<PatientId, Note[]>>
  ],
  Note[]
>([selectFirstPatient, selectNotesByPatientId], (patient, notesByPatientId) => {
  if (!patient || !patient.id) return [];

  return notesByPatientId[patient.id] || [];
});

export const selectObservationsFromPatientNotes = createSelector<
  [
    Selector<RootState, Note[]>,
    Selector<RootState, string>,
    Selector<RootState, string>,
    Selector<RootState, string[]>
  ],
  PatientObservation[]
>(
  [
    selectFirstPatientNotes,
    selectMonitoringStartDate,
    selectMonitoringEndDate,
    selectRejectedNoteIds,
  ],
  (notes = [], startDate, endDate, rejectedNoteIds) => {
    const start = DateTime.fromISO(startDate).startOf("day");
    const end = DateTime.fromISO(endDate).endOf("day");

    return notes
      .filter((note) => note.type === PatientNoteTypes.Observation)
      .map((note) => {
        const { id, _meta, severityScore, disposition } = note;

        const observations = note.observation?.observations || [];

        if (
          (id && rejectedNoteIds.includes(id)) ||
          (_meta?.id && rejectedNoteIds.includes(_meta?.id))
        ) {
          return [];
        }

        // Copy note meta to individual observations
        return observations.map((note2) => ({
          ...note2,
          _meta,
          severityScore,
          disposition,
        })) as PatientObservation[];
      })
      .reduce(
        (prev, current) => (prev as any)?.concat((current as any) || []),
        []
      )
      .filter((observation) => {
        // Ignore observations with "rejected" notes
        if (
          observation.disposition === NoteDisposition.Rejected ||
          rejectedNoteIds.includes(observation._meta.id)
        ) {
          return false;
        }

        // Filter between dates, inclusive of end date
        const created = DateTime.fromISO(observation._meta?.created);

        const bool =
          (created.diff(start, ["days"]).toObject().days || 0) >= 0 &&
          (created.diff(end, ["days"]).toObject().days || 0) <= 0;

        return bool;
      });
  }
);
