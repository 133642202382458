import React from "react";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import Button from "rsuite/Button";
import RSModal from "rsuite/Modal";
import cx from "clsx";

import { Modal } from "@/components/modal";
import {
  Patient,
  PatientEndpoints,
  PatientFilters,
  PatientTableType,
} from "@/domain/patient/model/types";
import { PaginationConfig, PatientQuery } from "@/library/types";
import { Provider, ProviderModel } from "@/domain/provider/model";
import { Practice } from "@/domain/practice/model/types";
import { PracticeModel } from "@/domain/practice/model";
import { PatientModel } from "@/domain/patient/model";
import { LocationSelect } from "@/components/_selects/LocationSelect";
import {
  CURRENT_DATE,
  DAYS_LIMIT,
  ERROR,
  FILTER_STRING,
  MIN_FETCH_TIME_MS,
  MODEL_SYNCED,
  SOMETHING_WENT_WRONG,
  SUCCESS,
} from "@/library/constants";
import { PatientForm } from "@/domain/patient/view/form/PatientForm";
import { Header } from "@/components/Header";
import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { Model, ModelCollection } from "@/library/model";
import { User, UserModel } from "@/domain/user/model";
import { RCAResourceActions } from "@/library/core/config/actions";
import { OrganizationModel } from "@/domain/organization/model";
import { Iterator } from "@/library/iteration/iterator";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { Notification } from "@/components/notification/notification";
import { NotificationTypes } from "@/library/types/notification.types";
import { convertDateToLocalTimezone } from "@/util/dateToLocalTimezone";
import { PatientAdminTableComponent } from "./admin.table.component";
import {
  mapDispatcherToProps,
  mapStateToProps,
  PatientAdminTableHeader,
  PatientAssignmentTableHeader,
  PatientClinicalTableHeader,
} from "../constants";
import { Props, PatientTableState } from "../types";
import {
  generateClinicalFilter,
  getPracticeModels,
  getPracticeIds,
} from "../helpers";

import styles from "../styles.module.scss";
import { INVALID_MONGO_ID } from "@/library/serializers/user/helper";
import { PatientAssignmentTable } from "@/domain/patient/view/PatientsPage/table/assignment.table.component";
import { PatientClinicalTableComponent } from "@/domain/patient/view/PatientsPage/table/clinical.table.component";
import { AssignWatchListModal } from "@/domain/patient/view/PatientsPage/watchlist/assignWatchListModal";
import { UnassignWatchListModal } from "@/domain/patient/view/PatientsPage/watchlist/unassignWatchlistModal";
import { RCAResponseErrorParser } from "@/library/error/parser/rca.error.parser";
import {
  attachAssignedUserName,
  attachParentEntities,
  attachReimbursableEvents,
} from "@/domain/patient/redux/middleware";

class PatientsTableComponent extends React.PureComponent<
  Props,
  PatientTableState
> {
  state: Readonly<PatientTableState> = {
    userName: "",
    userModels: [],
    paginationConfig: {
      totalResources: 0,
      currentPage: 0,
      lastPage: 0,
    },
    search: "",

    patientAssignmentModalConfig: {
      show: false,
      title: "",
      modalButton: "",
    },
    patientWatchListAssignmentModalConfig: {
      show: false,
      users: [],
    },
    patientWatchListUnassignmentModalConfig: {
      show: false,
      users: [],
    },
    patientToAssignServiceLevel: undefined,
    patientModel: undefined,
    assignServiceLevel: false,
    patientModalConfig: {
      show: false,
      title: "",
      modalButton: "",
      resourceAction: undefined,
    },
    providerIds: [],
    providerModels: [],
    isCollapsed: false,

    typingTimeout: 0,
    users: [],
    formik: undefined,
    onSubmitForm: () => {},
    multiSelectConfig: {
      showLoadingIndicator: false,
    },
    showPlaceholder: false,
    selectedLocationId: "",

    assignLocationPending: false,
    unAssignLocationPending: false,
    locationTags: this.props.locationTags,
  };

  async componentDidMount() {
    if (this.props.tableType === PatientTableType.Admin) {
      if (!this.props.selectedPractices.length) {
        return;
      }

      const allPracticeModels = await getPracticeModels();
      const allProviderModels = await this.getProviderModels(
        allPracticeModels.map(
          (practiceModel) => practiceModel.pluck("_meta")?.id as string
        )
      );
      if (!allPracticeModels.length || !allProviderModels.length) {
        return;
      }

      this.execSearch({});
    }

    if (this.props.tableType === PatientTableType.Clinical) {
      await this.execClinicalPatientSearch({});
    }

    if (this.props.tableType === PatientTableType.Assignment) {
      // get all practices that belong to selectedOrganization
      const practiceIds: string[] = await getPracticeIds();
      // If there are no practice under that organization, there is definitely no patients
      if (!practiceIds.length) {
        this.props.setAssignmentPatients([]);
        this.props.setAssignmentPatientsPaginationConfig({
          totalResources: 0,
          currentPage: 1,
          lastPage: 1,
        });
      } else {
        this.execAssignmentPatientSearch({ practiceIds });
      }
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (
      this.props.selectedOrganization &&
      prevProps.selectedOrganization?._meta?.id !==
        this.props.selectedOrganization._meta?.id
    ) {
      this.props.deselectAllPatients();
      // Different organization has been selected, patients that belongs to the newly selected organization needs to be fetched
      if (this.props.tableType === PatientTableType.Assignment) {
        // get all practices that belong to selectedOrganization
        const practiceIds: string[] = await getPracticeIds();
        // get all providers belonging to selected practices
        const allProviderModels = await this.getProviderModels(practiceIds);
        // If there are no practice under that organization, there is definitely no patients
        if (!practiceIds.length || !allProviderModels.length) {
          this.props.setAssignmentPatients([]);
          this.props.setAssignmentPatientsPaginationConfig({
            totalResources: 0,
            currentPage: 1,
            lastPage: 1,
          });
        } else {
          this.execAssignmentPatientSearch({ practiceIds });
        }
      }

      if (this.props.tableType === PatientTableType.Clinical) {
        this.props.setPatients([]);
        await this.execClinicalPatientSearch({});
      }
    }

    if (
      prevProps.selectedPractices.length !== this.props.selectedPractices.length
    ) {
      await this.execSearch({});
    }

    // Always compare props before processing events
    if (
      prevProps.selectedProviders.length !== this.props.selectedProviders.length
    ) {
      await this.execSearch({});
    }

    if (prevProps.patients !== this.props.patients) {
      // this.props.selectAllPatients();
    }

    if (prevProps.reload !== this.props.reload) {
      await this.execAssignmentPatientSearch({});
    }

    if (!this.props.selectedPatients?.length && this.state.selectedLocationId) {
      this.setState({ selectedLocationId: "" });
    }
  }

  async componentWillUnmount() {
    if (this.props.tableType === PatientTableType.Assignment) {
      return;
    }
    this.props.setPractices([]);
    this.props.setProviders([]);
    this.props.setPatients([]);
    this.props.setAssignmentPatients([]);
    this.props.deselectAllPractices();
    this.props.deselectAllProviders();
    this.props.deselectAllPatients();
  }

  async getProviderModels(practiceIds: string[]): Promise<ProviderModel[]> {
    if (!practiceIds.length) {
      return [];
    }
    const providerCollection: ModelCollection<ProviderModel, Provider> =
      ProviderModel.makeProviderCollection();
    providerCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
    providerCollection.withParams({
      practiceIds,
    });
    const iterator: Iterator<ProviderModel, Provider> =
      await providerCollection.getMany();
    const allProviderModels = await iterator.getAll();

    this.setState((previousState) => ({
      ...previousState,
      providerModels: allProviderModels,
    }));

    return allProviderModels;
  }

  getHealthSinceDate(): DateTime {
    const datetime = convertDateToLocalTimezone(CURRENT_DATE);
    return datetime.minus({ days: DAYS_LIMIT });
  }

  execClinicalPatientSearch = async (query: PatientQuery) => {
    const { setPatients, setPatientsPaginationConfig, selectedOrganization } =
      this.props;

    if (query.name !== undefined) {
      this.setState({ search: query.name });

      // reset typing timer
      if (this.state.typingTimeout) {
        // stops the execution of an outdated search since search string has been updated
        clearTimeout(this.state.typingTimeout);
      }
    }

    if (!selectedOrganization) {
      return;
    }
    const allOrganizationPractices = (
      await PracticeModel.fetchByOrganizationIds([
        selectedOrganization?._meta?.id as string,
      ])
    ).map((practiceModel) => practiceModel.pluck("_meta")?.id);

    if (!allOrganizationPractices.length) {
      return;
    }
    this.setState({ showPlaceholder: true });
    LoadingIndicator.fire.show();

    const typingTimeout = setTimeout(async () => {
      const patientCollection: ModelCollection<PatientModel, Patient> =
        PatientModel.makePatientCollection();
      patientCollection.setCustomLimit(500);
      patientCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
      const params = {
        ...query,
        includeHealth: 1,
        includeDeviceSnapshot: true,
        healthSince: this.getHealthSinceDate().toUTC().toString(),
        ...{ name: this.state.search },
        ...((!this.props.filters.practiceIds ||
          this.props.filters.practiceIds.length === 0) &&
          (!this.props.filters.providerIds ||
            this.props.filters.providerIds.length === 0) && {
            practiceIds: allOrganizationPractices,
          }),
        ...(generateClinicalFilter(this.props.filters) as PatientFilters),
      };

      // Not supported via api
      delete params.status;

      patientCollection.withParams(params);

      const iterator: Iterator<PatientModel, Patient> =
        await patientCollection.getMany();
      let patientsModel = await iterator.getAll();

      setPatients(patientsModel.map((pm) => pm.pluckAll()));

      setPatientsPaginationConfig({
        totalResources: patientsModel.length,
        currentPage: 1,
        lastPage: 1,
      });

      this.setState({ showPlaceholder: false });

      patientCollection.trigger(MODEL_SYNCED);
    }, 500) as unknown as number;

    this.setState({ typingTimeout });
  };

  execAssignmentPatientSearch = async (query: PatientQuery) => {
    const practiceIds: string[] = await getPracticeIds();
    // there are no practices belonging to the selected organization, don't exec search
    if (!practiceIds.length) {
      return;
    }

    const { setAssignmentPatients, setAssignmentPatientsPaginationConfig } =
      this.props;

    if (query.name !== undefined) {
      await this.setState({ search: query.name });

      // reset typing timer
      if (this.state.typingTimeout) {
        // stops the execution of an outdated search since search string has been updated
        clearTimeout(this.state.typingTimeout);
      }
    }
    LoadingIndicator.fire.show();
    const typingTimeout = setTimeout(async () => {
      const patientCollection: ModelCollection<PatientModel, Patient> =
        PatientModel.makePatientCollection();
      patientCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
      const paginationConfig: PaginationConfig = await patientCollection.fetch({
        ...query,
        practiceIds: practiceIds,
        limit: this.props.assignmentPatientPageLimit,
        ...{ name: this.state.search },
        ...(this.props.assignmentPatientFilters as PatientFilters),
        ...(!this.props.assignmentPatientFilters.practiceIds?.length
          ? { practiceIds: practiceIds }
          : {}),
      });

      await setAssignmentPatients(patientCollection.container);

      setAssignmentPatientsPaginationConfig(paginationConfig);
      patientCollection.trigger(MODEL_SYNCED);
    }, 500) as unknown as number;

    this.setState({ typingTimeout });
  };

  execSearch = async (query: PatientQuery) => {
    const {
      setPatients,
      setPatientsPaginationConfig,
      selectedOrganization,
      selectedPractices,
      selectedProviders,
    } = this.props;

    if (query.name !== undefined) {
      await this.setState({ search: query.name });

      // reset typing timer
      if (this.state.typingTimeout) {
        // stops the execution of an outdated search since search string has been updated
        clearTimeout(this.state.typingTimeout);
      }
    }
    const allOrganizationPractices: string[] = (
      await PracticeModel.fetchByOrganizationIds([
        selectedOrganization?._meta?.id as string,
      ])
    ).map((practiceModel) => practiceModel.pluck("_meta")?.id);

    if (!allOrganizationPractices.length) {
      return;
    }
    LoadingIndicator.fire.show();
    this.setState({
      typingTimeout: setTimeout(async () => {
        const patientCollection: ModelCollection<PatientModel, Patient> =
          PatientModel.makePatientCollection();
        patientCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());
        const patientPaginationConfig: PaginationConfig =
          await patientCollection.fetch({
            ...query,
            limit: this.props.patientPageLimit,
            ...{ name: this.state.search },
            ...(!!selectedProviders.length
              ? {
                  providerIds: selectedProviders.map(
                    (provider: Provider) => provider.id
                  ),
                }
              : !!selectedPractices.length
              ? {
                  practiceIds: selectedPractices.map(
                    (practice: Practice) => practice.id
                  ),
                }
              : { practiceIds: allOrganizationPractices }),
          });
        const patients = patientCollection.container.map((pc) => pc.pluckAll());

        if (patients) {
          const patientsWithParentEntities = await attachParentEntities(
            patients
          );
          const patientsWithUsernames = await attachAssignedUserName(
            patientsWithParentEntities
          );
          await setPatients(patientsWithUsernames);
          setPatientsPaginationConfig(patientPaginationConfig);
        }

        patientCollection.trigger(MODEL_SYNCED);
      }, 500) as unknown as number,
    });
  };

  setPageLimit = async (limit: number) => {
    await this.props.setPatientPageLimit(limit);
    await this.execSearch({});
  };

  setAssignmentPageLimit = async (limit: number) => {
    await this.props.setAssignmentPatientPageLimit(limit);
    await this.execAssignmentPatientSearch({});
  };

  onPatientAssignmentModalClose = () => {
    this.setState({
      patientAssignmentModalConfig: {
        show: false,
        title: "",
        modalButton: "",
      },
    });
  };

  onPatientModalClose = () => {
    this.props.setTargetPatient("");
    this.props.setTargetProvider("");
    this.setState({
      patientModalConfig: {
        show: false,
        title: "",
        modalButton: "",
        assignServiceLevelButton: "",
        resourceAction: undefined,
      },
    });
  };

  onEditPatient = () => {
    this.setState({
      assignServiceLevel: false,
      patientModalConfig: {
        show: true,
        title: "Edit Patient",
        modalButton: "Save Changes",
        resourceAction: RCAResourceActions.ModifyBasicInfo,
      },
    });
  };

  onAssignServiceLevel = () => {
    this.setState({
      assignServiceLevel: true,
    });
  };

  getUsers = async (): Promise<UserModel[]> => {
    const allOrganizationUserModel: UserModel[] =
      await UserModel.fetchByOrganizationIds(
        [this.props.selectedOrganization?._meta?.id!],
        { includeAssignedPatients: true, includeWatchedPatients: true }
      );
    let allPracticeUserModel: UserModel[] = [];

    if (this.props.selectedPractices.length) {
      allPracticeUserModel = await UserModel.fetchByPracticeIds(
        this.props.selectedPractices.map(
          (practice: Practice) => practice._meta?.id
        ),
        { includeAssignedPatients: true, includeWatchedPatients: true }
      );
    } else {
      allPracticeUserModel = await UserModel.fetchByPracticeIds(
        (
          await PracticeModel.fetchByOrganizationIds([
            this.props.selectedOrganization?._meta?.id!,
          ])
        ).map((practiceModel) => practiceModel.pluck("_meta").id),
        { includeAssignedPatients: true, includeWatchedPatients: true }
      );
    }

    await this.setState({
      // Removing the duplicate values from assignedUsers array and mapping it in multi select options array.
      users: [
        { value: "unassigned", label: "Unassigned", role: "Filter" },
        ...[
          ...(new Set(
            allPracticeUserModel.concat(allOrganizationUserModel)
          ) as any),
        ].map((model: Model<User>) => {
          return {
            value: model.pluck("id") as string,
            label: `${model.pluck("firstName")} ${model.pluck("lastName")}`,
            role: "Users",
          };
        }),
      ],
    });

    const allUserModels = allPracticeUserModel.concat(allOrganizationUserModel);
    return allUserModels;
  };

  async updateUserState(): Promise<void> {
    const userModels: UserModel[] = ((await this.getUsers()) || []).filter(
      (um) => !um.isSuspended
    );
    this.setState((previousState, props) => ({
      ...previousState,
      userModels,
    }));
  }

  onAssignPatient = () => {
    this.setState({
      patientAssignmentModalConfig: {
        show: true,
        title: "Assign Patients",
        modalButton: "Save Changes",
      },
    });
  };

  setFormik = (formik: any) => this.setState({ formik });

  execPatientSearch = async () => {
    switch (this.props.tableType) {
      case PatientTableType.Admin:
        await this.execSearch({});
        break;
      case PatientTableType.Assignment:
        await this.execAssignmentPatientSearch({});
        break;
      case PatientTableType.Clinical:
        await this.execClinicalPatientSearch({});
        break;
      default:
        await this.execSearch({});
    }
  };

  generateAssignPatientNotifications = (
    patientIds: string[],
    userName: string,
    state: NotificationTypes
  ) => {
    if (patientIds.length > 0) {
      let messageText = `assigned successfully to ${userName}`;
      if (state === ERROR) {
        messageText = `can not be assigned to ${userName}`;
      }

      const patients = patientIds.map((id) => {
        return this.props.selectedPatients.find((patient) => patient.id === id);
      });

      const message = patients.map((patient) => {
        return (
          `${patient?.demographics.legalName.firstName} ${patient?.demographics.legalName.lastName}` +
          " " +
          messageText
        );
      });
      Notification.notify(state, message);
    }
  };

  handleUnassignUsersClick = async () => {
    await Promise.all(
      this.props.selectedPatients.map(async (patient: Patient) => {
        const patientModel = PatientModel.make(patient);
        await patientModel.modifyPatient<Patient, "userId">(
          { userId: null },
          PatientEndpoints.AssignUser
        );
      })
    );
    this.props.deselectAllPatients();
    Notification.notify(SUCCESS, "Users have been unassigned.");
    this.execPatientSearch();
  };
  handleAssignUserClick = async () => {
    const { selectedPatients } = this.props;
    const { selectedUserId } = this.state;

    if (!selectedUserId) return;

    // get all selectedAssignmentPatients, loop over them and assign this user to each of them
    const assignedPatients = await UserModel.assignPatients(
      selectedUserId,
      selectedPatients.map((patient) => patient.id!)
    );
    const userModel = this.state.userModels.find(
      (userModel) => userModel.pluck("id") === selectedUserId
    );
    const userName = `${userModel?.pluck("firstName")} ${userModel?.pluck(
      "lastName"
    )}`;
    if (assignedPatients) {
      const {
        invalidUserAssignmentScopeIds,
        successfulPatientIds,
        invalidPatientIds,
      } = assignedPatients;

      this.generateAssignPatientNotifications(
        successfulPatientIds,
        userName,
        SUCCESS
      );
      this.generateAssignPatientNotifications(
        invalidUserAssignmentScopeIds,
        userName,
        ERROR
      );
    } else {
      this.generateAssignPatientNotifications(
        selectedPatients.map((patient) => patient.id!),
        userName,
        ERROR
      );
    }

    this.setState({ userName: undefined });
    Notification.notify(SUCCESS, "User has been assigned.");
    await this.execPatientSearch();
  };

  handleLocationSelect = (option: string) => {
    this.setState({
      selectedLocationId: option ?? "",
    });
  };
  handleAssignLocationClick = async () => {
    const { selectedPatients, updatePatientProperty } = this.props;
    const { selectedLocationId } = this.state;

    if (!selectedLocationId) return;

    const startTime = new Date().getTime();
    const assignTags = async (patient: Patient) => {
      if (!patient?.id) return;

      await PatientModel.assignTags(patient.id, {
        delete: patient.tagIds,
        set: [selectedLocationId],
      });
    };

    this.setState({ assignLocationPending: true });

    for (let i = 0; i < selectedPatients.length; i++) {
      const patient = selectedPatients[i];
      if (!patient?.id) continue;
      await assignTags(patient);
      updatePatientProperty(patient.id, "tagIds", [selectedLocationId]);
    }

    const totalTime = new Date().getTime() - startTime;
    const delay =
      totalTime < MIN_FETCH_TIME_MS ? MIN_FETCH_TIME_MS - totalTime : 0;

    setTimeout(() => {
      this.props.deselectAllPatients();
      this.setState({ assignLocationPending: false });
      Notification.notify(SUCCESS, "Location has been assigned.");
    }, delay);
  };

  handleUnassignLocationClick = async () => {
    const { selectedPatients, updatePatientProperty } = this.props;

    const startTime = new Date().getTime();
    const unassign = async (patient: Patient) => {
      if (!patient?.id) return;

      await PatientModel.assignTags(patient.id, {
        set: [],
        delete: patient.tagIds,
      });
    };

    this.setState({ unAssignLocationPending: true });

    for (let i = 0; i < selectedPatients.length; i++) {
      const patient = selectedPatients[i];
      if (!patient?.id) continue;
      await unassign(patient);
      updatePatientProperty(patient.id, "tagIds", []);
    }

    const totalTime = new Date().getTime() - startTime;
    const delay =
      totalTime < MIN_FETCH_TIME_MS ? MIN_FETCH_TIME_MS - totalTime : 0;

    setTimeout(() => {
      this.props.deselectAllPatients();
      this.setState({ unAssignLocationPending: false });
      Notification.notify(SUCCESS, "Locations have been unassigned.");
    }, delay);
  };

  renderAssignLocation = () => {
    const { selectedPatients } = this.props;
    const {
      selectedLocationId,
      assignLocationPending,
      unAssignLocationPending,
    } = this.state;

    return (
      <div className={styles.assignLocationContainer}>
        <label>Location </label>
        <LocationSelect
          data={[]}
          isSearchable={false}
          name="select-location"
          selectedId={selectedLocationId}
          placeholder="Select Location"
          className={styles.locationSelect}
          onChange={this.handleLocationSelect}
          disabled={!selectedPatients.length}
        />
        <Button
          appearance="ghost"
          onClick={this.handleAssignLocationClick}
          disabled={!selectedPatients.length || !selectedLocationId}
          loading={assignLocationPending}
        >
          Assign Location
        </Button>
        <Button
          appearance="ghost"
          color="red"
          onClick={this.handleUnassignLocationClick}
          disabled={!selectedPatients.length}
          style={{ marginLeft: 7 }}
          loading={unAssignLocationPending}
        >
          Unassign Locations
        </Button>
      </div>
    );
  };

  renderPatientFormActions = () => {
    const { formik, patientModalConfig } = this.state;

    return (
      <div
        className={cx(styles.patientFormActions, "d-flex justify-content-end")}
      >
        <Button
          type="button"
          appearance="subtle"
          onClick={() => {
            this.onPatientModalClose();
            formik?.resetForm();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          appearance="primary"
          disabled={formik?.isSubmitting}
          onClick={() => {
            formik?.submitForm();
          }}
          style={{ marginLeft: 8 }}
        >
          {patientModalConfig?.modalButton}
        </Button>
        {patientModalConfig?.modalButton === "Create" ? (
          <Button
            type="submit"
            appearance="primary"
            disabled={formik?.isSubmitting}
            onClick={() => {
              this.onAssignServiceLevel();
              formik?.submitForm();
            }}
            style={{ marginLeft: 8 }}
          >
            {patientModalConfig?.assignServiceLevelButton}
          </Button>
        ) : null}
      </div>
    );
  };

  fetchPracticeIdsByOrg = async (organizationId: string) => {
    let allPracticeIds: string[] = [];
    const practiceModels = await PracticeModel.fetchByOrganizationIds([
      organizationId,
    ]);
    allPracticeIds = (practiceModels || []).map((pm) => pm.pluck("id")!);

    return allPracticeIds;
  };

  addToAssignList = (user: User) => {
    this.setState((prev) => ({
      ...prev,
      patientWatchListAssignmentModalConfig: {
        ...prev.patientWatchListAssignmentModalConfig,
        users: [...prev.patientWatchListAssignmentModalConfig.users, user],
      },
    }));
  };

  removeFromAssignList = (userToRemove: User) => {
    const newUsers =
      this.state.patientWatchListAssignmentModalConfig.users.filter(
        (user: User) => user._meta?.id !== userToRemove._meta?.id
      );
    this.setState((prev) => ({
      ...prev,
      patientWatchListAssignmentModalConfig: {
        ...prev.patientWatchListAssignmentModalConfig,
        users: newUsers,
      },
    }));
  };

  toggleWatchListAssignmentModal = (isShow: boolean): void => {
    this.setState((prev) => ({
      ...prev,
      patientWatchListAssignmentModalConfig: {
        ...prev.patientWatchListAssignmentModalConfig,
        show: isShow,
      },
    }));
  };

  addToUnassignList = (user: User) => {
    this.setState((prev) => ({
      ...prev,
      patientWatchListUnassignmentModalConfig: {
        ...prev.patientWatchListUnassignmentModalConfig,
        users: [...prev.patientWatchListUnassignmentModalConfig.users, user],
      },
    }));
  };

  removeFromUnassignList = (userToRemove: User) => {
    const newUsers =
      this.state.patientWatchListUnassignmentModalConfig.users.filter(
        (user: User) => user._meta?.id !== userToRemove._meta?.id
      );
    this.setState((prev) => ({
      ...prev,
      patientWatchListUnassignmentModalConfig: {
        ...prev.patientWatchListUnassignmentModalConfig,
        users: newUsers,
      },
    }));
  };

  toggleWatchListUnassignmentModal = (isShow: boolean): void => {
    this.setState((prev) => ({
      ...prev,
      patientWatchListUnassignmentModalConfig: {
        ...prev.patientWatchListUnassignmentModalConfig,
        show: isShow,
      },
    }));
  };

  render() {
    const { patients, selectedPatients } = this.props;
    const { selectedUserId } = this.state;

    return (
      <div
        className="container-fluid"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Header
          isNav={this.props.tableType === PatientTableType.Clinical}
          entity={{
            name: "patient",
            displayName: this.props.displayName,
            selectedEntityLength:
              this.props.tableType === PatientTableType.Assignment
                ? this.props.selectedAssignmentPatients.length
                : this.props.patients.length,
            totalEntityLength:
              this.props.tableType === PatientTableType.Assignment
                ? this.props.assignmentPatientPaginationConfig.totalResources
                : this.props.paginationConfig.totalResources,
          }}
          button={{
            create: {},
            toggleCollapse: {
              onToggle: () => {},
              collapsed: false,
            },
          }}
          table={{ pageLimit: this.props.patientPageLimit }}
          search={{
            onSearch:
              this.props.tableType === PatientTableType.Assignment
                ? this.execAssignmentPatientSearch
                : this.props.tableType === PatientTableType.Clinical
                ? this.execClinicalPatientSearch
                : this.execSearch,
            searchStr: this.state.search,
            filterStr: FILTER_STRING,
            filters:
              this.props.tableType === PatientTableType.Assignment
                ? {
                    status: undefined,
                    assignedUserIds: undefined,
                    practiceIds: undefined,
                    providerIds: undefined,
                  }
                : {
                    status: undefined,
                    assignedUserIds: undefined,
                  },
            placeholder: "name, ID or MRN",
          }}
          filter={{
            onResetState: (newState) => this.setState(newState),
            onResetFilters:
              this.props.tableType === PatientTableType.Assignment
                ? this.props.setAssignmentPatientFilters
                : this.props.setPatientFilters,
          }}
          userFilter={{
            enabled: this.props.tableType === PatientTableType.Clinical,
            onSelectUser: (user: string[]) => {
              this.props.setPatientFilters({
                ...this.props.filters,
                assignedUserIds: user,
              });
              this.execPatientSearch();
            },
          }}
          statusFilter={{
            enabled: this.props.tableType === PatientTableType.Clinical,
            onSelectStatus: (status: string | undefined) => {
              this.props.setPatientFilters({
                ...this.props.filters,
                status: status ? [status] : [],
              });
            },
            selectedStatus: this.props.filters.status?.length
              ? this.props.filters.status
              : [],
          }}
          practiceFilter={{
            enabled: this.props.tableType === PatientTableType.Clinical,
            onSelectPractices: async (practiceIds: string[] | undefined) => {
              this.props.setPatientFilters({
                ...this.props.filters,
                practiceIds,
              });
              await this.execPatientSearch();
            },
          }}
          providerFilter={{
            enabled: this.props.tableType === PatientTableType.Clinical,
            onSelectProviders: (
              providers: string[] | undefined,
              description: string[] | undefined
            ) => {
              this.props.setPatientFilters({
                ...this.props.filters,
                providers,
              });
              this.execPatientSearch();
            },
          }}
          alertFilter={{
            enabled: this.props.tableType === PatientTableType.Clinical,
            onSelectAlert: (alert: string) => {
              if (alert === "") {
                this.props.setPatientFilters({
                  ...this.props.filters,
                  alert: undefined,
                });
              } else {
                this.props.setPatientFilters({
                  ...this.props.filters,
                  alert,
                });
              }
              this.execPatientSearch();
            },
          }}
          organizationFilter={{
            enabled: this.props.tableType === PatientTableType.Clinical,
            selectedOrganization: this.props.selectedOrganization,
            onSelectOrganization: async (organizationId: string) => {
              const allPracticeIds = await this.fetchPracticeIdsByOrg(
                organizationId
              );
              if (!this.state.currentUserTags?.length) {
                this.setState((prevState) => ({
                  ...prevState,
                  allPracticeIds: !allPracticeIds.length
                    ? [INVALID_MONGO_ID]
                    : allPracticeIds,
                }));
              }
              await this.props.setPatientFilters({
                status: undefined,
                practiceIds: [],
                providerIds: [],
                assignedUserIds: undefined,
                tagIds: [],
              });
              const path = "patients";
              window.history.replaceState(
                null,
                ``,
                `/${path}?organizationId=${organizationId}`
              );

              // Sync organization with organizationId
              const orgModel: OrganizationModel = await OrganizationModel.sync(
                organizationId
              );

              // make selected organization the default
              await this.props.selectOrganization(orgModel.pluckAll());
              // this.selectOrganization(`${orgModel.pluck('name')},${orgModel.pluck('id')!}`)
            },
          }}
          searchRight={
            this.props.tableType === PatientTableType.Admin &&
            this.renderAssignLocation()
          }
        >
          {(this.props.tableType === PatientTableType.Admin ||
            this.props.tableType === PatientTableType.Assignment) && (
            <div className={cx("col", styles.headerRight)}>
              <div className="d-flex flex-row">
                <div>
                  <Button
                    appearance="ghost"
                    onClick={async () => {
                      await this.updateUserState();
                      this.toggleWatchListAssignmentModal(true);
                    }}
                    disabled={!selectedPatients.length}
                    style={{ marginLeft: 10 }}
                  >
                    Assign Watchlist
                  </Button>
                </div>
                <div className="d-flex flex-row-reverse">
                  <Button
                    appearance="ghost"
                    color="red"
                    onClick={async () => {
                      await this.updateUserState();
                      this.toggleWatchListUnassignmentModal(true);
                    }}
                    disabled={!selectedPatients.length}
                    style={{ marginLeft: 10 }}
                  >
                    Unassign Watchlist
                  </Button>
                </div>

                <BasicMultiSelect
                  data={this.state.userModels.map((userModel: UserModel) => {
                    return {
                      label: `${
                        userModel.pluck("patientCount") || 0
                      } ${userModel.pluck("lastName")}, ${userModel.pluck(
                        "firstName"
                      )} ${
                        userModel.pluck("credentials")
                          ? `(${userModel.pluck("credentials")})`
                          : ""
                      }`,
                      value: `${userModel.pluck("_meta")?.id}`,
                    };
                  })}
                  onChange={(selectedUserId: string) => {
                    this.setState({ selectedUserId });
                  }}
                  onOpen={async () => {
                    await this.updateUserState();
                  }}
                  value={this.state.selectedUserId}
                  findValueBy={this.state.selectedUserId}
                  isSearchable={true}
                  placeholder="Select Care Team Member"
                  config={this.state.multiSelectConfig}
                  disabled={!selectedPatients.length}
                  style={{ marginLeft: 10 }}
                />
                <Button
                  appearance="ghost"
                  onClick={this.handleAssignUserClick}
                  disabled={!selectedUserId || !selectedPatients.length}
                  style={{ marginLeft: 10 }}
                >
                  Assign Care Team Member
                </Button>
              </div>
              <div className="d-flex flex-row-reverse">
                <Button
                  appearance="ghost"
                  color="red"
                  onClick={this.handleUnassignUsersClick}
                  disabled={!selectedPatients.length}
                  style={{ marginLeft: 10 }}
                >
                  Unassign Care Team Member
                </Button>
              </div>
            </div>
          )}
        </Header>

        <div
          id="patient-list"
          className={cx(
            "container-fluid",
            styles.list,
            this.props.hidePatient ? styles.hide : styles.show
          )}
        >
          {this.props.tableType === PatientTableType.Clinical && (
            <PatientClinicalTableComponent
              pageLimit={this.props.patientPageLimit}
              paginationConfig={this.props.paginationConfig}
              onPageLimitChange={this.setPageLimit}
              headers={PatientClinicalTableHeader}
              data={patients}
              execSearch={this.execClinicalPatientSearch}
              onAssignPatient={this.onAssignPatient}
              onEditPatient={this.onEditPatient}
            />
          )}
          {this.props.tableType === PatientTableType.Admin && (
            <PatientAdminTableComponent
              pageLimit={this.props.patientPageLimit}
              paginationConfig={this.props.paginationConfig}
              onPageLimitChange={this.setPageLimit}
              headers={PatientAdminTableHeader}
              data={patients}
              execSearch={this.execSearch}
              onAssignPatient={this.onAssignPatient}
              onEditPatient={this.onEditPatient}
            />
          )}
          {this.props.tableType === PatientTableType.Assignment && (
            <PatientAssignmentTable
              pageLimit={this.props.assignmentPatientPageLimit}
              paginationConfig={this.props.assignmentPatientPaginationConfig}
              onPageLimitChange={this.setAssignmentPageLimit}
              headers={PatientAssignmentTableHeader}
              data={this.props.assignmentPatients}
              execSearch={this.execAssignmentPatientSearch}
              onAssignPatient={this.onAssignPatient}
              onEditPatient={() => {}}
            />
          )}
        </div>

        <Modal
          handleClose={this.onPatientAssignmentModalClose}
          show={this.state.patientAssignmentModalConfig.show}
          heading={this.state.patientAssignmentModalConfig.title}
          button={this.state.patientAssignmentModalConfig.modalButton}
        />

        {this.props.selectedPatients?.length && (
          <AssignWatchListModal
            addToAssignList={this.addToAssignList}
            removeFromAssignList={this.removeFromAssignList}
            title="Assign Watchlist"
            patients={this.props.selectedPatients}
            users={this.state.userModels.map((user: UserModel) => {
              return user.pluckAll();
            })}
            show={this.state.patientWatchListAssignmentModalConfig.show}
            onClose={() => {
              this.toggleWatchListAssignmentModal(false);
            }}
            button={"Assign"}
            save={async () => {
              if (
                this.state.patientWatchListAssignmentModalConfig.users.length
              ) {
                try {
                  const userIds =
                    this.state.patientWatchListAssignmentModalConfig.users.map(
                      (user: User) => user._meta?.id
                    ) as string[];
                  await Promise.all(
                    this.props.selectedPatients.map(
                      async (patient: Patient) => {
                        return PatientModel.assignWatchers(patient._meta?.id!, {
                          set: userIds!,
                        });
                      }
                    )
                  );
                  this.props.selectedPatients.map((patient: Patient) => {
                    this.props.updatePatient({
                      ...patient,
                      watchingUserIds: patient.watchingUserIds
                        ? [...patient.watchingUserIds, ...userIds]
                        : userIds,
                    });
                  });
                  Notification.notify(
                    SUCCESS,
                    "Watchlist successfully assigned."
                  );
                } catch (error: any) {
                  if (error.status || error.response) {
                    Notification.notify(
                      ERROR,
                      RCAResponseErrorParser.parse(error).message()
                    );
                  } else {
                    Notification.notify(ERROR, SOMETHING_WENT_WRONG);
                  }
                }
                this.toggleWatchListAssignmentModal(false);
              }
            }}
          />
        )}
        {this.props.selectedPatients?.length && (
          <UnassignWatchListModal
            addToUnassignList={this.addToUnassignList}
            removeFromUnassignList={this.removeFromUnassignList}
            title="Unassign Watchlist"
            patients={this.props.selectedPatients}
            users={this.state.userModels.map((user: UserModel) => {
              return user.pluckAll();
            })}
            show={this.state.patientWatchListUnassignmentModalConfig.show}
            onClose={() => {
              this.toggleWatchListUnassignmentModal(false);
            }}
            button={"Unassign"}
            save={async () => {
              if (
                this.state.patientWatchListUnassignmentModalConfig.users.length
              ) {
                try {
                  const userIds =
                    this.state.patientWatchListUnassignmentModalConfig.users.map(
                      (user: User) => user._meta?.id
                    ) as string[];
                  await Promise.all(
                    this.props.selectedPatients.map(
                      async (patient: Patient) => {
                        return PatientModel.assignWatchers(patient._meta?.id!, {
                          delete: userIds!,
                        });
                      }
                    )
                  );
                  this.props.selectedPatients.map((patient: Patient) => {
                    const newUserIds = patient.watchingUserIds?.filter(
                      (id: string) => !userIds.includes(id)
                    );
                    const updatedPatient: Patient = {
                      ...patient,
                      watchingUserIds: newUserIds,
                    };
                    this.props.updatePatient(updatedPatient);
                  });
                  Notification.notify(
                    SUCCESS,
                    "Watchlist successfully unassigned."
                  );
                } catch (error: any) {
                  if (error.status || error.response) {
                    Notification.notify(
                      ERROR,
                      RCAResponseErrorParser.parse(error).message()
                    );
                  } else {
                    Notification.notify(ERROR, SOMETHING_WENT_WRONG);
                  }
                }
                this.toggleWatchListUnassignmentModal(false);
              }
            }}
          />
        )}

        <RSModal
          size="full"
          backdrop="static"
          overflow={false}
          open={this.state.patientModalConfig.show}
          onClose={this.onPatientModalClose}
        >
          <RSModal.Header closeButton>
            <RSModal.Title>{this.state.patientModalConfig.title}</RSModal.Title>
          </RSModal.Header>
          <RSModal.Body>
            <PatientForm
              button={this.state.patientModalConfig.modalButton}
              handleClose={this.onPatientModalClose}
              resourceAction={
                this.state.patientModalConfig
                  .resourceAction as RCAResourceActions
              }
              onSetFormik={(formik: any) => {
                this.setFormik(formik);
              }}
              assignServiceLevel={this.state.assignServiceLevel}
              onEditPatient={this.onEditPatient}
            />

            {this.renderPatientFormActions()}
          </RSModal.Body>
        </RSModal>
      </div>
    );
  }
}

export const PatientsTable = connect(
  mapStateToProps,
  mapDispatcherToProps
)(PatientsTableComponent);
