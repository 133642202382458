import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import CancelFilled from "@mui/icons-material/Cancel";
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import React, { FC, useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Patient } from "@/domain/patient/model/types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import {
  Automation,
  DateTimeTrigger,
  ReminderAction,
  ScheduleTrigger,
  Trigger,
  Action,
  VirtualVisitAction,
  AutomationActionType,
} from "@/domain/automation/model/types";
import {
  AutomationPayload,
  Frequency,
  ReminderType,
  VALIDATION_SCHEMA,
} from "@/domain/patient/view/BannerMenu/Reminders/constants";
import { Http } from "@/library/core/api/http";
import { RemoteCareAPISync } from "@/library/core/api";
import { Notification } from "@/components/notification/notification";
import { ERROR, SUCCESS } from "@/library/constants";
import { Str } from "@/util/Str";
import { FormikHelpers, FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/types";
import { Writable } from "@/domain/patient/view/form/manageProgram/types";
import { arrClone } from "@/util/clone";
import { dispatchSetPatients } from "@/domain/patient/redux/actions";
import { Modal } from "@/components/modal";
import { TextInput } from "@/components/_inputs/TextInput";
import globalStyles from "@/styles/globals.module.scss";

interface Props {
  patient: Patient;
  reminderAutomation?: Automation;
  deleteReminderFunction: () => void;
  onSetFormik?: (formik: FormikHelpers<reminderForm>) => void;
  updatePatientDetailState: (patient: Partial<Patient>) => void;
}

interface reminderForm {
  type: string;
  frequency: string;
  weekDayOption: string;
  date: dayjs.Dayjs | null;
  description: string;
  trigger: Trigger;
  action: Action;
  weekDayTime: null;
  dailyTime: null;
  isEditMode: boolean;
}

export const ViewReminders: FC<Props> = ({
  patient,
  reminderAutomation,
  deleteReminderFunction,
  onSetFormik,
  updatePatientDetailState,
}) => {
  const [closeReminder, setCloseReminder] = useState(true);
  const patients = useSelector((state: RootState) => state.patient.patients);

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [disableAcceptBtn, setDisableAcceptBtn] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleSubmit = async (
    values: reminderForm,
    { setSubmitting }: FormikHelpers<reminderForm>
  ) => {
    if (
      "message" in values.action &&
      values.action.message === "" &&
      values.description
    ) {
      values.action = {
        ...values.action,
        message: values.description,
      };
    }
    const patientAutomations = patient.automations;
    let updatedPatientAutomations: Automation[] | undefined =
      [] as Automation[];
    const payload: AutomationPayload = {
      set: {},
      delete: [],
      push: [],
    };
    setSubmitting(true);
    if (reminderAutomation?.id) {
      const id: string = reminderAutomation.id;
      payload.set[id] = {
        triggers: [values.trigger],
        actions: [values.action],
      } as Automation;
      //update original automations
      updatedPatientAutomations = patientAutomations?.map((automation) => {
        if (automation.id === id) {
          automation = {
            ...automation,
            triggers: [values.trigger],
            actions: [values.action],
          };
        }
        return automation;
      });
    } else {
      const newAutomation = {
        triggers: [values.trigger],
        actions: [values.action],
      } as Automation;
      payload.push = [newAutomation];
      updatedPatientAutomations = patientAutomations
        ? [...patientAutomations, ...[newAutomation]]
        : [newAutomation];
      await formik.setFieldValue("isEditMode", true);
    }
    try {
      await Http().post(
        `${RemoteCareAPISync.host}/patients/${patient._meta?.id}/actions/modify`,
        {
          automations: payload,
        }
      );
      if (updatedPatientAutomations?.length) {
        updatePatientList(updatedPatientAutomations);
        updatePatientDetailState({
          ...patient,
          automations: updatedPatientAutomations,
        });
      }
      Notification.notify(SUCCESS, `Reminder updated successfully`);
      deleteReminderFunction();
    } catch (e) {
      Notification.notify(
        ERROR,
        `There was an error updating this reminder, please try again.`
      );
    }
  };
  const handleDeleteReminder = async () => {
    setDisableAcceptBtn(true);
    const patientAutomations = patient.automations;
    let updatedPatientAutomations: Automation[] | undefined = [];
    const payload: AutomationPayload = {
      set: {},
      delete: [],
      push: [],
    };
    updatedPatientAutomations = patientAutomations?.filter(
      (automation: Automation) => {
        return automation.id !== reminderAutomation?.id;
      }
    );
    if (reminderAutomation?.id) payload.delete = [reminderAutomation?.id];
    try {
      await Http().post(
        `${RemoteCareAPISync.host}/patients/${patient._meta?.id}/actions/modify`,
        {
          automations: payload,
        }
      );
      if (updatedPatientAutomations?.length) {
        updatePatientList(updatedPatientAutomations);
        updatePatientDetailState({
          ...patient,
          automations: updatedPatientAutomations,
        });
      }
      Notification.notify(SUCCESS, `Reminder deleted successfully`);
    } catch (e) {
      Notification.notify(
        ERROR,
        `There was an error deleting this reminder, please try again.`
      );
    }
  };
  useEffect(() => {
    if (reminderAutomation) {
      const action: ReminderAction | VirtualVisitAction = reminderAutomation
        .actions[0] as ReminderAction | VirtualVisitAction;
      const trigger: Trigger = reminderAutomation.triggers[0];
      let type = "";
      if ("message" in action) {
        if (action.message.includes("biometric")) {
          type = ReminderType.Biometrics;
          formik.setFieldValue("type", type);
        } else if (action.message.includes("medication")) {
          type = ReminderType.Medication;
          formik.setFieldValue("type", type);
        } else if (action.message.includes("appointment")) {
          type = ReminderType.Appointment;
          formik.setFieldValue("type", type);
        }
      } else if (action.type === AutomationActionType.RequestVirtualVisit) {
        type = ReminderType.VirtualVisit;
        formik.setFieldValue("type", type);
      } else {
        type = ReminderType.Other;
        formik.setFieldValue("type", type);
        formik.setFieldValue("description", (action as ReminderAction).message);
      }
      if (type !== ReminderType.Other) getDescription(type);

      if (trigger) {
        if (trigger.type === "DATE_TIME") {
          formik.setFieldValue("frequency", Frequency.Once);
          formik.setFieldValue("date", dayjs(trigger.when));
        } else if (trigger.type === "SCHEDULE") {
          const occurrence = trigger.occurrences[0];
          formik.setFieldValue(
            "weekDayOption",
            Str.CapitalizeFirstLetter(occurrence.day.toLowerCase())
          );
          const now = new Date();
          const date = dayjs(
            new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate(),
              occurrence.hour,
              occurrence.minute
            )
          );
          formik.setFieldValue("date", date);
          if (trigger.occurrences.length > 1) {
            formik.setFieldValue("frequency", Frequency.Daily);
            formik.setFieldValue("dailyTime", date);
          } else {
            formik.setFieldValue("frequency", Frequency.Weekly);
            formik.setFieldValue("weekDayTime", date);
          }
        }
      }
      formik.setFieldValue("isEditMode", true);
    }
  }, []);

  const formik = useFormik<reminderForm>({
    initialValues: {
      type: "",
      frequency: Frequency.Once,
      weekDayOption: "",
      date: null,
      description: "",
      trigger: reminderAutomation
        ? reminderAutomation.triggers[0]
        : ({} as Trigger),
      action: reminderAutomation
        ? reminderAutomation.actions[0]
        : ({} as Action),
      weekDayTime: null,
      dailyTime: null,
      isEditMode: !!reminderAutomation,
    },
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: VALIDATION_SCHEMA,
  });
  const { values, errors } = formik;
  const [didSetFormik, setDidSetFormik] = useState(false);
  useEffect(() => {
    if (didSetFormik) return;
    if (onSetFormik) onSetFormik(formik);
    setDidSetFormik(true);
  }, [formik]);
  const getDescription = (type: string) => {
    switch (type) {
      case "Biometrics":
        formik.setFieldValue(
          "description",
          "Please remember to take your biometric (vital) reading"
        );
        break;
      case "Medication":
        formik.setFieldValue(
          "description",
          "Please remember to take your medication"
        );
        break;
      case "Appointment":
        formik.setFieldValue(
          "description",
          "Please remember that you have the upcoming appointment scheduled"
        );
        break;
      case "VirtualVisit":
        formik.setFieldValue("description", "");
        break;
      default:
        formik.setFieldValue("description", "");
    }
  };
  const handleReminderTypeChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    formik.setFieldValue("type", value);
    if (value !== "Other") {
      getDescription(value);
    }
    if (value === ReminderType.VirtualVisit) {
      formik.setFieldValue("action", {
        type: "REQUEST_VIRTUAL_VISIT",
      });
    } else {
      formik.setFieldValue("action", {
        type: "REMIND",
        message: values.description,
      });
    }
  };

  const handleFrequencyChange = (event: SelectChangeEvent) => {
    formik.setFieldValue("frequency", event.target.value);
    formik.setFieldValue("date", null);
  };
  const handleWeekDayOptionChange = (event: SelectChangeEvent) => {
    formik.setFieldValue("weekDayOption", event.target.value);
  };

  const renderError = (nameKey: keyof reminderForm) => {
    return errors.hasOwnProperty(nameKey) ? (
      <ul style={{ marginTop: 14 }}>
        {errors.hasOwnProperty(nameKey) ? (
          <li className={globalStyles.danger}>{errors[nameKey]}</li>
        ) : null}
      </ul>
    ) : null;
  };

  const renderText = () => {
    return values.frequency === Frequency.Daily
      ? "every day"
      : values.weekDayOption
      ? `on ${values.weekDayOption}`
      : `on ${dayjs(values.date).format("dddd MM/DD/YYYY")}`;
  };

  const updatePatientList = (updatedPatientAutomations: Automation[]) => {
    const _patients: Writable<Patient[]> = arrClone(patients) as Patient[];
    const updatedPatients = _patients.map((selectedPatient) => {
      if (selectedPatient.id === patient.id) {
        selectedPatient.automations = updatedPatientAutomations;
      }
      return selectedPatient;
    });
    dispatch(dispatchSetPatients(updatedPatients));
  };

  return (
    <>
      {closeReminder && (
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ border: 2, borderColor: "#F4F2F2", borderRadius: 10 }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ m: 3 }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Typography variant="h4" sx={{ pl: 15 }}>
                    {patient.demographics.legalName.firstName}{" "}
                    {patient.demographics.legalName.lastName}
                  </Typography>
                  <IconButton
                    style={{ backgroundColor: "transparent", border: "none" }}
                    onClick={() => {
                      if (values.isEditMode) {
                        setShowConfirmModal(true);
                      } else {
                        deleteReminderFunction();
                      }
                    }}
                  >
                    <CancelFilled sx={{ ml: 10 }} />
                  </IconButton>
                </Stack>
                <FormControl variant="outlined" sx={{ m: 4, minWidth: 180 }}>
                  <InputLabel id="typeLabel">Type</InputLabel>
                  <Select
                    label="Type"
                    labelId="typeLabel"
                    MenuProps={{ disablePortal: true }}
                    onChange={handleReminderTypeChange}
                    sx={{ mb: 2 }}
                    name="type"
                    value={values.type}
                    required
                    error={!!errors.type}
                  >
                    <MenuItem value="Medication">Medication</MenuItem>
                    <MenuItem value="Biometrics">Biometrics</MenuItem>
                    <MenuItem value="Appointment">Appointment</MenuItem>
                    <MenuItem value="VirtualVisit">Virtual Visit</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {renderError("type")}
                </FormControl>
                <FormControl variant="standard">
                  <TextInput
                    label="Description"
                    style={{ width: 452 }}
                    name="description"
                    type="text"
                    disabled={values.type !== "Other"}
                    placeholder="Description"
                    value={values.description}
                    hasError={!!errors?.description}
                    onChange={(event) => {
                      formik.setFieldValue("description", event.target.value);
                      formik.setFieldValue("action", {
                        type: "REMIND",
                        message: values.description,
                      });
                    }}
                  />
                  {renderError("description")}
                </FormControl>
                {values.date && (
                  <Typography variant="body1" gutterBottom>
                    At {dayjs(values.date).format("hh:mm A  ")} {renderText()}
                  </Typography>
                )}
                <div>
                  <FormControl
                    variant="outlined"
                    sx={{ mt: 4, mr: 0, maxWidth: 130 }}
                  >
                    <Select
                      value={values.frequency}
                      onChange={handleFrequencyChange}
                      displayEmpty
                      name="frequency"
                      renderValue={(value) => {
                        return (
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <CachedIcon color="primary" />
                            {value}
                          </Box>
                        );
                      }}
                      size="small"
                      MenuProps={{ disablePortal: true }}
                      required
                      error={!!errors.frequency}
                    >
                      <MenuItem value="Once">Once</MenuItem>
                      <MenuItem value="Daily">Daily</MenuItem>
                      <MenuItem value="Weekly">Weekly</MenuItem>
                    </Select>
                  </FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {values.frequency === Frequency.Once && (
                      <>
                        <DateTimePicker
                          label="Date/Time"
                          disablePast
                          value={values.date}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("date", value);
                              formik.setFieldValue("trigger", {
                                type: "DATE_TIME",
                                when: dayjs(value).toISOString(),
                              } as DateTimeTrigger);
                            }
                          }}
                          slotProps={{
                            openPickerButton: {
                              color: "primary",
                            },
                            inputAdornment: {
                              position: "start",
                            },
                            textField: { size: "small", required: true },
                          }}
                          sx={{ mt: 4, ml: 1 }}
                        />
                        {renderError("date")}
                      </>
                    )}
                    {values.frequency === Frequency.Daily && (
                      <>
                        <TimePicker
                          label="Time"
                          value={values.dailyTime}
                          onChange={(value: dayjs.Dayjs | null) => {
                            if (value) {
                              formik.setFieldValue("dailyTime", value);
                              formik.setFieldValue("date", value.add(1, "d"));
                              formik.setFieldValue("trigger", {
                                type: "SCHEDULE",
                                occurrences: [
                                  {
                                    day: "MONDAY",
                                    hour: dayjs(value).hour(),
                                    minute: dayjs(value).minute(),
                                  },
                                  {
                                    day: "TUESDAY",
                                    hour: dayjs(value).hour(),
                                    minute: dayjs(value).minute(),
                                  },
                                  {
                                    day: "WEDNESDAY",
                                    hour: dayjs(value).hour(),
                                    minute: dayjs(value).minute(),
                                  },
                                  {
                                    day: "THURSDAY",
                                    hour: dayjs(value).hour(),
                                    minute: dayjs(value).minute(),
                                  },
                                  {
                                    day: "FRIDAY",
                                    hour: dayjs(value).hour(),
                                    minute: dayjs(value).minute(),
                                  },
                                  {
                                    day: "SATURDAY",
                                    hour: dayjs(value).hour(),
                                    minute: dayjs(value).minute(),
                                  },
                                  {
                                    day: "SUNDAY",
                                    hour: dayjs(value).hour(),
                                    minute: dayjs(value).minute(),
                                  },
                                ],
                              } as ScheduleTrigger);
                            }
                          }}
                          slotProps={{
                            openPickerButton: {
                              color: "primary",
                            },
                            inputAdornment: {
                              position: "start",
                            },
                            textField: { size: "small", required: true },
                          }}
                          sx={{ mt: 4, ml: 2, maxWidth: 160 }}
                        />
                        {renderError("dailyTime")}
                      </>
                    )}
                    {values.frequency === Frequency.Weekly && (
                      <>
                        <FormControl
                          sx={{ mt: 4, ml: 1, minWidth: 120 }}
                          variant="outlined"
                          error={!values.weekDayOption}
                        >
                          <InputLabel id="weekDayOptionLabel" size="small">
                            Day
                          </InputLabel>
                          <Select
                            displayEmpty
                            required
                            label="Day"
                            labelId="weekDayOptionLabel"
                            MenuProps={{ disablePortal: true }}
                            onChange={handleWeekDayOptionChange}
                            name="weekDayOption"
                            size="small"
                            value={values.weekDayOption}
                            error={!!errors.weekDayOption}
                          >
                            <MenuItem value="Monday">Monday</MenuItem>
                            <MenuItem value="Tuesday">Tuesday</MenuItem>
                            <MenuItem value="Wednesday">Wednesday</MenuItem>
                            <MenuItem value="Thursday">Thursday</MenuItem>
                            <MenuItem value="Friday">Friday</MenuItem>
                            <MenuItem value="Saturday">Saturday</MenuItem>
                            <MenuItem value="Sunday">Sunday</MenuItem>
                          </Select>
                          {renderError("weekDayOption")}
                        </FormControl>
                        <FormControl sx={{ mt: 4, ml: 1 }}>
                          <TimePicker
                            label="Time"
                            value={values.weekDayTime}
                            onChange={(value: dayjs.Dayjs | null) => {
                              if (value) {
                                formik.setFieldValue("weekDayTime", value);
                                formik.setFieldValue("date", value.add(1, "d"));
                                formik.setFieldValue("trigger", {
                                  type: "SCHEDULE",
                                  occurrences: [
                                    {
                                      day: values.weekDayOption.toUpperCase(),
                                      hour: dayjs(value).hour(),
                                      minute: dayjs(value).minute(),
                                    },
                                  ],
                                } as ScheduleTrigger);
                              }
                            }}
                            slotProps={{
                              openPickerButton: {
                                color: "primary",
                              },
                              inputAdornment: {
                                position: "start",
                              },
                              textField: { size: "small", required: true },
                            }}
                            sx={{ maxWidth: 140 }}
                          />
                          {renderError("weekDayTime")}
                        </FormControl>
                      </>
                    )}
                  </LocalizationProvider>
                  {values.frequency && (
                    <FormControl sx={{ mt: 4, ml: 1 }}>
                      <Button
                        type="submit"
                        disabled={formik.isSubmitting}
                        size="medium"
                        color="primary"
                        variant="outlined"
                        sx={{ height: 39 }}
                      >
                        SAVE
                      </Button>
                    </FormControl>
                  )}
                </div>
              </Stack>
            </Box>
          </form>
        </FormikProvider>
      )}
      <Modal
        size="xs"
        handleClose={() => setShowConfirmModal(false)}
        show={showConfirmModal}
        heading={"Confirm Delete Reminder"}
        button={"Accept"}
      >
        {showConfirmModal && (
          <>
            <p
              style={{
                padding: "10px",
                fontSize: "17px",
              }}
            >
              Are you sure you want to delete this reminder ?
            </p>
            <div className="row">
              <div className="col-12 mt-4 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={() => setShowConfirmModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={disableAcceptBtn}
                  onClick={handleDeleteReminder}
                  className="btn btn-primary mr-2"
                >
                  Accept
                </button>
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
