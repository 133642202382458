import { AnyAction } from "redux";
import { DateTime } from "luxon";
import produce from "immer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  FilterItemDetail,
  Patient,
  PatientFilters,
} from "@/domain/patient/model/types";
import {
  getStartDate,
  getEndDate,
} from "@/domain/patient/view/tabs/monitoring/helpers";
import {
  ObservationReasons,
  ObservationToKeyofOverviewSnapshot,
  ObservationTypes,
} from "@/domain/patient/model/constants";
import {
  ObservationOverviewSnapshot,
  ObservationSnapshot,
  ObservationType,
  ScalarObservationSnapshot,
} from "@/domain/observations/types";
import { CREATE_NOTE, RESOLVE_NOTES } from "@/domain/notes/redux/constants";
import { DAYS_LIMIT, LOGOUT } from "@/library/constants";
import { PatientId, PatientState } from "./types";
import * as C from "./constants";

export const INITIAL_STATE: PatientState = {
  patients: [],
  assignmentPatients: [],
  selectedPatients: [],
  selectedAssignmentPatients: [],
  observationsByPatientId: {},
  paginationConfig: {
    totalResources: 0,
    currentPage: 1,
    lastPage: 1,
  },
  assignmentPatientPaginationConfig: {
    totalResources: 0,
    currentPage: 1,
    lastPage: 1,
  },
  patientPageLimit: 50,
  assignmentPatientPageLimit: 50,
  targetPatient: "",
  filters: { status: undefined, assignedUserIds: undefined },
  filterItems: [],
  assignmentPatientFilters: { status: undefined, assignedUserIds: undefined },
  monitoringStartDate: getStartDate(),
  monitoringEndDate: getEndDate(),
  monitoringTabVisibility: {
    [ObservationTypes.BloodPressure]: true,
    [ObservationTypes.HeartRate]: true,
    [ObservationTypes.Temperature]: true,
    [ObservationTypes.Weight]: true,
    [ObservationTypes.GlucoseLevel]: true,
    [ObservationTypes.PulseOximetry]: true,
    [ObservationTypes.Spirometry]: true,
  } as Record<ObservationType, boolean>,
  timerByUserPatientId: {},
  noteCreatedOn: null,
};

const reducer = produce((state: PatientState, action: AnyAction) => {
  switch (action.type) {
    case "persist/REHYDRATE": {
      if (action.key !== "patient") break;

      const {
        observationsByPatientId,
        monitoringTabVisibility,
        timerByUserPatientId,
        monitoringStartDate,
        monitoringEndDate,
      } = action.payload || {};

      state.observationsByPatientId = observationsByPatientId || {};
      state.monitoringStartDate = monitoringStartDate || getStartDate();
      state.monitoringEndDate = monitoringEndDate || getEndDate();
      state.monitoringTabVisibility =
        monitoringTabVisibility || INITIAL_STATE.monitoringTabVisibility;
      state.timerByUserPatientId = timerByUserPatientId || {};

      break;
    }

    case C.CREATE_PATIENT: {
      const { patient } = action.payload;
      state.patients.push(patient);
      break;
    }

    case C.UPDATE_PATIENT: {
      const { patient } = action.payload;
      const index = state.patients.findIndex((p) => p.id === patient.id);
      if (index > -1) state.patients[index] = patient;

      const indexOfSelectedPatient = state.selectedPatients.findIndex(
        (p) => p.id === patient.id
      );
      if (indexOfSelectedPatient > -1)
        state.selectedPatients[indexOfSelectedPatient] = patient;
      break;
    }

    case C.UPDATE_PATIENT_PROPERTY: {
      const { patientId, key, value } = action.payload;
      const index = state.patients.findIndex((p) => p.id === patientId);
      // @ts-ignore
      if (index > -1) (state.patients[index] as Patient)[key] = value;
      break;
    }

    case C.SET_PATIENTS: {
      state.patients = action.payload.patients;
      break;
    }

    case C.SET_ASSIGNMENT_PATIENTS: {
      state.assignmentPatients = action.payload.patients;
      break;
    }

    case C.SET_PATIENTS_PAGINATIONCONFIG: {
      state.paginationConfig = action.payload.paginationConfig;
      break;
    }

    case C.SET_ASSIGNMENT_PATIENTS_PAGINATIONCONFIG: {
      state.assignmentPatientPaginationConfig = action.payload.paginationConfig;
      break;
    }

    case C.SET_PATIENTS_PAGE_LIMIT: {
      state.patientPageLimit = action.payload.pageLimit;
      break;
    }

    case C.SET_ASSIGNMENT_PATIENTS_PAGE_LIMIT: {
      state.assignmentPatientPageLimit = action.payload.pageLimit;
      break;
    }

    case C.SELECT_PATIENT: {
      const { patient } = action.payload;
      if (!patient?.id) break;

      const { id: patientId } = patient;
      const index = state.selectedPatients.findIndex((p) => p.id === patientId);

      if (index > -1) state.selectedPatients[index] = patient;
      else state.selectedPatients.push(patient);

      break;
    }

    case C.SELECT_ASSIGNMENT_PATIENT: {
      state.selectedAssignmentPatients.push(action.payload.patient as Patient);
      break;
    }

    case C.DESELECT_PATIENT: {
      const index = state.selectedPatients.findIndex(
        (p) => p.id === action.payload.patient.id
      );

      if (index > -1) state.selectedPatients.splice(index, 1);

      break;
    }

    case C.DESELECT_ASSIGNMENT_PATIENT: {
      const index = state.selectedAssignmentPatients.findIndex(
        (p) => p.id === action.payload.patient.id
      );

      if (index > -1) state.selectedAssignmentPatients.splice(index, 1);

      break;
    }

    case C.SELECT_ALL_PATIENTS: {
      state.selectedPatients = state.patients;
      break;
    }

    case C.SELECT_ALL_ASSIGNMENT_PATIENTS: {
      state.selectedAssignmentPatients = state.assignmentPatients;
      break;
    }

    case C.DESELECT_ALL_PATIENTS: {
      state.selectedPatients = [];
      break;
    }

    case C.DESELECT_ALL_ASSIGNMENT_PATIENTS: {
      state.selectedAssignmentPatients = [];
      break;
    }

    case C.SET_TARGET_PATIENT: {
      state.targetPatient = action.payload.id as PatientId;
      break;
    }

    case C.SET_PATIENT_FILTERS: {
      state.filters = action.payload.filters as PatientFilters;
      break;
    }

    case C.SET_FILTER_ITEM_DETAILS: {
      state.filterItems = action.payload.filterItems as FilterItemDetail[];
      break;
    }

    case C.SET_ASSIGNMENT_PATIENT_FILTERS: {
      state.assignmentPatientFilters = {
        ...state.assignmentPatientFilters,
        ...(action.payload.filters as PatientFilters),
      };
      break;
    }

    case C.ADD_PATIENT_OBSERVATIONS: {
      const { patientId, observations } = action.payload;

      if (!state.observationsByPatientId[patientId])
        state.observationsByPatientId[patientId] = [];

      state.observationsByPatientId[patientId] = state.observationsByPatientId[
        patientId
      ].concat(observations || []);
      break;
    }

    case C.SET_PATIENT_OBSERVATIONS: {
      const { patientId, observations } = action.payload;

      if (!state.observationsByPatientId[patientId])
        state.observationsByPatientId[patientId] = [];

      state.observationsByPatientId[patientId] = observations || [];
      break;
    }

    case C.SET_MONITORING_DATES:
      const { startDate, endDate } = action.payload;
      state.monitoringStartDate = startDate;
      state.monitoringEndDate = endDate;
      break;

    case C.SET_MONITORING_START_DATE: {
      const { startDate } = action.payload;

      const now = DateTime.now();
      let start = DateTime.fromISO(startDate);
      let end = DateTime.fromISO(startDate)
        .plus({ days: DAYS_LIMIT - 1 })
        .endOf("day");
      const diff = end.diff(now, "days").toObject();
      const { days = 0 } = diff;

      // Clamp end date to today
      if (days > 1) {
        end = DateTime.now();
        start = DateTime.now().minus({ days: DAYS_LIMIT - 1 });
      }

      state.monitoringStartDate = start.toISODate();
      state.monitoringEndDate = end.toISODate();

      break;
    }
    case C.SET_MONITORING_END_DATE: {
      const { endDate } = action.payload;

      const now = DateTime.now();
      let end = DateTime.fromISO(endDate).endOf("day");
      let start = DateTime.fromISO(endDate)
        .minus({ days: DAYS_LIMIT - 1 })
        .startOf("day");
      const diff = end.diff(now, "days").toObject();
      const { days = 0 } = diff;

      // Clamp end date to today
      if (days > 1) {
        end = DateTime.now();
        start = DateTime.now().minus({ days: DAYS_LIMIT });
      }

      state.monitoringStartDate = start.toISODate();
      state.monitoringEndDate = end.toISODate();

      break;
    }

    case C.TOGGLE_MONITORING_TAB_VISIBILITY: {
      const { tab } = action.payload;

      state.monitoringTabVisibility[tab as ObservationType] =
        !state.monitoringTabVisibility[tab as ObservationType];

      break;
    }

    case C.SET_PATIENT_TIMER: {
      const { timer } = action.payload;
      state.timerByUserPatientId[timer.userPatientId] = timer;

      break;
    }

    /**
     *
     * NOTE SIDE-EFFECTS
     *
     */

    case CREATE_NOTE: {
      const { note } = action.payload;
      const { observations = [] } = note;

      if (!observations.length) break;

      // Flag AlertsTable to refresh
      const createdAt = new Date().toISOString();
      state.noteCreatedOn = createdAt;

      // Update the patient's healthSnapshot
      for (let i = 0; i < observations.length; i++) {
        const observation = observations[i];
        const key = (ObservationToKeyofOverviewSnapshot as any)[
          observation.type
        ] as keyof ObservationOverviewSnapshot;

        if (key && state.selectedPatients[0].health?.observations?.[key]) {
          const snapshot = state.selectedPatients[0].health.observations[key]!;

          snapshot.isComplete = false;

          if (snapshot.observation) {
            snapshot.observation = {
              ...snapshot.observation,
              ...observation,
            };
          }
        }
      }

      break;
    }

    case RESOLVE_NOTES: {
      const { rows } = action.payload;

      if (!rows.length || !state.selectedPatients.length) break;

      // Update the patient's healthSnapshot
      for (let i = 0; i < rows.length; i++) {
        const key = (ObservationToKeyofOverviewSnapshot as any)[
          rows[i].type
        ] as keyof ObservationOverviewSnapshot;

        if (key && state.selectedPatients[0].health?.observations?.[key]) {
          state.selectedPatients[0].health.observations[key]!.isComplete = true;
        }
      }

      break;
    }

    case LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}, INITIAL_STATE);

const config = {
  key: "patient",
  storage,
  blacklist: [
    "patients",
    "assignmentPatients",
    "selectedPatients",
    "selectedAssignmentPatients",
  ],
};

export const patientReducer = persistReducer(config, reducer);
