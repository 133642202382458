import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Toggle } from "rsuite";
import { connect } from "react-redux";

import { TextArea, Validation } from "@/components/form";
import { Patient } from "@/domain/patient/model/types";
import { Note } from "@/domain/notes/model/types";
import {
  Assessment,
  AssessmentQuestion,
} from "@/domain/assessment/model/types";
import { AssessmentModel } from "@/domain/assessment/model";
import { Invoker } from "@/library/common/invoker/invoker";
import { Receiver } from "@/library/common/receiver/receiver";
import { NoteModel } from "@/domain/notes/model";
import { ResourceType } from "@/library/core/config/resource";
import { RCAResourceActions } from "@/library/core/config/actions";
import { CleanTask, FinallyTask } from "@/library/common/task";
import { Interventions, PatientNoteType } from "@/library/types/note";
import { MultiSelect } from "@/components/form/select/multiSelect";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { snake2Camel } from "@/pipes/text";
import NoteTimer from "../NoteTimer";
import { dispatchSetPatientTimer } from "@/domain/patient/redux/actions";
import { RootState } from "@/types/index";
import { User } from "@/domain/user/model/user";
import { UserPatientTimerState } from "@/domain/patient/redux/types";
import { TimerState } from "@/components/countdown/types";
import { Intervention } from "../../../../../library/types/note";

interface Props {
  onSetFormik: (formik: any) => void;
  replyToIds: string[];
  handleClose: () => void;
  patient: Patient;
  updateParentNoteInReduxIfChild: (note: Partial<Note>) => void;
  includeTimer?: boolean;

  //Redux props
  patientTimer: { [key: string]: UserPatientTimerState };
  currentUser: User | null;
  setTimer: (
    timer: UserPatientTimerState
  ) => { type: string; payload: UserPatientTimerState };
}

interface State {
  assessments: Assessment[];
  questions: AssessmentQuestion[];
  assessmentTypes: { value: string; label: string }[];
  timerStopped: boolean;
}

class AssessmentNoteForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      assessments: [],
      questions: [],
      assessmentTypes: [],
      timerStopped: false,
    };
  }

  get initialValues() {
    return {
      patientId: this.props.patient.id as string,
      seconds: 0,
      intervention: [],
      replyToIds: this.props.replyToIds,
      type: PatientNoteType.Assessment,
      totalDurationMs: 0,
      directPatientContact: true,
      comment: "",
    } as any;
  }

  async componentDidMount() {
    this.getAssessments();
  }

  isAcceptable = (question: AssessmentQuestion): boolean => {
    if (["boolean", "number", "percent"].includes(question.answer.type)) {
      return true;
    }

    const answerType = question.answer.type.split("_");
    const doContain_scale = answerType[answerType.length - 1] === "scale";
    return doContain_scale;
  };

  getAssessments = async () => {
    LoadingIndicator.fire.show();
    const assessments = await AssessmentModel.getAssessmentTemplates();
    this.setState({
      ...this.state,
      assessments,
      assessmentTypes: assessments.map((assessment) => {
        return {
          label: assessment.name,
          value: assessment.legacyDocumentId,
        };
      }),
    });
    LoadingIndicator.fire.hide();
  };

  setAssessmentQuestions = (
    selectedAssessmentQuestions: AssessmentQuestion[]
  ) => {
    const filteredQuestions = selectedAssessmentQuestions.filter((question) => {
      if (this.isAcceptable(question)) {
        return question;
      }
    });
    this.setState({
      ...this.state,
      questions: filteredQuestions || [],
    });
  };

  componentDidUpdate(prevProps: Props) {
    if (!this.props.includeTimer) {
      const patientTimer =
        this.props.patientTimer[
          this.props.currentUser!.id! + this.props.patient.id
        ];
    }
  }

  render() {
    return (
      <>
        <Formik
          initialValues={this.initialValues}
          validationSchema={Yup.object({
            comment: Validation.notRequired.nullable(),
            replyToIds: Validation.notRequiredArray,
            intervention: Validation.notRequiredArray,
          })}
          onSubmit={async (
            values: Partial<Assessment>,
            { setSubmitting, resetForm }
          ) => {
            this.setState({
              timerStopped: true,
            });
            values.comment = values.comment === "" ? null : values.comment;
            values.questions =
              this.state.questions
                .filter((question) => question.answer.value)
                .map((question) => {
                  return {
                    prompt: question.prompt,
                    durationMs: 0,
                    answer: {
                      type: question.answer.type,
                      value: question.answer.value,
                    },
                  };
                }) || [];
            await Invoker.make<Note>(
              Receiver.make(
                NoteModel.make(values as any, true),
                ResourceType.note,
                RCAResourceActions.Create
              ),
              CleanTask.make(
                () => resetForm(this.initialValues),
                () => this.props.handleClose(),
                () => this.props.updateParentNoteInReduxIfChild(values as any)
              ),
              FinallyTask.make(() => setSubmitting(false))
            ).invoke();
          }}
        >
          {(formik) => {
            this.props.onSetFormik(formik);
            return (
              <Form>
                <div className="modal-body">
                  <div className="subject-scroll p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="row">
                          {this.props.includeTimer && (
                            <div className="col-md-2">
                              <div className="mt-2">
                                <NoteTimer
                                  onChange={(timerState: TimerState) => {
                                    const seconds =
                                      timerState.seconds +
                                      timerState.minutes * 60;
                                    formik.setFieldValue("seconds", seconds);
                                  }}
                                  isStopped={this.state.timerStopped}
                                />
                              </div>
                            </div>
                          )}
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Direct Patient Contact</label>
                              <div>
                                <Toggle
                                  checkedChildren="On"
                                  unCheckedChildren="Off"
                                  defaultChecked={true}
                                  onChange={(v) => {
                                    formik.setFieldValue(
                                      "directPatientContact",
                                      v
                                    );
                                  }}
                                  checked={formik.values.directPatientContact}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="form-group">
                              <label>Intervention(s)</label>
                              <div>
                                <MultiSelect
                                  data={Object.keys(Interventions)
                                    .filter(
                                      (intervention) =>
                                        intervention !==
                                        Intervention.LeftPhoneMessage
                                    )
                                    .map((key) => {
                                      return {
                                        value: key,
                                        label:
                                          Interventions[key as Intervention],
                                      };
                                    })}
                                  onChange={(intervention: any) => {
                                    if (
                                      !formik.values.directPatientContact &&
                                      (intervention.includes(
                                        "VIDEO_VISIT_COMPLETED"
                                      ) ||
                                        intervention.includes(
                                          "DIRECT_PATIENT_CONTACT"
                                        ))
                                    ) {
                                      formik.setFieldValue(
                                        "directPatientContact",
                                        true
                                      );
                                    }
                                    formik.setFieldValue(
                                      "intervention",
                                      intervention
                                    );
                                  }}
                                  value={[formik.values.intervention]}
                                  isSearchable={false}
                                  placeholder="Select Intervention"
                                  sortable={false}
                                  findValueBy={(option: any) => option.value}
                                  picker="check"
                                  name={"intervention"}
                                  optional={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Assessment</label>
                              <div>
                                <select
                                  className="form-control mb-3"
                                  defaultValue={""}
                                  onChange={(e) => {
                                    const selectedAssessmentLegacyId =
                                      e.currentTarget.value;
                                    const selectedAssessment =
                                      this.state.assessments.find(
                                        (assessment) =>
                                          assessment.legacyDocumentId ===
                                          selectedAssessmentLegacyId
                                      );
                                    this.setAssessmentQuestions(
                                      selectedAssessment?.questions || []
                                    );
                                  }}
                                >
                                  <option value="">SELECT ASSESSMENT</option>
                                  {this.state.assessmentTypes.map(
                                    (option, key) => (
                                      <option value={option.value} key={key}>
                                        {option.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group">
                          <TextArea
                            label="Narrative"
                            name="comment"
                            required={false}
                            rows="5"
                          />
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        {this.state.questions.map((question, key) => (
                          <div className="row" key={key}>
                            <div className="col-6">
                              <p className="mt-3">{question.prompt}</p>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    const answerText = e.currentTarget.value;
                                    this.state.questions[key].answer.value =
                                      answerText;
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col"></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  patientTimer: state.patient.timerByUserPatientId,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    setTimer: (time: UserPatientTimerState) =>
      dispatch(dispatchSetPatientTimer(time)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AssessmentNoteForm);
