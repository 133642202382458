import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Dropdown, IconButton, IconButtonProps } from "rsuite";
import { Dispatch } from "redux";
import { FormikHelpers } from "formik";
import { Menu } from "@rsuite/icons";
import { DateTime } from "luxon";
import cx from "clsx";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Patient } from "@/domain/patient/model/types";
import {
  dispatchDeselectAllPatients,
  dispatchSelectPatient,
  dispatchSetPatients,
  dispatchSetPatientTimer,
  dispatchUpdatePatient,
} from "@/domain/patient/redux/actions";
import { Writable } from "@/domain/patient/view/form/manageProgram/types";
import { dispatchSetReturnKitModalActive } from "@/domain/kits/redux/actions";
import { RootState } from "@/types";
import { UserPatientTimerState } from "@/domain/patient/redux/types";
import { User } from "@/domain/user/model";
import { KitReturnModal } from "@/domain/kits/view/KitReturnModal";
import { KitShipmentFormValues } from "@/domain/kits/view/KitShipmentForm/types";
import {
  PatientState,
  PatientStateMachine,
  PatientStateTransition,
  PatientTransition,
} from "@/domain/patient/view/form/manageState/types";
import { OrderModel } from "@/domain/order/model";
import { Notification } from "@/components/notification/notification";
import { Modal } from "@/components/modal";
import { CURRENT_DATE, ERROR, SUCCESS } from "@/library/constants";
import { RCAResponseErrorParser } from "@/library/error/parser/rca.error.parser";
import { LogisticMethod, Order } from "@/domain/order/model/types";
import { ReturnMethods } from "@/domain/kits/view/KitShipmentForm/constants";
import { arrClone } from "@/util/clone";
import { convertDateToLocalTimezone } from "@/util/dateToLocalTimezone";
import { transitionState } from "../form/manageState/state";
import { PatientModel } from "../../model";
import { CreateNoteModal, NoteFormType } from "../CreateNoteModal";
import { RiskLevel } from "./RiskLevel";
import Status from "./Status";
import ManageReminders from "./Reminders/manageReminders";

import bannerStyles from "@/domain/patient/view/PatientBanners/styles.module.scss";
import styles from "./styles.module.scss";

export type OwnProps = {
  patient: Patient;
  includeTimerOptions?: boolean;
};
export type ConnectedProps = {
  selectedPatient: Patient[];
  patientTimer: { [key: string]: UserPatientTimerState };
  currentUser: User | null;
};
export type DispatchProps = {
  setSelectedPatient: (patient: Patient) => void;
  deselectAllPatients: () => void;
  setTimer: (timer: UserPatientTimerState) => void;
  setReturnKitModalActive: (active: boolean) => void;
};
export type Props = OwnProps & ConnectedProps & DispatchProps;

export const BannerMenu: React.FC<Props> = (props) => {
  const isAlertScoreFeatureEnabled =
    _env_.ENABLE_SEVERITY_SCORE_FEATURE === "true";

  const isPatientRemindersFeatureEnabled =
    _env_.ENABLE_PATIENT_REMINDERS_FEATURE === "true";

  const { patient } = props;

  const hasTimerInNotes = !props.includeTimerOptions;

  const patientModel = PatientModel.make(patient);
  const { lifecycle } = patient || {};
  const [selectedStatus, setSelectedStatus] = useState<
    PatientState | undefined
  >();
  const [disableAcceptBtn, setDisableAcceptBtn] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showClinicalNoteModal, setShowClinicalNoteModal] = useState(false);
  const [showBiometricModal, setShowBiometricModal] = useState(false);
  const [showPathwayAssessmentModal, setShowPathwayAssessmentModal] =
    useState(false);
  const [showReturnKitModal, setShowReturnKitModal] = useState(false);
  const [showRemindersModal, setShowRemindersModal] = useState(false);

  const patients = useSelector((state: RootState) => state.patient.patients);
  useSelector((state: RootState) => state.patient.selectedPatients);
  const dispatch = useDispatch();
  const renderSubmenuTitle = (title: string) => (
    <>
      <i className={cx("fa fa-chevron-left", bannerStyles.menuChevron)} />{" "}
      {title}
    </>
  );

  const updatePatientDetailState = (editedPatient: Partial<Patient>) => {
    if (props.selectedPatient && props.selectedPatient.length > 0) {
      props.selectedPatient.forEach((patient: Patient) => {
        if (patient.id === editedPatient.id) {
          patient = { ...patient, ...editedPatient };
          props.deselectAllPatients();
          props.setSelectedPatient(patient);
        }
      });
    }
  };

  const handleReturnKitModalOpen = () => setShowReturnKitModal(true);
  const handleReturnKitModalClose = () => setShowReturnKitModal(false);
  const handleReturnKitModalSubmit = async (
    values: KitShipmentFormValues,
    form: FormikHelpers<KitShipmentFormValues>
  ) => {
    form.setSubmitting(true);
    setTimeout(() => form.setSubmitting(false), 500);
    const additionalFulfillmentEmailAddresses = values.contactEmail
      .split(",")
      .slice(1)
      .map((email) => email.trim());
    const returnKitOrderOwned: Order = {
      type: "RETURN",
      fulfillment: "CUSTOMER",
      patientId: patient.id!,
      method:
        values.returnMethod === ReturnMethods.pickup
          ? LogisticMethod.Manual
          : LogisticMethod.Courier,
      origin: {
        name: values.pickupName,
        address: values.pickupAddress,
        needsShippingMaterials: values.sendReplacementBoxes === "yes",
        requestedDate: DateTime.fromISO(values.pickupDate).toISODate(),
        requestedTimeWindow: values.pickupTime,
      },
      destination:
        values.shipToName && values.shipToAddress
          ? {
              name: values.shipToName,
              address: values.shipToAddress,
            }
          : undefined,
      deviceId: values.device?.id!,
      specialInstructions: values.specialInstructions,
      fulfillmentEmailAddress: values.contactEmail.split(",")[0],
      additionalFulfillmentEmailAddresses,
    };

    try {
      // initiate owned kit (customer order) return
      await OrderModel.createOrder(returnKitOrderOwned);
      Notification.notify(SUCCESS, "Return Order successfully created.");
      const updatedPatient = {
        ...props.patient,
        deviceInfo: undefined,
      };
      dispatch(dispatchUpdatePatient(updatedPatient));
      await handleAccept();
      handleReturnKitModalClose();
    } catch (error: any) {
      if (error.status || error.response) {
        Notification.notify(
          ERROR,
          RCAResponseErrorParser.parse(error).message()
        );
      } else {
        throw error;
      }
    }
  };

  const handleSuccess = (): void => {
    handleShowConfirmModal(false);
    setDisableAcceptBtn(false);
  };

  const handleError = (error: any): void => {
    setDisableAcceptBtn(false);
    if (error.status || error.response) {
      Notification.notify(ERROR, RCAResponseErrorParser.parse(error).message());
      return;
    } else {
      throw error;
    }
  };

  const updateReduxState = (transition: PatientTransition): void => {
    const _patients: Writable<Patient[]> = arrClone(patients) as Patient[];
    const updatedLifeCycle = {
      ...lifecycle,
      state: PatientStateMachine[transition].resultingState,
      history: [
        ...(lifecycle?.history ?? []),
        {
          time: convertDateToLocalTimezone(CURRENT_DATE).toUTC().toISO(),
          toState: selectedStatus!,
          transition: transition,
        },
      ],
    };
    const updatedPatient = {
      ...props.patient,
      lifecycle: updatedLifeCycle,
    };
    dispatch(dispatchUpdatePatient(updatedPatient));

    // update targeted patient in the patients banner list
    const updatedPatients = _patients.map((patient) => {
      if (patient.id === props.patient.id) {
        patient.lifecycle = updatedLifeCycle;
      }
      return patient;
    });

    dispatch(dispatchSetPatients(updatedPatients));
  };

  const commonProps = {
    patient,
    updatePatientDetailState,
    handleReturnKitClick: handleReturnKitModalOpen,
  };
  const handleShowConfirmModal = (val: boolean) => setShowConfirmModal(val);
  const handleAccept = async (): Promise<void> => {
    setDisableAcceptBtn(true);
    const transition: PatientTransition | undefined = selectedStatus
      ? transitionState(lifecycle.state, selectedStatus, PatientStateTransition)
      : undefined;

    if (transition) {
      try {
        await patientModel.updateLifecycleState({ transition });
        updateReduxState(transition);
        handleSuccess();
        if (
          selectedStatus === PatientState.Completed &&
          props.patient.deviceInfo
        ) {
          props.setReturnKitModalActive(false);
        }
      } catch (e) {
        handleError(e);
      }
    }
  };

  const currentUserInPatientWatchList = () => {
    return !!patient.watchingUserIds?.find(
      (id) => props.currentUser?._meta && props.currentUser._meta.id === id
    );
  };
  const updatePatientList = (watchingUserIds: string[]) => {
    const _patients: Writable<Patient[]> = arrClone(patients) as Patient[];
    const updatedPatients = _patients.map((selectedPatient) => {
      if (selectedPatient.id === patient.id) {
        selectedPatient.watchingUserIds = watchingUserIds;
      }
      return selectedPatient;
    });
    dispatch(dispatchSetPatients(updatedPatients));
  };

  return (
    <>
      {" "}
      <button
        id="banner-dropdown"
        className={styles.bannerDropdown}
        onClick={async () => {
          props.deselectAllPatients();
          props.setSelectedPatient(patient);
        }}
      >
        <Dropdown
          placement="leftStart"
          renderToggle={(props: IconButtonProps, ref: any) => (
            <IconButton
              {...props}
              ref={ref}
              icon={<Menu />}
              circle
              className={bannerStyles.iconBanner}
            />
          )}
        >
          <Dropdown.Item
            onSelect={() => {
              setShowClinicalNoteModal(true);
            }}
          >
            Enter Chart Note
          </Dropdown.Item>

          <Dropdown.Item divider />

          <Dropdown
            placement="leftStart"
            title={renderSubmenuTitle("Assign Risk Level")}
          >
            <RiskLevel {...commonProps} />
          </Dropdown>

          <Dropdown.Item divider />

          <Dropdown
            placement="leftStart"
            title={renderSubmenuTitle("Manual Entry")}
          >
            <Dropdown.Item
              onSelect={() => {
                setShowBiometricModal(true);
              }}
            >
              Biometric Measurement
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={() => {
                setShowPathwayAssessmentModal(true);
              }}
            >
              Pathway Assessment
            </Dropdown.Item>
          </Dropdown>
          {isPatientRemindersFeatureEnabled && (
            <Dropdown.Item
              onSelect={() => {
                setShowRemindersModal(true);
              }}
            >
              Manage Reminders
            </Dropdown.Item>
          )}
          {patient?.lifecycle?.state !== PatientState.Completed ? (
            <>
              <Dropdown.Item divider />
              <Dropdown
                placement="leftStart"
                title={renderSubmenuTitle("Change Status")}
              >
                <Status
                  {...commonProps}
                  onSelectStatus={setSelectedStatus}
                  onDisableAcceptBtn={setDisableAcceptBtn}
                  onShowConfirmModal={handleShowConfirmModal}
                  handleReturnKitClick={handleReturnKitModalOpen}
                />
              </Dropdown>
            </>
          ) : null}
        </Dropdown>
      </button>
      <IconButton
        onClick={async () => {
          const currentUserId =
            props.currentUser?._meta && props.currentUser?._meta.id;
          if (currentUserId) {
            if (currentUserInPatientWatchList()) {
              await PatientModel.assignWatchers(patient.id!, {
                delete: [currentUserId],
              });
              const watchingUserIds = patient.watchingUserIds?.filter(
                (id: string) => id !== currentUserId
              );
              dispatch(
                dispatchUpdatePatient({
                  ...patient,
                  watchingUserIds,
                })
              );
              if (watchingUserIds) updatePatientList(watchingUserIds);
              Notification.notify(
                SUCCESS,
                "Watchlist successfully un-assigned."
              );
            } else {
              await PatientModel.assignWatchers(patient.id!, {
                set: [currentUserId],
              });
              const watchingUserIds = patient.watchingUserIds
                ? [...patient.watchingUserIds, ...[currentUserId]]
                : [currentUserId];
              dispatch(
                dispatchUpdatePatient({
                  ...patient,
                  watchingUserIds,
                })
              );
              if (watchingUserIds) updatePatientList(watchingUserIds);
              Notification.notify(SUCCESS, "Watchlist successfully assigned.");
            }
          }
        }}
        className={styles.watchlistIcon}
      >
        <VisibilityIcon
          style={{
            color: currentUserInPatientWatchList() ? "black" : "lightgray",
          }}
        />
      </IconButton>
      {isAlertScoreFeatureEnabled && <span>{props.patient.sortScore}</span>}
      <span className="text-left">
        <CreateNoteModal
          formType={NoteFormType.ClinicalNote}
          includeNoteTimer={hasTimerInNotes}
          show={showClinicalNoteModal}
          patient={props.patient}
          handleClose={() => setShowClinicalNoteModal(false)}
        />
        <CreateNoteModal
          formType={NoteFormType.ObservationNote}
          includeNoteTimer={hasTimerInNotes}
          show={showBiometricModal}
          patient={props.patient}
          handleClose={() => setShowBiometricModal(false)}
        />
        <CreateNoteModal
          formType={NoteFormType.AssessmentNote}
          includeNoteTimer={hasTimerInNotes}
          show={showPathwayAssessmentModal}
          patient={props.patient}
          handleClose={() => setShowPathwayAssessmentModal(false)}
        />
        {showReturnKitModal && (
          <KitReturnModal
            open={showReturnKitModal}
            onClose={handleReturnKitModalClose}
            onSubmit={handleReturnKitModalSubmit}
            patient={patient}
          />
        )}
      </span>
      <Modal
        size={"xs"}
        handleClose={() => setShowConfirmModal(false)}
        show={showConfirmModal}
        heading={"Confirm change status"}
        button={"Accept"}
      >
        {showConfirmModal && (
          <>
            <p
              style={{
                padding: "10px",
                fontSize: "17px",
              }}
            >
              You are about to change the patient's status to{" "}
              <b>{selectedStatus}</b>.
            </p>
            <div className="row">
              <div className="col-12 mt-4 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={() => setShowConfirmModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={disableAcceptBtn}
                  onClick={handleAccept}
                  className="btn btn-primary mr-2"
                >
                  Accept
                </button>
              </div>
            </div>
          </>
        )}
      </Modal>
      <ManageReminders
        {...commonProps}
        open={showRemindersModal}
        onClose={() => {
          setShowRemindersModal(false);
        }}
        onSubmit={() => {}}
        selectedPatient={props.selectedPatient}
      />
    </>
  );
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    setSelectedPatient: (patient: Patient) =>
      dispatch(dispatchSelectPatient(patient)),
    deselectAllPatients: () => dispatch(dispatchDeselectAllPatients()),
    setTimer: (timer: UserPatientTimerState) =>
      dispatch(dispatchSetPatientTimer(timer)),
    setReturnKitModalActive: (active: boolean) =>
      dispatch(dispatchSetReturnKitModalActive(active)),
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedPatient: state.patient.selectedPatients,
    patientTimer: state.patient.timerByUserPatientId,
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatcherToProps)(BannerMenu);
