export const CREATE_NOTE = "CREATE_NOTE";
export const SET_NOTE_PAGE_LIMIT = "SET_NOTE_PAGE_LIMIT";
export const SET_NOTES_PAGINATIONCONFIG = "SET_NOTES_PAGINATIONCONFIG";
export const SELECT_NOTE = "SELECT_NOTE";
export const DESELECT_NOTE = "DESELECT_NOTE";
export const SET_NOTES = "SET_NOTES";
export const SET_TARGET_NOTE_ID = "SET_TARGET_NOTE_ID";
export const SET_PATIENT_NOTES = "SET_PATIENT_NOTES";
export const SET_UNRESOLVED_PATIENT_NOTES = "SET_UNRESOLVED_PATIENT_NOTES";
export const RESOLVE_NOTES = "RESOLVE_NOTES";
export const ADD_REJECTED_NOTE_ID = "ADD_REJECTED_NOTE_ID";
export const ADD_PATIENT_NOTE = "ADD_PATIENT_NOTE";
