import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { Header } from "@/components/Header";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { OrganizationModel } from "@/domain/organization/model";
import { dispatchSelectOrganization } from "@/domain/organization/redux/actions";
import { selectActiveOrganization } from "@/domain/organization/redux/selectors";
import { PracticeModel } from "@/domain/practice/model";
import { UserParentTypes } from "@/domain/user/model";
import { FILTER_STRING, MODEL_SYNCED } from "@/library/constants";
import { ModelCollection } from "@/library/model";
import { PaginationConfig } from "@/library/types";
import { DeviceState, PatientDevice } from "../models/device.interface";
import { DeviceModel } from "../models/device.model";
import { attachPatientNameToDevices, peripheralOptions } from "./helper";
import KitManagementTable from "./table";
import {
  selectKitFilters,
  selectedKitSortedState,
  selectKits,
  selectKitPagePaginationConfig,
} from "../redux/selectors";
import { TagModel } from "@/domain/tags/model";
import {
  dispatchSetKitFilters,
  dispatchSetKitPaginationConfig,
  dispatchSetKits,
  dispatchSetKitSortedState,
} from "../redux/actions";
import { AssignKitLocation } from "./AssignKitLocation";
import { dispatchSetLocationTags } from "@/domain/tags/redux/actions";
import headerStyles from "@/components/Header/styles.module.scss";

export const KitManagement = () => {
  const selectedOrganization = useSelector(selectActiveOrganization);
  const kitFilters = useSelector(selectKitFilters);
  const kitSortedState = useSelector(selectedKitSortedState);
  const paginationConfig = useSelector(selectKitPagePaginationConfig);
  const kits = useSelector(selectKits);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState("");

  const dispatch = useDispatch();

  const [locationOptions, setLocationOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [searchInput, setSearchInput] = useState<string>("");

  const handleSelectOrganization = async (orgId: string | undefined) => {
    if (orgId) {
      setSelectedOrganizationId(orgId);
    }
    resetFilter();
    const org = orgId ? await OrganizationModel.sync(orgId) : undefined;
    dispatch(dispatchSelectOrganization(org ? org.pluckAll() : org));
    const path = "kits";
    window.history.replaceState(null, ``, `/${path}?organizationId=${orgId}`);
  };
  const handleSelectPractices = (practiceIds: string[] | undefined) => {
    dispatch(dispatchSetKitFilters({ ...kitFilters, practiceIds }));
  };

  const generateDeviceQuery = async (q: Record<string, any> = {}) => {
    const { status, serviceLevel, tagIds, ...filters } = kitFilters;
    const { sortedColumnIndex, ...sortedState } = kitSortedState;

    const allPracticeIds = await getPracticeIds();
    return {
      ...filters,
      ...sortedState,
      ...q,
      ...(selectedOrganization
        ? {
            organizationIds: [
              selectedOrganizationId.length
                ? selectedOrganizationId
                : selectedOrganization.id!,
            ],
          }
        : {}),
      searchBy: q.name,
      practiceIds: allPracticeIds,
      includeTagInfo: 1,
      ...(status === "assigned"
        ? { isAssigned: true }
        : status === "available"
        ? { isAssigned: false, states: [DeviceState.Active] }
        : status === "dirty"
        ? { isAssigned: false, states: [DeviceState.Dirty] }
        : status === "reprocessing"
        ? { states: [DeviceState.Reprocessing] }
        : {}),
      ...(serviceLevel === "" || !serviceLevel
        ? {}
        : { serviceLevel: serviceLevel }),
      tags: tagIds && tagIds.length > 0 ? tagIds : undefined,
    };
  };

  const execSearch = async (q?: Record<string, any>) => {
    if (!selectedOrganization) return;
    setSearchInput(q?.name || "");
    LoadingIndicator.fire.show();
    const deviceCollection: ModelCollection<DeviceModel, PatientDevice> =
      DeviceModel.makeDevicesCollection();
    deviceCollection.on(MODEL_SYNCED, () => LoadingIndicator.fire.hide());

    const deviceQuery = await generateDeviceQuery(q);

    const paginateConfig: PaginationConfig = await deviceCollection.fetch(
      deviceQuery
    );
    const devices = deviceCollection.container.map((dm) => dm.pluckAll());
    await attachPatientNameToDevices(devices);

    dispatch(dispatchSetKits(devices));
    dispatch(dispatchSetKitPaginationConfig(paginateConfig));

    LoadingIndicator.fire.hide();
  };

  const handlePageLimitChange = (limit: number) => {
    dispatch(dispatchSetKitFilters({ ...kitFilters, limit }));
  };

  useEffect(() => {
    execSearch();
  }, [kitFilters, kitSortedState]);

  const handleFetchLocations = async () => {
    const allPracticeIds = await getPracticeIds();

    const tags = await TagModel.fetchAllTagsWithIterator({
      type: "location",
      practiceIds: allPracticeIds,
    });
    dispatch(dispatchSetLocationTags(tags));
    setLocationOptions(
      (tags || []).map((tag) => {
        return {
          label: tag.value,
          value: tag._meta.id,
        };
      })
    );
  };

  const getPracticeIds = async () => {
    let allPracticeIds: string[] = [];
    if (kitFilters.practiceIds?.length === 0 && selectedOrganization) {
      const practiceModels = await PracticeModel.fetchByOrganizationIds([
        selectedOrganizationId.length
          ? selectedOrganizationId
          : selectedOrganization.id!,
      ]);
      allPracticeIds = (practiceModels || []).map((pm) => pm.pluck("id")!);
    } else {
      allPracticeIds = kitFilters.practiceIds || [];
    }

    return allPracticeIds;
  };

  const handleSelectLocation = async (ids: string[]) => {
    dispatch(dispatchSetKitFilters({ ...kitFilters, tagIds: ids }));
  };

  const handleSelectPeripherals = async (peripherals: string[]) => {
    dispatch(
      dispatchSetKitFilters({ ...kitFilters, peripherals: peripherals })
    );
  };

  const resetFilter = () => {
    dispatch(
      dispatchSetKitFilters({
        organizationIds: undefined,
        practiceIds: [],
        currentPage: 1,
        limit: 50,
        includeTagInfo: 1,
        tagIds: undefined,
        status: "",
      })
    );

    dispatch(
      dispatchSetKitSortedState({
        direction: undefined,
        sortBy: undefined,
        sortedColumnIndex: undefined,
      })
    );

    dispatch(
      dispatchSetKitPaginationConfig({
        totalResources: 0,
        currentPage: 1,
        lastPage: 1,
      })
    );
  };

  useEffect(() => {
    execSearch();
    handleFetchLocations();
  }, [selectedOrganization]);

  const renderLocationSelect = () => {
    return (
      <>
        <AssignKitLocation />
      </>
    );
  };

  return (
    <>
      <Header
        isNav={true}
        entity={{
          name: "Kit Management",
          displayName: "",
          selectedEntityLength: kits.length,
          totalEntityLength: paginationConfig.totalResources,
        }}
        onResetClick={resetFilter}
        button={{
          create: {},
          toggleCollapse: {
            onToggle: () => {},
            collapsed: false,
          },
        }}
        table={{ pageLimit: kitFilters.limit! }}
        search={{
          onSearch: execSearch,
          searchStr: searchInput,
          filterStr: FILTER_STRING,
          filters: {},
          placeholder: "Search on ICCID or Kit Number",
        }}
        organizationFilter={{
          enabled: true,
          onSelectOrganization: handleSelectOrganization,
        }}
        practiceFilter={{
          enabled: true,
          onSelectPractices: handleSelectPractices,
          selectedPractices: kitFilters.practiceIds,
        }}
        className="pl-2"
        searchRight={renderLocationSelect()}
      >
        <>
          <div className={headerStyles.col}>
            <BasicMultiSelect
              value={kitFilters.tagIds}
              data={locationOptions}
              onChange={async (value: string) => {}}
              isSearchable={false}
              placeholder="All Location"
              onSelect={handleSelectLocation}
              onOpen={handleFetchLocations}
              picker="check"
              onClear={() => {
                dispatch(dispatchSetKitFilters({ ...kitFilters, tagIds: [] }));
              }}
            />
          </div>
          <div className={headerStyles.col}>
            <BasicMultiSelect
              value={kitFilters.peripherals}
              data={peripheralOptions}
              onChange={async (value: string) => {}}
              isSearchable={false}
              placeholder="Choose Peripherals"
              onSelect={handleSelectPeripherals}
              picker="check"
              onClear={() => {
                dispatch(
                  dispatchSetKitFilters({ ...kitFilters, peripherals: [] })
                );
              }}
            />
          </div>
          <div className="col" />
        </>
      </Header>

      <div className="card-body">
        <KitManagementTable
          devices={kits}
          execSearch={execSearch}
          pageLimit={kitFilters.limit!}
          onPageLimitChange={handlePageLimitChange}
          paginationConfig={paginationConfig}
        />
      </div>
    </>
  );
};
