import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Dispatch } from "redux";

import { Content } from "@/components/content";
import { Sidebar } from "@/components/Sidebar";
import { ProfileComponent } from "@/domain/profile/index";
import { PracticeModel } from "@/domain/practice/model";
import { OrganizationComponent } from "@/domain/organization/view";
import { UserManagementComponent } from "@/domain/user/view/user.component";
import { OrganizationPage } from "@/domain/organization/view/OrganizationPage";
import { User, UserModel } from "@/domain/user/model";
import { setCurrentUser } from "@/domain/user/redux/user.actions";
import { PatientDetails } from "@/domain/patient/view/PatientDetails";
import { PatientFilters } from "@/domain/patient/model/types";
import { KitManagement } from "@/domain/kits/view/index";
import { UserRouter } from "@/library/router/";
import { RootState } from "@/types";
import { DashboardPage } from "@/domain/user/view/DashboardPage";
import { ReportsComponent } from "@/pages/reports/view/reports.component";
import {
  attachParentEntityAndClassification,
  attachUserRoleName,
} from "@/domain/user/redux/user.middleware";
import { dispatchSetPatientFilters } from "@/domain/patient/redux/actions";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { Props, State, ConnectedProps, DispatchProps } from "./types";
import { PatientsPage } from "@/domain/patient/view/PatientsPage";
import SideMenu from "@/components/SideMenu";

class Component extends React.Component<Props, State> {
  state: Readonly<State> = {
    statusCode: 1,
    orgId: "",
    intervalId: 0,
  };

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  async componentDidMount() {
    const { setCurrentUser } = this.props;

    LoadingIndicator.fire.show();
    const user = await UserModel.authenticateMe();
    const { _env_ } = window as typeof window & {
      _env_: Record<string, string>;
    };

    if (!user) {
      window.location.assign(
        `${_env_.AUTH_URL}/auth/login?callback=${_env_.MAVERICK_URL}`
      );

      return;
    }
    this.setState({ statusCode: 200 });
    const userModel = UserModel.make(user);
    const userWithParentEntity = await attachParentEntityAndClassification(
      user
    );
    const userWithRoleName = await attachUserRoleName(userWithParentEntity);
    await setCurrentUser({
      ...userWithRoleName,
      id: user._meta?.id,
    });

    if (userModel.hasPracticeParentType && userModel.parentId) {
      const practiceModel: PracticeModel = await PracticeModel.sync(
        userModel.parentId
      );
      const organizationIds = practiceModel.attributes.get("organizationIds");
      this.setState({ orgId: organizationIds[0] });
    }

    if (userModel.hasOrganizationParentType) {
      this.setState({ orgId: userModel.parentId });
    }
    LoadingIndicator.fire.hide();

    const intervalId = setInterval(async () => {
      try {
        await UserModel.authenticateMe();
      } catch (error: any) {
        // logout if unauthorized
        if (!user || error.response.status === 401) {
          window.location.assign(
            `${_env_.AUTH_URL}/auth/login?callback=${_env_.MAVERICK_URL}`
          );
        }
      }
    }, 30000) as unknown as number;

    this.setState((prevState) => {
      return {
        ...prevState,
        intervalId,
      };
    });
  }

  render() {
    const { currentUser, selectedOrganization } = this.props;
    const { orgId } = this.state;
    const isCANewNavigationEnabled = _env_.ENABLE_CA_SIDE_NAVIGATION === "true";

    return (
      <BrowserRouter>
        {currentUser && (
          <div id="app">
            {isCANewNavigationEnabled ? <SideMenu /> : <Sidebar />}
            <div style={{ marginLeft: `21px` }}>
              <Content>
                <Switch>
                  <Route exact path="/">
                    <Redirect to={UserRouter.routeMe(currentUser).to} />
                  </Route>
                  <Route
                    exact
                    path="/patients"
                    render={(props) => (
                      <PatientsPage
                        {...props}
                        tableType={"clinical"}
                        displayName="Patients"
                        collapsible={false}
                        shouldCreate={true}
                        bannerView={true}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/user-management"
                    component={UserManagementComponent}
                  />
                  <Route
                    exact
                    path="/organizations"
                    component={OrganizationComponent}
                  />
                  <Route exact path="/profile" component={ProfileComponent} />
                  <Route
                    exact
                    path="/practices"
                    render={() => (
                      <OrganizationPage
                        // organizationId={selectedOrganization?.id || orgId}
                        // @ts-ignore
                        match={{
                          params: {
                            organizationId: selectedOrganization
                              ? selectedOrganization.id!
                              : orgId,
                          },
                        }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/organizations/:organizationId"
                    component={OrganizationPage}
                  />
                  <Route
                    exact
                    path="/patient/:patientId/:activeTab?"
                    component={PatientDetails}
                  />
                  <Route exact path="/reports" component={ReportsComponent} />
                  <Route
                    exact
                    path="/user-dashboard"
                    component={DashboardPage}
                  />
                  <Route exact path="/kits" component={KitManagement} />
                </Switch>
              </Content>
            </div>
          </div>
        )}
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state: RootState): ConnectedProps => {
  return {
    currentUser: state.user.currentUser,
    selectedOrganization: state.organization.selectedOrganization,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    setCurrentUser: (user: User): { type: string; payload: User } =>
      dispatch(setCurrentUser(user)),
    setPatientFilters: (filters: PatientFilters) =>
      dispatch(dispatchSetPatientFilters(filters)),
  };
};

export const Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
