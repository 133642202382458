import React, { useMemo, useState } from "react";
import { TooltipFormatterContextObject } from "highcharts";

import LineChart from "@/components/charts/HighChart.component";
import {
  COLORS,
  makeChartOptions,
} from "@/domain/patient/view/charts/constants";
import { parseTooltipDate } from "@/domain/patient/view/tabs/monitoring/helpers";
import { ObservationUnits } from "@/domain/patient/model/constants";
import {
  getAlertLevelColor,
  getSeverityAlertLevel,
} from "@/domain/notes/model/constants";
import { Props } from "./types";
import {
  selectMonitoringStartDate,
  selectMonitoringEndDate,
} from "@/domain/patient/redux/selectors";
import { useSelector } from "react-redux";
import {
  ObservationsUnitAbbrevation,
  ObservationType,
} from "@/domain/observations/types";
import { displayObsUnit } from "@/domain/observations/helpers";

export const TemperatureChart: React.FC<Props> = (props) => {
  const { temperatures, patientDetail } = props;
  const isoStartDate = useSelector(selectMonitoringStartDate);
  const isoEndDate = useSelector(selectMonitoringEndDate);
  const [isPrinting, setIsPrinting] = useState(false);

  const chartConfig = useMemo(() => {
    return makeChartOptions({
      yTitle: displayObsUnit(ObservationType.Temperature) as string,
      events: {
        beforePrint: async () => {
          setIsPrinting(true);
        },
        afterPrint: () => {
          setIsPrinting(false);
        },
      },
      xAxis: {
        min: isoStartDate,
        max: isoEndDate,
      },
      tooltip: {
        useHTML: true,
        valueSuffix: "",
        formatter: function (this: TooltipFormatterContextObject) {
          return parseTooltipDate(
            ObservationType.Temperature,
            this,
            `º${ObservationsUnitAbbrevation.TEMPERATURE}`,
            "Temperature"
          );
        },
      },
      plotOptions: {
        spline: {
          pointInterval: 3600000, // one hour
        },
      },
      series: [
        {
          name: "Temperature",
          color: COLORS.primary.line,
          data: temperatures.map((tp) => {
            const { severityScore } = tp;

            if (tp.value && tp.unit === ObservationUnits.Celsius) {
              tp.value = (tp.value * 9) / 5 + 32;
            }
            return {
              x: Date.parse(
                new Date(
                  tp.timeOfObservation ? tp.timeOfObservation : tp._meta.created
                ).toISOString()
              ),
              y: tp.value,
              marker: {
                fillColor: getAlertLevelColor(
                  getSeverityAlertLevel(severityScore)
                ),
              },
            };
          }),
        },
      ],
      caption: {
        text: isPrinting ? patientDetail : "",
      },
      time: {
        useUTC: false,
      },
    });
  }, [temperatures, patientDetail, isPrinting]);

  return (
    <LineChart
      containerProps={{ id: "temperature-chart" }}
      options={chartConfig.options}
    />
  );
};
