import React, { useMemo, useState } from "react";
import { TooltipFormatterContextObject } from "highcharts";

import LineChart from "@/components/charts/HighChart.component";
import { parseTooltipDate } from "@/domain/patient/view/tabs/monitoring/helpers";
import {
  ObservationUnits,
  PressureUnits,
} from "@/domain/patient/model/constants";
import {
  COLORS,
  makeChartOptions,
} from "@/domain/patient/view/charts/constants";
import {
  getAlertLevelColor,
  getSeverityAlertLevel,
} from "@/domain/notes/model/constants";
import { Props } from "./types";
import {
  selectMonitoringStartDate,
  selectMonitoringEndDate,
} from "@/domain/patient/redux/selectors";
import { useSelector } from "react-redux";
import {
  ObservationsUnitAbbrevation,
  ObservationType,
} from "@/domain/observations/types";
import { displayObsUnit } from "@/domain/observations/helpers";

export const BloodPressureChart: React.FC<Props> = (props) => {
  const { bloodPressures, patientDetail } = props;
  const isoStartDate = useSelector(selectMonitoringStartDate);
  const isoEndDate = useSelector(selectMonitoringEndDate);
  const [isPrinting, setIsPrinting] = useState(false);

  const chartConfig = useMemo(() => {
    return makeChartOptions({
      yTitle: displayObsUnit(ObservationType.BloodPressure) as string,
      events: {
        beforePrint: async () => {
          setIsPrinting(true);
        },
        afterPrint: () => {
          setIsPrinting(false);
        },
      },
      tooltip: {
        useHTML: true,
        valueSuffix: PressureUnits.mmhg,
        formatter: function (this: TooltipFormatterContextObject) {
          return parseTooltipDate(
            ObservationType.BloodPressure,
            this,
            `${ObservationsUnitAbbrevation.BLOOD_PRESSURE}`
          );
        },
      },
      caption: {
        text: isPrinting ? patientDetail : "",
      },
      xAxis: {
        min: isoStartDate,
        max: isoEndDate,
      },
      series: [
        // Systolic BP
        {
          align: "right",
          name: "Systolic",
          color: COLORS.primary.line,
          colorAxis: 0,
          data: bloodPressures.map((bp) => {
            const { severityScore = 0 } = bp;

            const mmHgsInOneKpa = 7.501;
            const value =
              bp.systolic && bp.systolic.unit === ObservationUnits.KiloPascal
                ? bp.systolic.value * mmHgsInOneKpa
                : bp.systolic?.value || 0;

            return {
              x: Date.parse(
                new Date(
                  bp.timeOfObservation ? bp.timeOfObservation : bp._meta.created
                ).toISOString()
              ),
              y: value,
              marker: {
                fillColor: getAlertLevelColor(
                  getSeverityAlertLevel(severityScore)
                ),
              },
            };
          }),
        },
        // Diastolic BP
        {
          name: "Diastolic",
          color: COLORS.secondary.line,
          colorAxis: 1,
          data: bloodPressures.map((bp) => {
            const { severityScore = 0 } = bp;

            const mmHgsInOneKpa = 7.501;
            const value =
              bp.diastolic && bp.diastolic.unit === ObservationUnits.KiloPascal
                ? bp.diastolic.value * mmHgsInOneKpa
                : bp.diastolic?.value || 0;

            return {
              x: Date.parse(
                new Date(
                  bp.timeOfObservation ? bp.timeOfObservation : bp._meta.created
                ).toISOString()
              ),
              y: value,
              marker: {
                fillColor: getAlertLevelColor(
                  getSeverityAlertLevel(severityScore)
                ),
              },
            };
          }),
        },
      ],
      colorAxis: [
        {
          showInLegend: false,
          reversed: true,
          labels: {
            format: "{value}%",
          },
        },
        {
          showInLegend: false,
          labels: {
            format: "{value}%",
          },
        },
      ],
      time: {
        useUTC: false,
      },
    });
  }, [bloodPressures, patientDetail, isPrinting]);

  return (
    <LineChart
      containerProps={{ id: "blood-pressure-chart" }}
      options={chartConfig.options}
    />
  );
};
