import React, { useEffect, useState } from "react";
import { Table } from "@/components/table";
import { PaginationConfig } from "@/library/types";
import styles from "@/components/Header/styles.module.scss";
import cx from "clsx";
import globalStyles from "@/styles/globals.module.scss";
import { TimeLogData } from "@/domain/patient/view/tabs/audit/index";
import ArrowDownIcon from "@rsuite/icons/ArrowDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

type TableProps = {
  headers: string[];
  data: any[];
  onNavigate: (query: {}) => any;
  onPageLimitChange: (limit: number) => any;
  pageLimit: number;
  paginationConfig: PaginationConfig;
};
export const TimeLogTable: React.FC<TableProps> = (props) => {
  const {
    headers,
    data,
    onNavigate,
    onPageLimitChange,
    pageLimit,
    paginationConfig,
  } = props;
  const [shouldCollapse, setShouldCollapse] = useState(true);
  const headerContents = [
    ...(headers || []).map((content, key) => {
      if (content.includes("/n")) {
        const splitContent = content.split("/n");
        return (
          <th
            key={key}
            className="bg-primary text-white th-text"
            style={{
              textTransform: "capitalize",
              verticalAlign: "bottom",
            }}
          >
            <span className="th-text">
              {splitContent[0]} <br /> {splitContent[splitContent.length - 1]}
            </span>
          </th>
        );
      }
      return (
        <th
          key={key}
          className="bg-primary text-white th-text"
          style={{
            textTransform: "capitalize",
            verticalAlign: "bottom",
          }}
        >
          <span className="th-text">{content}</span>
        </th>
      );
    }),
  ];
  const [timeLogNotes, setTimeLogNotes] = useState<JSX.Element[]>([]);
  const getTimeLogNotes = (data: any) => {
    return (data || []).map((note: TimeLogData) => {
      return (
        <tr key={note.id} className="td-text">
          <td>{note.timeStamp}</td>
          <td>
            <span>{note.user}</span>
          </td>
          <td>{note.timeDuration}</td>
          <td>{note.billable}</td>
          <td>{note.billableAccumulatedTime}</td>
          <td>{note.totalAccumulatedTime}</td>
        </tr>
      );
    });
  };
  useEffect(() => {
    const notes = getTimeLogNotes(data);
    setTimeLogNotes(notes);
  }, [data]);
  const toggleCollapse = () => {
    setShouldCollapse(!shouldCollapse);
  };
  return (
    <>
      <div>
        <div className={cx(styles.header, styles.row)}>
          <div className={styles.col}>
            <h5 className={cx(globalStyles.pageHeading)}>
              <button
                onClick={toggleCollapse}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                {shouldCollapse ? (
                  <ArrowDropDownIcon
                    sx={{ margin: "-0.3em", fontSize: "35px" }}
                  />
                ) : (
                  <ArrowRightIcon sx={{ margin: "-0.3em", fontSize: "35px" }} />
                )}
              </button>
              Time Log
            </h5>
          </div>
        </div>
      </div>
      {shouldCollapse && data && data.length && (
        <Table
          paginationConfig={paginationConfig}
          pageLimit={pageLimit}
          onPageLimitChange={onPageLimitChange}
          header={headerContents}
          data={timeLogNotes}
          onNavigate={onNavigate}
        />
      )}
    </>
  );
};
