import { ObservationReason } from "@/library/types/note";

// Observation Units
export enum PressureUnit {
  mmhg = "mmHg",
  kpa = "kPa",
}

export enum ConcentrationUnit {
  mmoll = "mmol/L",
}

export enum VolumeUnit {
  Liters = "L",
}

export enum FlowUnit {
  LitersPerMinute = "L/min",
}

export enum HeartRate {
  BeatPerMinute = "bpm",
}

export enum OxygenSaturation {
  SpO2 = "SpO2",
}

export enum TemperatureUnit {
  Fahrenheit = "F",
  Celsius = "C",
}

export enum WeightUnit {
  Pounds = "lbs",
  Kilograms = "kg",
}

export enum BloodSugarLevel {
  MilligramPerDeciliter = "mg/dL",
  mmoll = "mmol/L",
}

export enum ObservationType {
  BloodPressure = "BLOOD_PRESSURE",
  GlucoseLevel = "GLUCOSE_LEVEL",
  HeartRate = "HEART_RATE",
  PulseOximetry = "PULSE_OXIMETRY",
  Spirometry = "SPIROMETRY",
  Temperature = "TEMPERATURE",
  Weight = "WEIGHT",
}

/**
 * Observation Units support by RCA APIs
 */
export const ObservationsUnitAbbrevation: Record<ObservationType, string> = {
  [ObservationType.BloodPressure]: PressureUnit.mmhg,
  [ObservationType.Spirometry]: VolumeUnit.Liters,
  [ObservationType.GlucoseLevel]: BloodSugarLevel.MilligramPerDeciliter,
  [ObservationType.HeartRate]: HeartRate.BeatPerMinute,
  [ObservationType.PulseOximetry]: OxygenSaturation.SpO2,
  [ObservationType.Temperature]: TemperatureUnit.Fahrenheit,
  [ObservationType.Weight]: WeightUnit.Pounds,
};

export interface AssessmentSnapshot {
  id: string;
  isComplete: boolean;
  name: string;
  reason: string;
  severityScore: number;
  time: string;
  disposition: string | null;
}

export interface ObservationOverviewSnapshot {
  bloodPressure?: BloodPressureSnapshot | null;
  glucose?: ScalarObservationSnapshot | null;
  heartRate?: ScalarObservationSnapshot | null;
  pulseOximetry?: ScalarObservationSnapshot | null;
  spirometry?: SpirometrySnapshot | null;
  temperature?: ScalarObservationSnapshot | null;
  weight?: ScalarObservationSnapshot | null;
}

export interface ObservationSnapshot {
  isComplete: boolean;
  reason: ObservationReason;
  severityScore: number;
  time: string;
}

export type ObservationSnapshotValue = {
  fev1: {
    value: number;
    unit: string;
  };
  pef: {
    value: number;
    unit: string;
  };
  value: number;
  type: ObservationType;
  unit: string;
  actorUserId?: string;
  manualNoteId?: string;
};

export interface ScalarObservationSnapshot extends ObservationSnapshot {
  accessory?: { id: string; interface: string };
  value: number;
  unit: string;
  observation?: ObservationSnapshotValue;
}

export interface BloodPressureSnapshot extends ObservationSnapshot {
  systolic: {
    value: number;
    unit: string;
  };
  diastolic: {
    value: number;
    unit: string;
  };
  observation: {
    systolic: {
      value: number;
      unit: string;
    };
    diastolic: {
      value: number;
      unit: string;
    };
  };
}

export interface SpirometrySnapshot extends ObservationSnapshot {
  fev1: {
    value: number;
    unit: string;
  };
  pef: {
    value: number;
    unit: string;
  };
  observation?: ObservationSnapshotValue;
}

/**
 * OBSERVATIONS UPPER, LOWER BOUNDS AND PRECISION
 */
export const OBSERVATIONS_BOUNDS = {
  BloodPressureSystolic: {
    lowerBound: 0,
    upperBound: 400,
  },
  BloodPressureDiastolic: {
    lowerBound: 0,
    upperBound: 375,
  },
  SpirometryFev: {
    lowerBound: 0,
    upperBound: 100,
    precision: 2,
  },
  SpirometryPef: {
    lowerBound: 0,
    upperBound: 1000,
  },
  [ObservationType.GlucoseLevel]: {
    lowerBound: 0,
    upperBound: 2500,
  },
  [ObservationType.HeartRate]: {
    lowerBound: 0,
    upperBound: 350,
  },
  [ObservationType.PulseOximetry]: {
    lowerBound: 0,
    upperBound: 100,
  },
  [ObservationType.Temperature]: {
    lowerBound: 0,
    upperBound: 115,
    precision: 1,
  },
  [ObservationType.Weight]: {
    lowerBound: 0,
    upperBound: 800,
    precision: 1,
  },
};
