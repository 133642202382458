import React from "react";
import { connect } from "react-redux";

import { PracticeForm } from "@/domain/practice/view/PracticeForm";
import { Practice } from "@/domain/practice/model/types";
import { PracticeModel } from "@/domain/practice/model";
import {
  dispatchDeselectAllPractices,
  dispatchSelectAllPractices,
  dispatchSetPracticesPaginationConfig,
  dispatchSelectPractice,
  dispatchDeselectPractice,
  dispatchSetPracticeSortedState,
} from "@/domain/practice/redux/actions";
import {
  SetPracticeDispatch,
  PracticeSortedState,
} from "@/domain/practice/redux/types";
import {
  deselectOrganization,
  dispatchSelectOrganization,
  setOrganizations,
} from "@/domain/organization/redux/actions";
import {
  dispatchSelectAllPatients,
  dispatchSetAssignmentPatients,
  dispatchSetPatientsPaginationConfig,
} from "@/domain/patient/redux/actions";
import { OrganizationType, PaginationConfig } from "@/library/types";
import { OrganizationModel } from "@/domain/organization/model";
import { Organization } from "@/domain/organization/model/types";
import { MODEL_SYNCED } from "@/library/constants";
import { Modal } from "@/components/modal";
import { ProviderComponent } from "@/domain/provider/view";
import { ProviderModel, Provider } from "@/domain/provider/model";
import { OrganizationComponent } from "@/domain/organization/view/organization.component";
import { LocationComponent } from "@/domain/tags/view/Location";
import { ModelCollection } from "@/library/model";
import { RCAResourceActions } from "@/library/core/config/actions";
import {
  deselectAllProviders,
  deselectProvider,
  ProviderSortedState,
  selectAllProviders,
  selectProvider,
  SetProviderDispatch,
  setProviderSortedState,
  setProvidersPaginationConfig,
} from "@/domain/provider/redux";
import { AssignedBy } from "@/domain/patient/model/types";
import { PatientAssignment } from "@/domain/patient/view/form/assignment";
import { PatientModel } from "@/domain/patient/model";
import { Patient } from "@/domain/patient/model/types";
import { PracticeComponent } from "@/domain/practice/view/Practice";
import { setProvidersThunk } from "@/domain/provider/redux/provider.middleware";
import { setPatientsThunk } from "@/domain/patient/redux/middleware";
import { setPracticesThunk } from "@/domain/practice/redux/middleware";
import { OrganizationFilters } from "@/domain/organization/view/OrganizationFilters";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { RootState } from "@/types";
import { State, Props } from "./types";

import styles from "./styles.module.scss";
import { PatientsTable } from "@/domain/patient/view/PatientsPage/table/table.component";

class Component extends React.PureComponent<Props, State> {
  state: Readonly<State> = {
    allOrganizations: [],
    organizationId: "",
    practiceModalConfig: {
      show: false,
      title: "",
      modalButton: "",
      resourceAction: undefined,
    },
    organizationModalConfig: {
      show: false,
      title: "",
      modalButton: "",
      resourceAction: undefined,
    },
    careTeamModalConfig: {
      show: false,
      title: "",
      modalButton: "Close",
      assignedBy: "" as AssignedBy,
    },
    hidePractice: false,
    hideProvider: false,
    hidePatient: false,
    hideLocation: false,
    organizationDropdownOptions: [],
    selectedManageAssignment: {
      type: null,
      id: null,
    },
    providers: [],
    practices: [],
    globalFilters: {
      providerIds: undefined,
      practiceIds: undefined,
    },
    multiSelectConfig: {
      showLoadingIndicator: false,
    },
  };

  practiceRef: React.RefObject<HTMLInputElement> = React.createRef();
  providerRef: React.RefObject<HTMLInputElement> = React.createRef();
  patientRef: React.RefObject<HTMLInputElement> = React.createRef();
  headerRef: React.RefObject<HTMLInputElement> = React.createRef();

  async componentDidMount() {
    const {
      params: { organizationId },
    } = this.props.match;

    this.setState({ organizationId });
    const orgModel: OrganizationModel = await OrganizationModel.sync(
      organizationId
    );
    await this.selectOrganization(
      `${orgModel.attributes.get("name")},${orgModel.attributes.get("id")!}`
    );
  }

  async componentWillUnmount() {
    await this.props.deselectAllPractices();
    await this.props.deselectAllProviders();
  }

  syncTargetedOrganization = (orgModel: OrganizationModel): void => {
    // make selected organization the default
    this.props.selectOrganization(orgModel.pluckAll());
  };

  toggleHeader = (type: keyof State) => () => {
    this.setState({ [type]: !this.state[type] } as any);
  };

  clearGlobalFilters() {
    // deselect all practices and providers
    this.props.deselectAllProviders();
    this.props.deselectAllPractices();
    this.setState((previousState) => ({
      ...previousState,
      globalFilters: {
        practiceIds: undefined,
        providerIds: undefined,
      },
    }));
  }

  clearProviderFilters() {
    this.setState((previousState) => ({
      ...previousState,
      globalFilters: {
        ...previousState.globalFilters,
        providerIds: undefined,
      },
    }));
  }

  resetSortedState = async () => {
    await this.props.setPracticeSortedState({
      sortBy: undefined,
      direction: undefined,
      sortedColumnIndex: undefined,
    });
    await this.props.setProviderSortedState({
      sortBy: undefined,
      direction: undefined,
      sortedColumnIndex: undefined,
    });
  };

  selectOrganization = async (selectedOrganization: string) => {
    const organizationId = selectedOrganization.split(",")[1];
    const org: OrganizationModel = await OrganizationModel.sync(organizationId);
    await this.props.selectOrganization(org.pluckAll());
    this.setState({ organizationId });
    this.clearGlobalFilters();
    await this.resetSortedState();

    const {
      setPractices,
      setPracticesPaginationConfig,
      practicePageLimit,
      setProviders,
      setProvidersPaginationConfig,
      providerPageLimit,
      setPatients,
      setPatientsPaginationConfig,
      patientPageLimit,
    } = this.props;
    LoadingIndicator.fire.show();
    const practiceCollection: ModelCollection<PracticeModel, Practice> =
      PracticeModel.makePracticeCollection();
    practiceCollection.on(MODEL_SYNCED, () => {
      LoadingIndicator.fire.hide();
    });
    const paginationConfig: PaginationConfig = await practiceCollection.fetch({
      limit: practicePageLimit,
      organizationIds: [organizationId],
      includeProviderCount: true,
    });

    // set practices on redux state
    setPractices(practiceCollection.container);
    if (!practiceCollection.container.length) {
      setPracticesPaginationConfig({
        totalResources: 0,
        currentPage: 1,
        lastPage: 1,
      });
    } else {
      setPracticesPaginationConfig(paginationConfig);
    }

    // if I don't have any practice under the organization in scope, I can't have a provider nor a patient
    if (paginationConfig?.totalResources) {
      const providerCollection: ModelCollection<ProviderModel, Provider> =
        ProviderModel.makeProviderCollection();
      providerCollection.on(MODEL_SYNCED, () => {
        LoadingIndicator.fire.hide();
      });
      const providerPaginationConfig: PaginationConfig =
        await providerCollection.fetch({
          practiceIds: practiceCollection.container.map(
            (practiceModel) => practiceModel.pluck("_meta")?.id
          ),
          limit: providerPageLimit,
          includePatientCount: true,
        });

      setProviders(providerCollection.container);
      setProvidersPaginationConfig(providerPaginationConfig);

      const patientCollection: ModelCollection<PatientModel, Patient> =
        PatientModel.makePatientCollection();
      patientCollection.on(MODEL_SYNCED, () => {
        LoadingIndicator.fire.hide();
      });
      const patientPaginationConfig: PaginationConfig =
        await patientCollection.fetch({
          practiceIds: practiceCollection.container.map(
            (practiceModel) => practiceModel.pluck("_meta")?.id
          ),
          limit: patientPageLimit,
        });

      setPatients(patientCollection.container);
      setPatientsPaginationConfig(patientPaginationConfig);
    } else {
      setProviders([]);
      setProvidersPaginationConfig({
        totalResources: 0,
        currentPage: 1,
        lastPage: 1,
      });

      setPatients([]);
      setPatientsPaginationConfig({
        totalResources: 0,
        currentPage: 1,
        lastPage: 1,
      });
    }
    LoadingIndicator.fire.hide();
  };

  onPracticeFormOpen = () => {
    this.setState({
      practiceModalConfig: {
        show: true,
        title: "Create Entity/Branch",
        modalButton: "Create",
        resourceAction: RCAResourceActions.Create,
      },
    });
  };

  onEditOrganization = () => {
    this.setState({
      organizationModalConfig: {
        show: true,
        title: "Edit Organization",
        modalButton: "Save Changes",
        resourceAction: RCAResourceActions.ModifyBasicInfo,
      },
    });
  };

  onPracticeModalClose = () => {
    this.setState({
      practiceModalConfig: {
        show: false,
        title: "",
        modalButton: "",
        resourceAction: undefined,
      },
    });
  };

  onOrganizationModalClose = async (organizaationModel?: OrganizationModel) => {
    this.setState({
      organizationModalConfig: {
        show: false,
        title: "",
        modalButton: "",
        resourceAction: undefined,
      },
      allOrganizations: this.state.allOrganizations.map(
        (org?: Organization) => {
          if (org?.id === this.props.selectedOrganization?.id) {
            return this.props.selectedOrganization;
          }
          return org;
        }
      ),
    });
    if (organizaationModel && organizaationModel.attributes) {
      if (!organizaationModel.pluck("type")) {
        organizaationModel.setValues("type")(OrganizationType.Root);
      }
      this.syncTargetedOrganization(organizaationModel);
    }
  };

  onManageAssignmentClick = (
    title: string,
    type: string | null,
    id: string | null
  ) => {
    this.setState({
      selectedManageAssignment: {
        type: type,
        id: id,
      },
      careTeamModalConfig: {
        show: true,
        title: title,
        modalButton: "Close",
        assignedBy: AssignedBy.Patient,
      },
    });
  };

  onCareTeamModalClose = () => {
    this.setState({
      careTeamModalConfig: {
        show: false,
        title: "",
        modalButton: "",
        assignedBy: "" as AssignedBy,
      },
    });
  };

  handleSelectOrganization = async (orgId: string) => {
    window.history.replaceState("", "", `/organizations/${orgId}`);
    await this.setState({ organizationId: orgId });

    // Sync organization with organizationId
    const orgModel: OrganizationModel = await OrganizationModel.sync(orgId);

    // make selected organization the default
    this.selectOrganization(
      `${orgModel.pluck("name")},${orgModel.pluck("id")!}`
    );
  };

  handleSelectPractices = async (practiceIds: string[]) => {
    if (practiceIds.length) {
      if (practiceIds.length < this.props.selectedPractices.length) {
        const practiceToBeRemoved = this.props.selectedPractices.filter(
          (practice) => !practiceIds.includes(practice._meta?.id)
        );
        if (practiceToBeRemoved.length) {
          this.props.deselectPractice(practiceToBeRemoved[0]);
        }
      } else {
        const id = [...practiceIds].pop();
        const practiceModel = id ? await PracticeModel.sync(id!) : null;
        if (practiceModel) this.props.selectPractice(practiceModel.pluckAll());
      }
    } else {
      this.clearProviderFilters();
      this.props.deselectAllPractices();
    }
    this.setState((previousState) => ({
      ...previousState,
      globalFilters: {
        ...previousState.globalFilters,
        practiceIds: practiceIds,
      },
    }));
  };

  handleSelectProviders = async (providerIds: string[]) => {
    if (providerIds.length) {
      if (providerIds.length < this.props.selectedProviders.length) {
        const providerToBeRemoved = this.props.selectedProviders.filter(
          (provider) => !providerIds.includes(provider._meta?.id)
        );
        if (providerToBeRemoved.length) {
          this.props.deselectProvider(providerToBeRemoved[0]);
        }
      } else {
        const id = [...providerIds].pop();
        const providerModel = await ProviderModel.sync(id!);
        this.props.selectProvider(providerModel.pluckAll());
      }
    } else {
      this.props.deselectAllProviders();
    }
    this.setState((previousState) => ({
      ...previousState,
      globalFilters: {
        ...previousState.globalFilters,
        providerIds: providerIds,
      },
    }));
  };

  render() {
    let orgId;
    if (this.state.organizationId) {
      window.history.replaceState(
        null,
        `${this.props.selectedOrganization?.name}`,
        `/organizations/${this.state.organizationId}`
      );
      orgId = this.state.organizationId;
    }

    if (!orgId) {
      const {
        params: { organizationId },
      } = this.props.match;
      orgId = organizationId;
    }

    return (
      <div className={styles.organizationPage}>
        <OrganizationFilters
          headerTitle="Organization"
          organizationFilter={{
            enabled: true,
            selectedOrganization: this.props.selectedOrganization,
            onSelectOrganization: this.handleSelectOrganization,
          }}
          practiceFilter={{
            enabled: true,
            selectedPractice: this.state.globalFilters.practiceIds,
            onSelectPractice: this.handleSelectPractices,
          }}
          providerFilter={{
            enabled: true,
            selectedProviders: this.state.globalFilters.providerIds,
            onSelectProvider: this.handleSelectProviders,
          }}
        />

        <PracticeComponent
          displayName="Entity/Branch"
          hidePractice={this.state.hidePractice}
          togglePractice={this.toggleHeader("hidePractice")}
          onManageAssignmentClick={this.onManageAssignmentClick}
        />

        <LocationComponent
          collapsed={this.state.hideLocation}
          onToggle={this.toggleHeader("hideLocation")}
        />

        <ProviderComponent
          displayName="Providers"
          hideProvider={this.state.hideProvider}
          toggleProvider={this.toggleHeader("hideProvider")}
          onManageAssignmentClick={this.onManageAssignmentClick}
        />

        <PatientsTable
          tableType="admin"
          displayName="Patients"
          hidePatient={this.state.hidePatient}
          togglePatient={this.toggleHeader("hidePatient")}
          collapsible={true}
        />

        <Modal
          handleClose={this.onPracticeModalClose}
          show={this.state.practiceModalConfig.show}
          heading={this.state.practiceModalConfig.title}
          button={this.state.practiceModalConfig.modalButton}
        >
          {this.state.practiceModalConfig.show && (
            <PracticeForm
              button={this.state.practiceModalConfig.modalButton}
              handleClose={this.onPracticeModalClose}
              resourceAction={this.state.practiceModalConfig.resourceAction}
            />
          )}
        </Modal>

        <Modal
          handleClose={this.onOrganizationModalClose}
          show={this.state.organizationModalConfig.show}
          heading={this.state.organizationModalConfig.title}
          button={this.state.organizationModalConfig.modalButton}
        >
          {this.state.organizationModalConfig.show && (
            <OrganizationComponent
              displayName={this.state.organizationModalConfig.title}
            />
          )}
        </Modal>
        <Modal
          handleClose={this.onCareTeamModalClose}
          show={this.state.careTeamModalConfig.show}
          heading={"Assign Patients"}
          button={this.state.careTeamModalConfig.modalButton}
        >
          {this.state.careTeamModalConfig.show && <PatientAssignment />}
        </Modal>
      </div>
    );
  }
}

const mapDispatcherToProps = (dispatch: any) => {
  return {
    setOrganizations: (organizations: Organization[]): any =>
      dispatch(setOrganizations(organizations)),
    setPractices: (
      practices: PracticeModel[]
    ): ((dispatch: SetPracticeDispatch) => Promise<void>) =>
      dispatch(setPracticesThunk(practices)),
    setProviders: (
      providerModels: ProviderModel[]
    ): ((dispatch: SetProviderDispatch) => Promise<void>) =>
      dispatch(setProvidersThunk(providerModels)),
    setProvidersPaginationConfig: (
      paginationConfig: PaginationConfig
    ): { type: string; payload: PaginationConfig } =>
      dispatch(setProvidersPaginationConfig(paginationConfig)),
    setAssignmentPatients: (
      patients: Patient[]
    ): { type: string; payload: Patient[] } =>
      dispatch(dispatchSetAssignmentPatients(patients)),
    setPracticesPaginationConfig: (
      paginationConfig: PaginationConfig
    ): { type: string; payload: PaginationConfig } =>
      dispatch(dispatchSetPracticesPaginationConfig(paginationConfig)),

    selectOrganization: (organization: Organization): any =>
      dispatch(dispatchSelectOrganization(organization)),
    deselectOrganization: (): any => dispatch(deselectOrganization()),

    setPatients: (patientCollection: PatientModel[]) =>
      dispatch(setPatientsThunk(patientCollection)),

    setPatientsPaginationConfig: (
      paginationConfig: PaginationConfig
    ): { type: string; payload: PaginationConfig } =>
      dispatch(dispatchSetPatientsPaginationConfig(paginationConfig)),

    deselectAllPractices: () => dispatch(dispatchDeselectAllPractices()),
    deselectAllProviders: () => dispatch(deselectAllProviders()),
    selectAllPractices: () => dispatch(dispatchSelectAllPractices()),
    selectAllProviders: () => dispatch(selectAllProviders()),
    selectAllPatients: () => dispatch(dispatchSelectAllPatients()),
    selectPractice: (practice: Practice) =>
      dispatch(dispatchSelectPractice(practice)),
    selectProvider: (provider: Provider) => dispatch(selectProvider(provider)),
    deselectPractice: (practice: Practice) =>
      dispatch(dispatchDeselectPractice(practice)),
    deselectProvider: (provider: Provider) =>
      dispatch(deselectProvider(provider)),
    setPracticeSortedState: (practiceSortedState: PracticeSortedState) =>
      dispatch(dispatchSetPracticeSortedState(practiceSortedState)),
    setProviderSortedState: (providerSortedState: ProviderSortedState) =>
      dispatch(setProviderSortedState(providerSortedState)),
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    practices: state.practice.practices,
    selectedPractices: state.practice.selectedPractices,
    selectedProviders: state.provider.selectedProviders,
    practicePageLimit: state.practice.practicePageLimit,
    providerPageLimit: state.provider.providerPageLimit,
    patientPageLimit: state.patient.patientPageLimit,
    organizations: state.organization.organizations,
    selectedOrganization: state.organization.selectedOrganization,
  };
};

export const OrganizationPage = connect(
  mapStateToProps,
  mapDispatcherToProps
)(Component);
