export type CountdownProps = {
  /**
   * Should timer run
   */
  running: boolean;
  /**
   * Initial countdown time
   */
  offsetTimestamp: Date;
  /**
   * State of the timer
   */
  timerActivityState: TimerActivityState;
  /**
   * Default background color of timer
   */
  timerColor: TimerColors;
  /**
   * Event fire on timer click
   */
  onClick: (timerState: TimerState) => void;
  /**
   * Fire when timer goes inactive yellow
   */
  onIdle: (timerState: TimerState) => void;
  /**
   * Fire when timer stopped due to inactivity red
   */
  onInactiveStopped: (timerState: TimerState) => void;
  /**
   * Keep emitting the latest state of timer
   */
  connectToTimerState: (timerState: TimerState) => void;
  /**
   * Fires when user State change to active
   */
  onActiveUser: (timerState: TimerState) => void;
};

export interface TimerState {
  seconds: number;
  minutes: number;
  hours: number;
  isRunning: boolean;
}

export enum TimerColors {
  Blue = "#4472c4",
  Red = "#ed3833",
  Yellow = "#fdf851",
  Gray = "#8c8989",
}

export enum TimerTextColor {
  Black = "black",
  White = "white",
}

export enum TimerActivityState {
  INITIAL = "INITIAL",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PAUSED = "PAUSED",
}

export enum TimerUserState {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}
