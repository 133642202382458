import { Patient } from "@/domain/patient/model/types";
import React, { useEffect, useState } from "react";
import RSModal from "rsuite/Modal";
import { Button, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ViewReminders } from "@/domain/patient/view/BannerMenu/Reminders/viewRemindersModal";
import {
  Automation,
  AutomationActionType,
} from "@/domain/automation/model/types";
import { DateTime } from "luxon";

interface Props {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  patient: Patient;
  selectedPatient: Patient[];
  updatePatientDetailState: (patient: Partial<Patient>) => void;
}

export const ManageReminders: React.FC<Props> = ({
  open,
  onClose,
  patient,
  updatePatientDetailState,
}) => {
  const [addReminder, setAddReminder] = useState(false);
  const [automations, setAutomations] = useState<Array<Automation>>([]);
  const deleteReminderFunction = () => {
    setAddReminder(false);
  };

  useEffect(() => {
    if (patient) {
      const reminders: Automation[] | undefined = patient.automations?.filter(
        (a) => {
          return (
            (a.actions[0].type === AutomationActionType.Remind ||
              a.actions[0].type === AutomationActionType.RequestVirtualVisit) &&
            (a.triggers[0].type === "SCHEDULE" ||
              (a.triggers[0].type === "DATE_TIME" &&
                !(DateTime.now() > DateTime.fromISO(a.triggers[0].when))))
          );
        }
      );
      if (reminders?.length) setAutomations(reminders);
    }
  }, [patient]);

  return (
    <RSModal
      style={{ minWidth: 1800 }}
      backdrop
      overflow={true}
      open={open}
      onClose={() => {
        setAddReminder(false);
        onClose();
      }}
    >
      <RSModal.Header closeButton>
        <Typography variant="h6">Manage Reminders</Typography>
      </RSModal.Header>
      <RSModal.Body>
        <Button
          onClick={() => setAddReminder(!addReminder)}
          startIcon={<AddIcon />}
          sx={{ mt: 2, mb: 3, ml: 198 }}
        >
          ADD REMINDER
        </Button>
        <Grid container rowSpacing={1} columnSpacing={3}>
          {automations.length &&
            automations.map((automation: Automation) => {
              return (
                <Grid key={automation.id} item xs={4}>
                  <ViewReminders
                    patient={patient}
                    reminderAutomation={automation}
                    deleteReminderFunction={deleteReminderFunction}
                    updatePatientDetailState={updatePatientDetailState}
                  />
                </Grid>
              );
            })}
          {addReminder && (
            <Grid item xs={4}>
              <ViewReminders
                patient={patient}
                deleteReminderFunction={deleteReminderFunction}
                updatePatientDetailState={updatePatientDetailState}
              />
            </Grid>
          )}
        </Grid>
      </RSModal.Body>
    </RSModal>
  );
};
export default ManageReminders;
