import { DateTime } from "luxon";
import { ObservationType } from "../../../../observations/types";
export const DAYS_LIMIT = 14;

export enum SummaryDateRange {
  Last14Days = 14,
  Last7Days = 7,
  Last2Days = 2,
  PreviousMonth = "P",
  CurrentMonth = "C",
  Custom = 0,
}

export enum SummaryNoteOptions {
  All = "all",
  Last2Days = "2",
  NoCharts = "NO_CHARTS",
}

export const getCurrentDate = (() => {
  const today = new Date();
  return DateTime.fromJSDate(today).endOf("day").toJSDate();
})();

export const getStartDate = () => {
  const dateTime = DateTime.fromJSDate(getCurrentDate)
    .minus({ day: DAYS_LIMIT })
    .plus({ minute: 1 })
    .startOf("day");
  const startDate = dateTime.toJSDate();
  return startDate;
};

/**
 * Age pipe is supporting to the PDF file
 * Let's use custom function to display the patient age
 * @param dob
 * @returns
 */
export const calculateAge = (dob: string): number => {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  if (!age) {
    console.error("Invalid date of birth.");
  }
  return age;
};

export const GraphElementIds: Record<
  ObservationType,
  | string
  | {
      pef: string;
      fev1: string;
    }
> = {
  [ObservationType.HeartRate]: "heart-rate-chart",
  [ObservationType.BloodPressure]: "blood-pressure-chart",
  [ObservationType.PulseOximetry]: "pulse-oximetry-chart",
  [ObservationType.Weight]: "weight-chart",
  [ObservationType.GlucoseLevel]: "glucose-level-chart",
  [ObservationType.Temperature]: "temperature-chart",
  [ObservationType.Spirometry]: {
    pef: "spirometry-chart-pef",
    fev1: "spirometry-chart-fev",
  },
};

export const graphHeadings: string[] = [
  "Heart Rate",
  "Blood Pressure",
  "Pulse Oximetry",
  "Weight",
  "Glucose Level",
  "Temperature",
  "Spirometry",
  "Spirometry",
];

export enum BiometricType {
  HeartRate = "Heart Rate",
  BloodPressure = "Blood Pressure",
  PulseOximetry = "Pulse Oximetry",
  Weight = "Weight",
  GlucoseLevel = "Glucose Level",
  Temperature = "Temperature",
  Spirometry = "Spirometry",
}
