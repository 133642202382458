import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { keyBy } from "lodash";

import { ReportTypes } from "@/pages/reports/model/reports";
import { Patient } from "@/domain/patient/model/types";
import { User } from "@/domain/user/model";
import { Practice } from "@/domain/practice/model/types";
import { SortedState, SortedStateType } from "@/components/table/head/sortable";
import TableWithPagination from "@/components/table/datatable/tableWithPagination.component";
import {
  sortAscendingWithoutJSX,
  sortDescendingWithoutJSX,
} from "@/components/table/datatable/datatableConfig";
import { selectedReportType } from "./reports.utils";
import { BasicMultiSelect } from "@/components/form/select/multiSelect";
import { Provider } from "@/domain/provider/model";
import { Program } from "@/domain/program/model/types";
import { Tag } from "@/domain/tags/model/types";
import styles from "@/domain/patient/view/filters/styles.module.scss";
import {
  PatientProgramState,
  StateDisplayName,
} from "@/domain/patient/view/form/manageProgram/types";
import { rpmComprehensive } from "@/pages/reports/view/table/rpm-comprehensive-utils";

interface Props {
  currentUser: User;
  locations: Tag[];
  patients: Patient[];
  practices: Practice[];
  programs: Program[];
  providers: Provider[];
  reportType: ReportTypes;
  reportMonthYear: string;
  selectedPractice: Practice | undefined;
  setFilteredPatients: Function;
}

function ReportTable(props: Props) {
  /* Mapping table headers */
  const headContents: string[] =
    props.reportType === ReportTypes.rpmComprehensive
      ? rpmComprehensive.header || []
      : selectedReportType[props.reportType]?.header || [];
  const [header, setHeader] = useState(
    headContents.map((content, key) => {
      return {
        name: content.toUpperCase(),
        isSortable: true,
        sortedState: SortedState.NoSort,
      };
    })
  );
  const [patientRecords, setPatientRecords] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState(props.patients);
  const [selectedStatuses, setSelectedStatuses] = useState([] as string[]);
  const [selectedPractices, setSelectedPractices] = useState([] as string[]);
  const [selectedProviders, setSelectedProviders] = useState([] as string[]);
  const [selectedPrograms, setSelectedPrograms] = useState([] as string[]);
  const [selectedLocations, setSelectedLocations] = useState([] as string[]);
  const updateHeaderContents = (): void => {
    const header =
      props.reportType === ReportTypes.rpmComprehensive
        ? rpmComprehensive.header || []
        : selectedReportType[props.reportType]?.header || [];
    setHeader(
      header.map((content: string, key: number) => {
        return {
          name: content.toUpperCase(),
          isSortable: true,
          sortedState: SortedState.NoSort,
        };
      })
    );
  };
  const filterPatients = async (
    patientStatuses: string[],
    practiceIds: string[],
    providerIds: string[],
    programIds: string[],
    locationIds: string[]
  ) => {
    let filteredPatients: Patient[] = props.patients;
    if (patientStatuses.length) {
      filteredPatients = props.patients.filter((patient: Patient) => {
        return patientStatuses.includes(patient.lifecycle.state);
      });
    }
    if (practiceIds.length) {
      filteredPatients = props.patients.filter((patient: Patient) => {
        return practiceIds.includes(patient.practiceId);
      });
    }
    if (providerIds.length) {
      filteredPatients = filteredPatients.filter((patient: Patient) => {
        return providerIds.includes(patient.providerId);
      });
    }
    if (programIds.length) {
      filteredPatients = filteredPatients.filter((patient: Patient) => {
        if (patient.programId) {
          return programIds.includes(patient.programId);
        }
        return false;
      });
    }
    if (locationIds.length) {
      filteredPatients = filteredPatients.filter((patient: Patient) => {
        const tag = patient.tags?.find((i) => i.type === "location");
        return tag && locationIds.includes(tag._meta.id);
      });
    }
    setFilteredPatients(filteredPatients);
  };
  const patientStatuses = [
    {
      label: "None",
      value: null,
    },
    ...Object.keys(PatientProgramState).map((status) => {
      return {
        label: StateDisplayName[status],
        value: status,
      };
    }),
  ];
  const subHeaders = headContents.map((header, key) => {
    if (header === "Patient Status") {
      return (
        <td key={key}>
          <div>
            <BasicMultiSelect
              data={patientStatuses}
              onChange={async (value: string[]) => {
                await setSelectedStatuses(value);
                await filterPatients(
                  value,
                  selectedPractices,
                  selectedProviders,
                  selectedPrograms,
                  selectedLocations
                );
              }}
              value={selectedStatuses}
              isSearchable={true}
              placeholder="Select"
              onClear={() => {
                setSelectedStatuses([]);
                updateHeaderContents();
              }}
              className={styles.select}
              picker="check"
            />
          </div>
        </td>
      );
    }
    if (header === "Practice") {
      return (
        <td key={key}>
          <div>
            <BasicMultiSelect
              data={props.practices.map((practice: Practice) => ({
                value: practice._meta?.id,
                label: practice.name,
              }))}
              onChange={async (value: string[]) => {
                await setSelectedPractices(value);
                await filterPatients(
                  selectedStatuses,
                  value,
                  selectedProviders,
                  selectedPrograms,
                  selectedLocations
                );
              }}
              findValueBy={selectedPractices}
              value={selectedPractices}
              isSearchable={true}
              sortable={true}
              placeholder="Select"
              onClear={() => {
                setSelectedPractices([]);
                updateHeaderContents();
              }}
              style={{ width: 200 }}
              picker="check"
            />
          </div>
        </td>
      );
    }
    if (header === "Provider") {
      return (
        <td key={key}>
          <div>
            <BasicMultiSelect
              data={props.providers.map((provider: Provider) => ({
                value: provider._meta?.id,
                label: `${provider.firstName} ${provider.lastName}, ${provider.credentials}`,
              }))}
              onChange={async (value: string[]) => {
                setSelectedProviders(value);
                await filterPatients(
                  selectedStatuses,
                  selectedPractices,
                  value,
                  selectedPrograms,
                  selectedLocations
                );
              }}
              findValueBy={selectedProviders}
              value={selectedProviders}
              isSearchable={true}
              sortable={true}
              placeholder="Select"
              onClear={() => {
                setSelectedProviders([]);
                updateHeaderContents();
              }}
              picker="check"
              style={{ width: 200 }}
            />
          </div>
        </td>
      );
    }
    if (header === "Program") {
      return (
        <td key={key}>
          <div>
            <BasicMultiSelect
              data={props.programs.map((program: Program) => ({
                value: program._meta?.id,
                label: program.shortName,
              }))}
              onChange={async (value: string[]) => {
                await setSelectedPrograms(value);
                await filterPatients(
                  selectedStatuses,
                  selectedPractices,
                  selectedProviders,
                  value,
                  selectedLocations
                );
              }}
              findValueBy={selectedPrograms}
              value={selectedPrograms}
              isSearchable={true}
              sortable={true}
              placeholder="Select"
              onClear={() => {
                setSelectedPrograms([]);
                updateHeaderContents();
              }}
              className={styles.select}
              picker="check"
            />
          </div>
        </td>
      );
    }
    if (header === "Location") {
      return (
        <td key={key}>
          <div>
            <BasicMultiSelect
              data={props.locations.map((l: Tag) => ({
                value: l._meta?.id,
                label: l.value,
              }))}
              onChange={async (value: string[]) => {
                await setSelectedLocations(value);
                await filterPatients(
                  selectedStatuses,
                  selectedPractices,
                  selectedProviders,
                  selectedPrograms,
                  value
                );
              }}
              findValueBy={selectedLocations}
              value={selectedLocations}
              isSearchable={true}
              sortable={true}
              placeholder="Select"
              onClear={() => {
                setSelectedLocations([]);
                updateHeaderContents();
              }}
              style={{ width: 200 }}
              picker="check"
            />
          </div>
        </td>
      );
    }
    return <td key={key}></td>;
  });
  const mergedHeaders = headContents.map((header, key) => {
    if (header === "Start") {
      return (
        <th
          key={key}
          className="bg-primary text-white th-text text-center"
          colSpan={5}
        >
          CPT99454
        </th>
      );
    }
    if (header === "Contact") {
      return (
        <th
          key={key}
          className="bg-primary text-white th-text text-center"
          colSpan={3}
        >
          CPT99457
        </th>
      );
    }
    if (header === "(1)") {
      return (
        <th
          key={key}
          className="bg-primary text-white th-text text-center"
          colSpan={4}
        >
          CPT99458
        </th>
      );
    }
    return <th></th>;
  });
  useEffect(() => {
    updateHeaderContents();
    updatePatientRecords(getPatientRecords());
  }, []);
  useEffect(() => {
    updateHeaderContents();
    updatePatientRecords(getPatientRecords());
  }, [props.reportType]);

  useEffect(() => {
    if (props.patients) setFilteredPatients(props.patients);
    updatePatientRecords(getPatientRecords());
    resetHeaderState();
  }, [props.patients]);

  useEffect(() => {
    const updatedRecords =
      props.reportType === ReportTypes.rpmComprehensive
        ? rpmComprehensive.data(filteredPatients) || []
        : selectedReportType[props.reportType]?.data(filteredPatients) || [];
    updatePatientRecords(updatedRecords);
    props.setFilteredPatients(filteredPatients);
  }, [filteredPatients]);

  const updatePatientRecords = (records: (string | number | undefined)[][]) => {
    setPatientRecords(records as []);
  };

  const getPatientRecords = () => {
    return props.reportType === ReportTypes.rpmComprehensive
      ? rpmComprehensive.data(filteredPatients) || []
      : selectedReportType[props.reportType]?.data(props.patients) || [];
  };

  const getFilteredPatientRecords = () => {
    return props.reportType === ReportTypes.rpmComprehensive
      ? rpmComprehensive.data(filteredPatients) || []
      : selectedReportType[props.reportType]?.data(filteredPatients) || [];
  };

  const onSortData = (index: number, state: SortedStateType) => {
    let sortedRecords;
    switch (state) {
      case SortedState.Ascending:
        sortedRecords = sortAscendingWithoutJSX(patientRecords, index);
        updatePatientRecords(sortedRecords);
        break;
      case SortedState.Descending:
        sortedRecords = sortDescendingWithoutJSX(patientRecords, index);
        updatePatientRecords(sortedRecords);
        break;
      case SortedState.NoSort:
        sortedRecords = getFilteredPatientRecords();
        updatePatientRecords(sortedRecords);
        break;
      default:
        sortedRecords = getFilteredPatientRecords();
        updatePatientRecords(sortedRecords);
    }
    /*
      Below code creates a look up hash of patients.
      Since patientRecords variable is a 2 dimensional array, look up is needed to consutruct an object needed for export
    */
    const patientLookup = keyBy(props.patients, (patient) => {
      return patient._meta?.id;
    });
    const patients = sortedRecords.map((patient: Array<any>) => {
      return patientLookup[patient[0]];
    });
    props.setFilteredPatients(patients);
    resetHeaderState();
  };

  const resetHeaderState = () => {
    setHeader(
      header.map((head) => {
        head.sortedState = SortedState.NoSort;
        return head;
      }) as []
    );
  };
  const paginationConfig = {
    totalResources: 100,
    currentPage: 1,
    lastPage: 2,
  };
  return (
    <div className="card-body">
      <div className="choose-organization" id="rpm">
        <div className="mt-1 table-responsive">
          <TableWithPagination
            id="rpmTable"
            config={{
              recordsPerPage: 50,
              isSortable: true,
              onSort: onSortData,
            }}
            header={header}
            subHeaders={subHeaders}
            mergedHeaders={mergedHeaders}
            records={patientRecords.map((patient: Array<any>) => {
              const arr = [...patient];
              arr.splice(0, 1);
              return arr;
            })}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: { user: { currentUser: User } }) => {
  return {
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps)(ReportTable);
