import { useState, MouseEvent, KeyboardEvent } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Placeholder } from "rsuite";
import cx from "clsx";

import { Patient } from "@/domain/patient/model/types";
import { PatientPriority } from "@/domain/patient/model/types";
import { PatientTab } from "@/domain/patient/view/PatientDetails/types";
import { dispatchSelectPatient } from "@/domain/patient/redux/actions";
import Pagination from "@/components/pagination";
import { RootState } from "@/types";
import { bannerBorderColor } from "./constants";
import { PatientBannerDetails } from "../PatientBannerDetails";
import { PatientObservations } from "../PatientObservations";
import PatientTimer from "../PatientTimer";

import globalStyles from "@/styles/globals.module.scss";
import styles from "./styles.module.scss";
import { arrClone } from "@/util/clone";
import PatientBannerRow from "../PatientBannerRow";
import { selectPatientListPaginationConfig } from "@/domain/patient/redux/selectors";
import { PatientQuery } from "@/library/types";

export interface Props {
  patients: Patient[];
  onEditPatient?: () => void;
  includeTimer?: boolean;
  stopRedirection?: boolean;
  commentFieldSize?: number;
  showPlaceholder?: boolean;
  onVirtualVisit: (patient?: Patient) => void;
  execSearch?: (query: PatientQuery) => Promise<void>;
}

const recordsOnPage = 50;

export const PatientBanners: React.FC<Props> = (props) => {
  const history = useHistory();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const [recordsPerPage, setRecordsPerPage] = useState(recordsOnPage);
  const [currentPage, setCurrentPage] = useState(1);
  const paginationConfig = useSelector(selectPatientListPaginationConfig);
  const dispatch = useDispatch();
  const setSelectedPatient = (patient: Patient) =>
    dispatch(dispatchSelectPatient(patient));
  const [selectedRow, setSelectedRow] = useState(null);

  const paginate = (pageNumber: number) => {
    if (props.execSearch) {
      props.execSearch({ currentPage: pageNumber, limit: recordsPerPage });
    } else {
      document.getElementById("patient-list")?.scrollIntoView();
      setCurrentPage(pageNumber);
    }
  };

  const patientList: Patient[] = arrClone(props.patients) as Patient[];

  // Get current records
  const indexOfLastRecord: number = currentPage * recordsPerPage;
  const indexOfFirstRecord: number = indexOfLastRecord - recordsPerPage;
  let currentRecords = patientList.slice(indexOfFirstRecord, indexOfLastRecord);

  const handleRecordPerPage = (perPage: number) => {
    if (props.execSearch) {
      props.execSearch({ currentPage: 1, limit: perPage });
    }
    setRecordsPerPage(perPage);
    resetPaginationConfig();
  };

  const resetPaginationConfig = () => {
    setCurrentPage(1);
  };

  const renderPlaceholder = () => (
    <>
      {Array(10)
        .fill(
          <Placeholder.Graph
            active
            className={cx("border", styles.placeholder)}
          />
        )
        .map((placeholder, index) => (
          <div key={`placeholder-${index}`}>{placeholder}</div>
        ))}
    </>
  );

  const handleNavigate =
    (patient: Patient, tab: "profile" | "monitoring") =>
    (event: MouseEvent | KeyboardEvent) => {
      event.stopPropagation();

      setSelectedPatient(patient);

      if (tab === "monitoring")
        history.push(`/patient/${patient.id}/${PatientTab.Monitoring}`);
      else history.push(`/patient/${patient.id}/${PatientTab.Profile}`);
    };

  const isPatientBannerRevamp = _env_.ENABLE_PATIENT_BANNER_REVAMP === "true";
  return (
    <>
      {props.showPlaceholder &&
        props.patients.length === 0 &&
        renderPlaceholder()}
      {isPatientBannerRevamp ? (
        <ul className="mt-2">
          {patientList &&
            currentRecords?.map((patient) => (
              <PatientBannerRow
                key={patient.id}
                patient={patient}
                onVirtualVisit={() => props.onVirtualVisit(patient)}
                gotoProfile={() => {
                  setSelectedPatient(patient);
                  history.push(`/patient/${patient.id}/${PatientTab.Profile}`);
                }}
                includeTimer={props.includeTimer}
                gotoMonitoring={() => {
                  setSelectedPatient(patient);
                  history.push(
                    `/patient/${patient.id}/${PatientTab.Monitoring}`
                  );
                }}
              />
            ))}
        </ul>
      ) : (
        patientList &&
        currentRecords.map((patient) => (
          <div
            key={`patientCard-${patient.id}`}
            className={cx(
              styles.patientBanner,
              props.includeTimer && styles.hasTimer
            )}
            style={{
              borderColor: bannerBorderColor(
                patient.priority as PatientPriority
              ),
            }}
          >
            <button
              type="button"
              className={cx(globalStyles.buttonReset, styles.cardLeft)}
              onClick={handleNavigate(patient, "profile")}
            >
              <PatientBannerDetails
                commentFieldSize={props.commentFieldSize}
                includeTimer={props.includeTimer}
                patient={patient}
                timer={
                  <PatientTimer
                    userPatientId={`${currentUser?.id}${patient.id!}`}
                    patient={patient}
                  />
                }
              />
            </button>
            <div className={styles.cardRight}>
              <PatientObservations
                onVirtualVisit={() => props.onVirtualVisit(patient)}
                includeTimerOptions={props.includeTimer}
                patient={patient}
                onClick={handleNavigate(patient, "monitoring")}
              />
            </div>
          </div>
        ))
      )}
      {!props.includeTimer && (
        <Pagination
          recordsPerPage={recordsPerPage}
          totalRecords={paginationConfig.totalResources}
          paginate={paginate}
          currentPage={paginationConfig.currentPage || currentPage}
          setRecordPerPage={handleRecordPerPage}
        />
      )}
    </>
  );
};
