import {
  ConcentrationUnit,
  PressureUnit,
  VolumeUnit,
  FlowUnit,
  ObservationType,
  ObservationOverviewSnapshot,
} from "@/domain/observations/types";
import { ObservationReason } from "@/library/types/note";

export const ConcentrationUnits = {
  mmoll: ConcentrationUnit.mmoll,
};

export const PressureUnits = {
  mmhg: PressureUnit.mmhg,
  kpa: PressureUnit.kpa,
};

export const VolumeUnits = {
  Liters: "L" as VolumeUnit,
};

export const FlowUnits = {
  LitersPerMinute: "L/min" as FlowUnit,
};

export enum ObservationUnit {
  KiloPascal = "kPa",
  Celsius = "C",
  Kilograms = "kg",
  Fahrenheit = "F",
  Pounds = "lbs",
  MilligramPerDeciliter = "mg/dL",
  OxygenSaturation = "SpO2",
  BeatPerMinute = "bpm",
  Liter = "L",
}

export const ObservationUnits = {
  KiloPascal: ObservationUnit.KiloPascal as ObservationUnit,
  Celsius: ObservationUnit.Celsius as ObservationUnit,
  Kilograms: ObservationUnit.Kilograms as ObservationUnit,
};

export const ObservationTypes = {
  BloodPressure: ObservationType.BloodPressure as ObservationType,
  GlucoseLevel: ObservationType.GlucoseLevel as ObservationType,
  HeartRate: ObservationType.HeartRate as ObservationType,
  PulseOximetry: ObservationType.PulseOximetry as ObservationType,
  Spirometry: ObservationType.Spirometry as ObservationType,
  Temperature: ObservationType.Temperature as ObservationType,
  Weight: ObservationType.Weight as ObservationType,
};

export const ObservationReasons = {
  InRange: "IN_RANGE" as ObservationReason,
  Manual: "MANUAL" as ObservationReason,
  Missed: "MISSED" as ObservationReason,
  OutOfRange: "OUT_OF_RANGE" as ObservationReason,
};

export const PatientAdminTableHeader: string[] = [
  "last name",
  "first name",
  "mrn",
  "primary dx",
  "entity / branch",
  "provider",
  "care team member",
  "action",
];
export const PatientClinicalTableHeader: (string | string[])[] = [
  "status",
  "last name",
  "first name",
  "dob (age)",
  "gender",
  "patient id",
  "program",
  "risk level",
  ["wgt", "lbs", "observation"],
  ["bp", "sbp/dbp", "observation"],
  ["hr", "bpm", "observation"],
  ["o2 sat", "spo2", "observation"],
  ["gluc", "mg/dl", "observation"],
  ["temp", "f", "observation"],
];

export const PatientAssignmentTableHeader: string[] = [
  "last name",
  "first name",
  "primary dx",
  "entity / branch",
  "provider",
  "care team",
];

export const ObservationTitles: Record<ObservationType, string> = {
  [ObservationType.BloodPressure]: "Blood Pressure",
  [ObservationType.GlucoseLevel]: "Glucose Level",
  [ObservationType.HeartRate]: "Heart Rate",
  [ObservationType.PulseOximetry]: "Pulse Oximetry",
  [ObservationType.Spirometry]: "Spirometry",
  [ObservationType.Temperature]: "Temperature",
  [ObservationType.Weight]: "Weight",
};

export const ObservationMeasurementUnits: Record<ObservationType, string> = {
  [ObservationType.BloodPressure]: "mmHg",
  [ObservationType.GlucoseLevel]: "mg/dL",
  [ObservationType.HeartRate]: "bpm",
  [ObservationType.PulseOximetry]: "% spo2",
  [ObservationType.Spirometry]: "pef/fev1",
  [ObservationType.Temperature]: "°F",
  [ObservationType.Weight]: "lb",
};

export const ObservationToKeyofOverviewSnapshot: Record<
  ObservationType,
  keyof ObservationOverviewSnapshot
> = {
  [ObservationType.BloodPressure]: "bloodPressure",
  [ObservationType.GlucoseLevel]: "glucose",
  [ObservationType.HeartRate]: "heartRate",
  [ObservationType.PulseOximetry]: "pulseOximetry",
  [ObservationType.Spirometry]: "spirometry",
  [ObservationType.Temperature]: "temperature",
  [ObservationType.Weight]: "weight",
};
