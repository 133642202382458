import React, { useMemo, useState } from "react";
import { TooltipFormatterContextObject } from "highcharts";

import LineChart from "@/components/charts/HighChart.component";
import {
  COLORS,
  makeChartOptions,
} from "@/domain/patient/view/charts/constants";
import { parseTooltipDate } from "@/domain/patient/view/tabs/monitoring/helpers";
import {
  getAlertLevelColor,
  getSeverityAlertLevel,
} from "@/domain/notes/model/constants";
import { Props } from "./types";
import {
  selectMonitoringStartDate,
  selectMonitoringEndDate,
} from "@/domain/patient/redux/selectors";
import { useSelector } from "react-redux";
import {
  ObservationsUnitAbbrevation,
  ObservationType,
} from "@/domain/observations/types";
import { displayObsUnit } from "@/domain/observations/helpers";

export const GlucometryChart: React.FC<Props> = (props) => {
  const { glucoseLevels, patientDetail } = props;
  const isoStartDate = useSelector(selectMonitoringStartDate);
  const isoEndDate = useSelector(selectMonitoringEndDate);
  const [isPrinting, setIsPrinting] = useState(false);

  const chartConfig = useMemo(() => {
    return makeChartOptions({
      yTitle: displayObsUnit(ObservationType.GlucoseLevel) as string,
      events: {
        beforePrint: async () => {
          setIsPrinting(true);
        },
        afterPrint: () => {
          setIsPrinting(false);
        },
      },
      tooltip: {
        useHTML: true,
        valueSuffix: ObservationsUnitAbbrevation.GLUCOSE_LEVEL,
        formatter: function (this: TooltipFormatterContextObject) {
          return parseTooltipDate(
            ObservationType.GlucoseLevel,
            this,
            ObservationsUnitAbbrevation.GLUCOSE_LEVEL
          );
        },
      },
      xAxis: {
        min: isoStartDate,
        max: isoEndDate,
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: false,
          },
          enableMouseTracking: true,
        },
      },
      series: [
        {
          name: "Glucose Level",
          color: COLORS.primary.line,
          data: glucoseLevels.map((gl) => {
            const { severityScore } = gl;
            return {
              x: Date.parse(
                new Date(
                  gl.timeOfObservation ? gl.timeOfObservation : gl._meta.created
                ).toISOString()
              ),
              y: gl.value,
              marker: {
                fillColor: getAlertLevelColor(
                  getSeverityAlertLevel(severityScore)
                ),
              },
            };
          }),
        },
      ],
      caption: {
        text: isPrinting ? patientDetail : "",
      },
      time: {
        useUTC: false,
      },
    });
  }, [glucoseLevels, patientDetail, isPrinting]);

  return (
    <LineChart
      containerProps={{ id: "glucose-level-chart" }}
      options={chartConfig.options}
    />
  );
};
