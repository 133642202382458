import { bloodPressure } from "@/assets/icons/observations";
import { HealthSnapshot, Patient } from "@/domain/patient/model/types";
import {
  getBannerColorByLastObservation,
  getBannerColorBySeverityScore,
  STALE_READING_TOOLTIP_MESSAGE,
} from "@/domain/patient/view/PatientBanners/constants";
import { dateFormatter } from "@/pipes/date";
import { IconContainer, Props as IconContainerProps } from "./IconContainer";

import styles from "./styles.module.scss";
import { AlertLevel } from "@/domain/notes/model/types";

export type OwnProps = {
  patient: Patient;
  patientHealth: HealthSnapshot;
};
export type Props = OwnProps & Partial<IconContainerProps>;

export const BloodPressure: React.FC<Props> = (props) => {
  const { patient, patientHealth, ...rest } = props;
  const isObservationCompleted = () => {
    return (
      !!getBannerColorByLastObservation(
        patientHealth.observations.bloodPressure?.time
      ) || patientHealth?.observations?.bloodPressure?.isComplete
    );
  };
  const alertLevelByObservationTime = getBannerColorByLastObservation(
    patientHealth.observations.bloodPressure?.time
  );
  const getBannerColor = () => {
    const alertLevelByTime = alertLevelByObservationTime;
    if (patientHealth?.observations?.bloodPressure === undefined) {
      return AlertLevel.DISABLED_ALERT;
    } else if (alertLevelByTime) {
      return alertLevelByTime;
    }
    return getBannerColorBySeverityScore(
      patientHealth?.observations?.bloodPressure?.severityScore
    );
  };
  return (
    <IconContainer
      {...rest}
      id={`obs-blood-pressure-${patient?.id}`}
      src={bloodPressure}
      isObservationCompleted={isObservationCompleted()}
      color={getBannerColor()}
      tooltipContent={
        alertLevelByObservationTime
          ? STALE_READING_TOOLTIP_MESSAGE(
              patientHealth.observations.bloodPressure?.time
            )
          : dateFormatter({
              date: patientHealth?.observations?.bloodPressure
                ?.time as unknown as string,
              includeTime: true,
              toLocalTimezone: { enabled: true },
            })
      }
    >
      <div className="text-center">
        <p
          className={
            alertLevelByObservationTime ? styles.whiteText : styles.lead
          }
        >
          {patientHealth?.observations?.bloodPressure
            ? Math.trunc(
                patientHealth?.observations?.bloodPressure?.observation
                  ?.systolic?.value
              ) +
              "/" +
              Math.trunc(
                patientHealth?.observations?.bloodPressure?.observation
                  ?.diastolic?.value
              )
            : "-"}
        </p>
      </div>
    </IconContainer>
  );
};
