import { spirometry } from "@/assets/icons/observations";
import { HealthSnapshot, Patient } from "@/domain/patient/model/types";
import { IconContainer, Props as IconContainerProps } from "./IconContainer";
import {
  getBannerColorByLastObservation,
  getBannerColorBySeverityScore,
  STALE_READING_TOOLTIP_MESSAGE,
} from "@/domain/patient/view/PatientBanners/constants";
import { AlertLevel } from "@/domain/notes/model/types";
import styles from "@/domain/patient/view/PatientObservations/icons/styles.module.scss";
import { dateFormatter } from "@/pipes/date";

export type OwnProps = {
  patient: Patient;
  patientHealth: HealthSnapshot;
};
export type Props = OwnProps & Partial<IconContainerProps>;

export const Spirometry: React.FC<Props> = (props) => {
  const { patient, patientHealth, ...rest } = props;
  const isObservationCompleted = () => {
    return (
      !!getBannerColorByLastObservation(
        patientHealth.observations.spirometry?.time
      ) || patientHealth?.observations?.spirometry?.isComplete
    );
  };
  const alertLevelByObservationTime = getBannerColorByLastObservation(
    patientHealth.observations.spirometry?.time
  );
  const getBannerColor = () => {
    const alertLevelByTime = alertLevelByObservationTime;
    if (patientHealth?.observations?.spirometry === undefined) {
      return AlertLevel.DISABLED_ALERT;
    } else if (alertLevelByTime) {
      return alertLevelByTime;
    }
    return getBannerColorBySeverityScore(
      patientHealth?.observations?.spirometry?.severityScore
    );
  };
  return (
    <IconContainer
      {...rest}
      id={`obs-spirometry-${patient?.id}`}
      src={spirometry}
      color={getBannerColor()}
      isObservationCompleted={isObservationCompleted()}
      tooltipContent={
        alertLevelByObservationTime
          ? STALE_READING_TOOLTIP_MESSAGE(
              patientHealth.observations.spirometry?.time
            )
          : dateFormatter({
              date: patientHealth?.observations?.spirometry
                ?.time as unknown as string,
              includeTime: true,
              toLocalTimezone: { enabled: true },
            })
      }
    >
      <div className="text-center">
        {patientHealth.observations.spirometry ? (
          <div>
            <p
              style={{ fontSize: 12 }}
              className={
                alertLevelByObservationTime ? styles.whiteText : styles.lead
              }
            >
              PEF:{" "}
              {patientHealth.observations.spirometry?.observation?.pef.value}{" "}
            </p>
            <p
              style={{ fontSize: 12 }}
              className={
                alertLevelByObservationTime ? styles.whiteText : styles.lead
              }
            >
              FEV1:{" "}
              {patientHealth.observations.spirometry?.observation?.fev1.value.toFixed(
                2
              )}{" "}
            </p>
          </div>
        ) : (
          <p>-</p>
        )}
      </div>
    </IconContainer>
  );
};
