import { PaginationConfig } from "@/library/types";
import { Note } from "@/domain/notes/model/types";
import { PatientId } from "@/domain/patient/redux/types";
import * as C from "./constants";
import { AlertTableRow } from "@/domain/patient/view/AlertsTable/types";

export const createNote = (note: Note) => ({
  type: C.CREATE_NOTE,
  payload: { note },
});

export const setNotePageLimit = (limit: number) => ({
  type: C.SET_NOTE_PAGE_LIMIT,
  payload: { limit },
});

export const setNotesPaginationConfig = (
  paginationConfig: PaginationConfig
) => ({
  type: C.SET_NOTES_PAGINATIONCONFIG,
  payload: { paginationConfig },
});

export const selectNote = (note: Note) => ({
  type: C.SELECT_NOTE,
  payload: { note },
});

export const deselectNote = (note: Note) => ({
  type: C.DESELECT_NOTE,
  payload: { note },
});

export const setTargetNoteId = (id: string) => ({
  type: C.SET_TARGET_NOTE_ID,
  payload: { id },
});

export const setNotes = (notes: Note[]) => ({
  type: C.SET_NOTES,
  payload: { notes },
});

export const setPatientNotes = (patientId: PatientId, notes: Note[]) => ({
  type: C.SET_PATIENT_NOTES,
  payload: { patientId, notes },
});

export const dispatchAddPatientNote = (patientId: PatientId, note: Note) => ({
  type: C.ADD_PATIENT_NOTE,
  payload: { patientId, note },
});

export const setUnresolvedPatientNotes = (
  patientId: PatientId,
  notes: Note[]
) => ({
  type: C.SET_UNRESOLVED_PATIENT_NOTES,
  payload: { patientId, notes },
});

export const resolveNotes = (
  patientId: PatientId,
  noteIds: string[],
  rows: AlertTableRow[]
) => ({
  type: C.RESOLVE_NOTES,
  payload: { patientId, noteIds, rows },
});

export const dispatchAddRejectedNoteId = (noteId: string) => ({
  type: C.ADD_REJECTED_NOTE_ID,
  payload: { noteId },
});
