import { AssessmentTemplate } from "@/domain/assessment/model/types";

export type Trigger = DateTimeTrigger | ScheduleTrigger | EnrollmentDayTrigger;

export interface DateTimeTrigger {
  type: "DATE_TIME";
  when: string;
}

export interface EnrollmentDayTrigger {
  type: "ENROLLMENT_DAY";
  day: number;
}

export interface ScheduleTrigger {
  type: "SCHEDULE";
  occurrences: {
    day:
      | "MONDAY"
      | "TUESDAY"
      | "WEDNESDAY"
      | "THURSDAY"
      | "FRIDAY"
      | "SATURDAY"
      | "SUNDAY";
    hour: number;
    minute: number;
  }[];
}

export type Action =
  | AssessmentAction
  | NotifyAction
  | ReminderAction
  | VirtualVisitAction;

export type AssessmentAction = {
  type: "START_ASSESSMENT";
  assessmentId: string | null;
  assessmentTemplate?: AssessmentTemplate | null;
};

export type NotifyAction = {
  type: "NOTIFY";
  message: string;
};

export type ReminderAction = {
  type: "REMIND";
  message: string;
};

export type VirtualVisitAction = {
  type: "REQUEST_VIRTUAL_VISIT";
};

export interface Automation {
  triggers: Trigger[];
  actions: Action[];
  id?: string;
}

export enum AutomationActionType {
  StartAssessment = "START_ASSESSMENT",
  RequestVirtualVisit = "REQUEST_VIRTUAL_VISIT",
  Remind = "REMIND",
  Notify = "NOTIFY",
}
