export const AuditTimeLogTableHeader: string[] = [
  "Date/Time",
  "User",
  "Time Duration",
  "Billable",
  "Billable Accumulated /n Time",
  "Accumulated Time /n (Billable and Non-Billable)",
];

export const AuditDirectPatientTableHeader: string[] = [
  "Date/Time",
  "User",
  "Narrative",
];

export const AuditProfileModificationsTableHeader: string[] = [
  "Date/Time",
  "User",
  "Modification",
];

export const AuditAutomatedStatusChangeTableHeader: string[] = [
  "Date/Time",
  "Change",
];
export const AuditEquipmentTableHeader: string[] = [
  "Date/Time",
  "Kit ID",
  "Kit ICCID",
  "Action",
];
