import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import cx from "clsx";
import { Notification } from "@/components/notification/notification";

import PatientTabsNew from "@/components/Tabs";
import { Tabs as PatientTabsOld } from "@/components/Tabs/index_old";
import { Patient } from "@/domain/patient/model/types";
import {
  PatientDetailTab,
  ChartTab,
  MonitoringTab,
  ThresholdTab,
} from "@/domain/patient/view/tabs";
import { PatientDetailTabs } from "@/domain/patient/view/PatientDetails/constants";
import {
  dispatchDeselectAllPatients,
  dispatchSelectPatient,
  dispatchSetPatients,
} from "@/domain/patient/redux/actions";
import {
  selectFirstPatient,
  selectNoteCreatedOn,
} from "@/domain/patient/redux/selectors";
import { Organization } from "@/domain/organization/model/types";
import { dispatchSelectOrganization } from "@/domain/organization/redux/actions";
import { PatientBanners } from "@/domain/patient/view/PatientBanners";
import { LoadingIndicator } from "@/components/loadingIndicator/loadingIndicator";
import { PatientSummary } from "@/domain/patient/view/tabs/summary";
import { ParametersTab } from "@/domain/patient/view/tabs/parameters";
import { BillingTab } from "@/domain/patient/view/tabs/billing";
import { EquipmentTab } from "@/domain/patient/view/tabs/equipment";
import {
  attachParentEntities,
  attachPatientRequestNotes,
} from "@/domain/patient/redux/middleware";
import { selectActiveOrganization } from "@/domain/organization/redux/selectors";

import { syncPatient, setSelectedOrganization } from "./helpers";
import { PatientTabs } from "./constants";
import { Props, PatientTab, RouteParams } from "./types";

import styles from "./styles.module.scss";
import { VirtualVisit } from "@/components/virtualVisit/container";
import { calculateAge } from "@/domain/patient/view/tabs/summary/constants";
import { TwilioError } from "twilio-video/tsdef/TwilioError";
import { RemoteCareAPISync } from "@/library/core/api";
import { Http } from "@/library/core/api/http";
import { ERROR, SUCCESS } from "@/library/constants";
import { AppContext, useGlobalAppContext } from "@/components/context";
import PathwayAutomationsReadOnly from "../../../../components/pathway/automations/read-only";
import { RootState } from "@/types";
import { Note } from "@/domain/notes/model/types";
import { AuditTab } from "@/domain/patient/view/tabs/audit";

export const PatientDetails: React.FC<Props> = () => {
  const { setIsVirtualVisitRunning } = useGlobalAppContext();
  const { setIsIdleDetectorOn } = useGlobalAppContext();
  const [activeTab, setActiveTab] = useState<PatientTab>(PatientTabs.Profile);
  const [patientId, setPatientId] = useState("");
  const [token, setToken] = useState("");

  const params = useParams<RouteParams>();

  const selectedPatient = useSelector(selectFirstPatient);
  const selectedOrganization = useSelector(selectActiveOrganization);
  const noteCreatedOn = useSelector(selectNoteCreatedOn);
  const patients = useSelector((state: RootState) => state.patient.patients);
  const [patientList, setPatientList] = useState([] as Patient[]);

  const dispatch = useDispatch();
  const setOrganization = (organization: Organization) =>
    dispatch(dispatchSelectOrganization(organization));
  const setSelectedPatient = (patient: Patient) =>
    dispatch(dispatchSelectPatient(patient));
  const setPatients = (patients: Patient[]) =>
    dispatch(dispatchSetPatients(patients));
  const deselectAllPatients = () => dispatch(dispatchDeselectAllPatients());

  const fetchPatient = async () => {
    if (!patientId) return;

    LoadingIndicator.fire.show();
    try {
      const patient = await syncPatient(patientId);
      const [patientWithParentEntities] = await attachParentEntities([patient]);
      const [patientWithRequestNotes] = await attachPatientRequestNotes([
        patientWithParentEntities,
      ]);
      setSelectedPatient(patientWithRequestNotes);
      if (!selectedOrganization)
        await setSelectedOrganization(patient, setOrganization);
    } catch (error) {
      console.error(error);
    } finally {
      LoadingIndicator.fire.hide();
    }
  };

  const [isVirtualVisit, setIsVirtualVisit] = useState(false);

  const getToken = async (id: string) => {
    LoadingIndicator.fire.show();
    const response = await Http().post(
      `${RemoteCareAPISync.host}/virtual-visits`,
      { patientId: id }
    );
    if (response) LoadingIndicator.fire.hide();
    return response.data;
  };
  useEffect(() => {
    (async () => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const openVirtualVisit = params.get("virtual-visits");
      if (openVirtualVisit === "true") {
        if (patientId) {
          const data = await getToken(patientId);
          const jwt = data.room.token;
          setToken(jwt);
        }
        setIsVirtualVisit(true);
        setIsIdleDetectorOn?.(false);
      }
    })();
  }, [patientId]);
  useEffect(() => {
    (async () => {
      await fetchPatient();
    })();
  }, [patientId]);
  useEffect(() => {
    if (noteCreatedOn) setTimeout(() => fetchPatient(), 500);
  }, [noteCreatedOn]);
  useEffect(() => {
    setPatientList(patients);
    return () => {
      deselectAllPatients();
    };
  }, []);
  useEffect(() => {
    if (params?.patientId) setPatientId(params.patientId);
  }, [params?.patientId]);
  useEffect(() => {
    if (params?.activeTab) setActiveTab(params.activeTab);
  }, [params?.activeTab]);

  const handleTabClick = (tab: string | PatientTab) => {
    setActiveTab(tab as PatientTab);
    const path = "patient";
    window.history.pushState(
      null,
      ``,
      `/${path}/${patientId}/${tab.split(" ").join("_")}`
    );
  };

  if (!selectedPatient) return null;

  const principal = {
    name: `${selectedPatient.demographics.legalName.firstName} ${selectedPatient.demographics.legalName.lastName}`,
    age: calculateAge(selectedPatient.demographics.dateOfBirth),
    gender: selectedPatient.demographics.gender as "Male" | "Female",
  };

  const handleVirtualVisit = (patient?: Patient) => {
    if (patientId) {
      if (isVirtualVisit) return;
      (async () => {
        const data = await getToken(patientId);
        const jwt = data.room.token;
        setToken(jwt);
      })();
    }
    setIsVirtualVisit(true);
    setIsIdleDetectorOn?.(false);
  };

  const isCANewPatientTabsEnabled = _env_.ENABLE_NEW_PATIENT_TAB === "true";

  return (
    <AppContext.Consumer>
      {({ isVirtualVisitRunning }) => {
        const options = { token: token, room: "" };
        const handlers = {
          onError: (error: TwilioError) => {
            console.log(error);
            setIsVirtualVisit(false);
            setIsIdleDetectorOn?.(true);
            setToken("");
            Notification.notify(
              ERROR,
              "There was a problem initiating virtual visit. Please try again later!"
            );
          },
          onMultiParticipant: async (note: string, elapsedTime: number) => {
            // Work timer is suspended
            if (setIsVirtualVisitRunning) {
              setIsVirtualVisitRunning(!isVirtualVisitRunning);
            }
            const res = await Http().post(
              `${RemoteCareAPISync.host}/patients/${patientId}/notes`,
              {
                type: "CLINICAL",
                replyToIds: [],
                comment: note || "Waiting for virtual visit",
                directPatientContact: false,
                seconds: elapsedTime,
                intervention: ["VIDEO_VISIT_ATTEMPTED"],
              }
            );
            if (res.status === 202) {
              Notification.notify(
                SUCCESS,
                "Patient joined, note saved successfully!"
              );
            }
          },
          onLeave: async (
            participantJoined: boolean,
            note: string,
            elapsedTime: number
          ) => {
            try {
              const patientNotes: any = selectedPatient.notes;
              const patientRequestNoteIds = patientNotes.map((note: Note) => {
                return note.id;
              });
              if (participantJoined) {
                // Work timer un-suspends in the state where it was suspended
                if (setIsVirtualVisitRunning) {
                  setIsVirtualVisitRunning(!isVirtualVisitRunning);
                }
                const res = await Http().post(
                  `${RemoteCareAPISync.host}/patients/${patientId}/notes`,
                  {
                    type: "CLINICAL",
                    replyToIds: patientRequestNoteIds || [],
                    comment: note || "Virtual visit completed",
                    directPatientContact: true,
                    seconds: elapsedTime,
                    intervention: [
                      "VIDEO_VISIT_COMPLETED",
                      "DIRECT_PATIENT_CONTACT",
                    ],
                  }
                );
                if (res.status === 202) {
                  const filteredNotes = patientNotes.filter((note: Note) => {
                    if (note.isComplete === null || note.isComplete === false) {
                      return note;
                    }
                  });

                  if (filteredNotes.length > 0) {
                    const modifiedSelectedPatientNote = filteredNotes?.map(
                      (note: Note) => ({
                        ...note,
                        isComplete: true,
                      })
                    );
                    const modifiedSelectedPatient = {
                      ...selectedPatient,
                      notes: modifiedSelectedPatientNote,
                    };

                    const updatedPatients = patientList.map((patient) => {
                      if (patient.id === selectedPatient.id) {
                        patient = {
                          ...patient,
                          notes: modifiedSelectedPatientNote,
                        };
                      }
                      return patient;
                    });
                    setPatients(updatedPatients);
                    setSelectedPatient(modifiedSelectedPatient);
                  }
                  Notification.notify(
                    SUCCESS,
                    "Virtual visit ended, note saved successfully!"
                  );
                }
              } else {
                const res = await Http().post(
                  `${RemoteCareAPISync.host}/patients/${patientId}/notes`,
                  {
                    type: "CLINICAL",
                    replyToIds: patientRequestNoteIds || [],
                    comment: note || "Waiting for virtual visit",
                    directPatientContact: false,
                    seconds: elapsedTime,
                    intervention: ["VIDEO_VISIT_ATTEMPTED"],
                  }
                );
                if (res.status === 202) {
                  const filteredNotes = patientNotes.filter((note: Note) => {
                    if (note.isComplete === null || note.isComplete === false) {
                      return note;
                    }
                  });
                  if (filteredNotes.length > 0) {
                    const modifiedSelectedPatientNote = filteredNotes?.map(
                      (note: Note) => ({
                        ...note,
                        isComplete: true,
                      })
                    );
                    const modifiedSelectedPatient = {
                      ...selectedPatient,
                      notes: modifiedSelectedPatientNote,
                    };
                    const updatedPatients = patientList.map((patient) => {
                      if (patient.id === selectedPatient.id) {
                        patient = {
                          ...patient,
                          notes: modifiedSelectedPatientNote,
                        };
                      }
                      return patient;
                    });
                    setPatients(updatedPatients);
                    setSelectedPatient(modifiedSelectedPatient);
                  }
                  Notification.notify(
                    SUCCESS,
                    "Virtual visit ended, note saved successfully!"
                  );
                }
              }
            } catch (e) {
              Notification.notify(ERROR, "There was a problem saving notes");
            } finally {
              setIsVirtualVisit(false);
              setIsIdleDetectorOn?.(true);
              setToken("");
            }
          },
        };
        return (
          <>
            {isVirtualVisit && token && (
              <VirtualVisit
                principal={principal}
                options={options}
                handlers={handlers}
              />
            )}
            <div className={cx("px-3 pt-3 bg-white", styles.top)}>
              <PatientBanners
                onVirtualVisit={handleVirtualVisit}
                includeTimer={true}
                patients={[selectedPatient as unknown as Patient]}
                commentFieldSize={4}
              />
              <div className="ml-0" style={{ marginTop: "7px" }}>
                {isCANewPatientTabsEnabled ? (
                  <PatientTabsNew
                    tabs={PatientDetailTabs}
                    handleTabClick={handleTabClick}
                    activeTab={activeTab}
                  />
                ) : (
                  <PatientTabsOld
                    tabs={PatientDetailTabs}
                    handleTabClick={handleTabClick}
                    activeTab={activeTab}
                  />
                )}
              </div>
            </div>
            <div className={cx(styles.detailsContainer, "user-management")}>
              <div className={styles.tabContent}>
                {activeTab === PatientTabs.Profile && <PatientDetailTab />}
                {activeTab === PatientTabs.Chart && <ChartTab />}
                {activeTab === PatientTabs.Thresholds && <ThresholdTab />}
                {activeTab === PatientTabs.Monitoring && <MonitoringTab />}
                {activeTab === PatientTabs.Summary && <PatientSummary />}
                {activeTab === PatientTabs.Billing && <BillingTab />}
                {activeTab === PatientTabs.Parameters && <ParametersTab />}
                {activeTab === PatientTabs.Equipment && <EquipmentTab />}
                {activeTab === PatientTabs.Pathways && (
                  <div>
                    {patientId && (
                      <PathwayAutomationsReadOnly patientId={patientId} />
                    )}
                  </div>
                )}
                {activeTab === PatientTabs.Audit && <AuditTab />}
              </div>
            </div>
          </>
        );
      }}
    </AppContext.Consumer>
  );
};
