import React from "react";
import cx from "clsx";

import styles from "./styles.module.scss";
import { FilterItem } from "../FilterItem";
import { connect } from "react-redux";
import {
  dispatchSetFilterItems,
  dispatchSetPatientFilters,
} from "@/domain/patient/redux/actions";
import { FilterItemDetail, PatientFilters } from "@/domain/patient/model/types";
import { FILTER_TYPES } from "@/domain/patient/view/PatientsPage/constants";
import { FilterClearComponent } from "../FilterClear";
import { Dispatch } from "redux";

type FilterItemsProps = {
  onSearch: (query: object) => void;
  filters: PatientFilters;
  filterItems: FilterItemDetail[];
  setPatientFilters: (filters: PatientFilters) => void;
  setFilterItems: (filterItems: FilterItemDetail[]) => void;
};

type FilterItemsState = {
  filters: PatientFilters;
  filterItems: FilterItemDetail[];
};

class FilterItems extends React.Component<FilterItemsProps, FilterItemsState> {
  onRemove = (removedItem: FilterItemDetail) => {
    switch (removedItem.type) {
      case FILTER_TYPES.FILTER_TYPE_ALERT:
        this.props.setPatientFilters({
          ...this.props.filters,
          alert: undefined,
        });
        break;
      case FILTER_TYPES.FILTER_TYPE_LOCATION:
        this.props.setPatientFilters({
          ...this.props.filters,
          tagIds:
            this.props.filters.tagIds?.filter(
              (tagId) => tagId !== removedItem.value
            ) || [],
        });
        break;
      case FILTER_TYPES.FILTER_TYPE_PRACTICE:
        this.props.setPatientFilters({
          ...this.props.filters,
          practiceIds:
            this.props.filters.practiceIds?.filter(
              (practiceId) => practiceId !== removedItem.value
            ) || [],
        });
        break;
      case FILTER_TYPES.FILTER_TYPE_PROGRAM:
        this.props.setPatientFilters({
          ...this.props.filters,
          programIds:
            this.props.filters.programIds?.filter(
              (programId) => programId !== removedItem.value
            ) || [],
        });
        break;
      case FILTER_TYPES.FILTER_TYPE_PROVIDER:
        this.props.setPatientFilters({
          ...this.props.filters,
          providerIds:
            this.props.filters.providerIds?.filter(
              (providerId) => providerId !== removedItem.value
            ) || [],
        });
        break;
      case FILTER_TYPES.FILTER_TYPE_STATUS:
        this.props.setPatientFilters({
          ...this.props.filters,
          status:
            this.props.filters.status?.filter(
              (status) => status !== removedItem.value
            ) || [],
        });
        break;
      case FILTER_TYPES.FILTER_TYPE_USER:
        this.props.setPatientFilters({
          ...this.props.filters,
          assignedUserIds:
            this.props.filters.assignedUserIds?.filter(
              (assignedUserId) => assignedUserId !== removedItem.value
            ) || [],
          watchingUserIds:
            this.props.filters.watchingUserIds?.filter(
              (watchingUserId) => watchingUserId !== removedItem.value
            ) || [],
        });
        break;
    }

    this.props.setFilterItems(
      this.props.filterItems.filter(
        (filterItem) =>
          filterItem.type !== removedItem.type ||
          filterItem.value !== removedItem.value
      )
    );

    this.props.onSearch({});
  };

  render() {
    return (
      <div className={cx(styles["filter-items-container"])}>
        <div className={cx(styles["filter-items"])}>
          <FilterClearComponent onSearch={this.props.onSearch} />
          {this.props.filterItems &&
            this.props.filterItems.map((filterItem, index) => (
              <FilterItem
                key={index}
                item={filterItem}
                remove={this.onRemove}
              />
            ))}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setPatientFilters: (filters: PatientFilters) =>
      dispatch(dispatchSetPatientFilters(filters)),
    setFilterItems: (filterItems: FilterItemDetail[]) =>
      dispatch(dispatchSetFilterItems(filterItems)),
  };
};

const mapStateToProps = (state: {
  patient: { filters: PatientFilters; filterItems: FilterItemDetail[] };
}) => {
  return {
    filters: state.patient.filters,
    filterItems: state.patient.filterItems,
  };
};

export const FilterItemsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterItems);
