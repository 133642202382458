import { RPMComprehensiveTableHeader } from "@/pages/reports/model/reports";
import { MINUTES_PER_HOUR } from "./reports.utils";
import { Patient } from "@/domain/patient/model/types";
import { arrClone } from "@/util/clone";
import { DateTime, FixedOffsetZone } from "luxon";
import { format } from "date-fns";
import { DATE_FORMAT } from "@/pipes/date/constants";
import { dateFormatter } from "@/pipes/date";
import { Str } from "@/util/Str";

const LOOKBACK_BILLING_PERIOD_END = 1;

export const rpmComprehensive = {
  /**
   * RPM Report Type Comprehensive
   */
  header: RPMComprehensiveTableHeader,
  Heading: "RPM Billing Report Comprehensive",
  FileName: "RPM_Billing_Report_Comprehensive",
  data: (patients: Patient[]) => {
    return patients.map((patient) => {
      let eligibilityDate;
      let startDate;
      let endDate;
      //get patient's device fixed timezone offset in minutes
      // and return eligibility date in patient's timezone
      const deviceInfo = arrClone(patient?.deviceInfo || []).reverse()[0];
      const zone = FixedOffsetZone.instance(
        (deviceInfo?.timezoneOffset ?? 0) * MINUTES_PER_HOUR
      );
      if (patient.billing?.cpt99454.eligibilityDate) {
        eligibilityDate = zone
          ? format(
              new Date(
                DateTime.fromISO(patient.billing?.cpt99454.eligibilityDate, {
                  zone,
                }).toString()
              ) as Date,
              DATE_FORMAT
            )
          : format(
              new Date(patient.billing?.cpt99454.eligibilityDate) as Date,
              DATE_FORMAT
            );
      }
      if (patient.billing?.cpt99454.periodStart) {
        startDate = zone
          ? format(
              new Date(
                DateTime.fromISO(patient.billing?.cpt99454.periodStart, {
                  zone,
                }).toString()
              ) as Date,
              DATE_FORMAT
            )
          : format(
              new Date(patient.billing?.cpt99454.periodStart) as Date,
              DATE_FORMAT
            );
      }
      if (patient.billing?.cpt99454.periodEnd) {
        endDate = zone
          ? format(
              new Date(
                DateTime.fromISO(patient.billing?.cpt99454.periodEnd, {
                  zone,
                })
                  .minus({ day: LOOKBACK_BILLING_PERIOD_END })
                  .toString()
              ) as Date,
              DATE_FORMAT
            )
          : format(
              new Date(
                DateTime.fromISO(patient.billing?.cpt99454.periodEnd, {
                  zone,
                })
                  .minus({ day: LOOKBACK_BILLING_PERIOD_END })
                  .toString()
              ) as Date,
              DATE_FORMAT
            );
      }
      let observedDays = 0;
      let currentDays = 0;
      if (patient.billing?.cpt99454) {
        observedDays = patient.billing.cpt99454.observedDays;
        currentDays = patient.billing.cpt99454.currentDays;
      }
      const cpt99458_1 =
        patient.billing?.cpt99458.currentMinuteBuckets.bucket21 ?? 0;
      const cpt99458_2 =
        patient.billing?.cpt99458.currentMinuteBuckets.bucket41 ?? 0;
      const cpt99458_3 =
        patient.billing?.cpt99458.currentMinuteBuckets.bucket61 ?? 0;
      return [
        patient._meta?.id,
        patient.demographics.legalName.lastName,
        patient.demographics.legalName.firstName,
        patient.demographics.dateOfBirth
          ? dateFormatter({
              date: patient.demographics.dateOfBirth,
              isDateOfBirth: true,
            })
          : "",
        patient.encounter?.id,
        patient.mrn || "",
        Str.CapitalizeFirstLetter(patient.lifecycle.state.toLowerCase()),
        patient?.practice?.name || "",
        patient.provider
          ? `${patient.provider.firstName} ${patient.provider.lastName}, ${patient.provider.credentials} `
          : "",
        patient.tags?.find((t) => t.type === "location")?.value || "",
        patient.billing?.cpt99453.reimbursableEvents,
        startDate,
        endDate,
        eligibilityDate,
        <span
          className={
            patient.billing!.cpt99454.reimbursableEvents > 0
              ? "text-success"
              : "text-danger"
          }
        >
          {patient.billing!.cpt99454 &&
            `${observedDays} in ${currentDays} (${30 - currentDays})`}
        </span>,
        patient.billing?.cpt99454.reimbursableEvents,
        <i
          className={
            patient.billing?.cpt99457.directPatientContact
              ? "fa fa-check text-success"
              : "fa fa-times text-danger"
          }
          aria-hidden="true"
        />,
        <span
          className={
            patient.billing?.cpt99457.currentMinutes &&
            patient.billing?.cpt99457.currentMinutes >= 20
              ? "text-success"
              : "text-danger"
          }
        >
          {patient.billing?.cpt99457.currentMinutes}
        </span>,
        patient.billing?.cpt99457.reimbursableEvents,
        <span className={cpt99458_1 > 39 ? "text-success" : "text-danger"}>
          {cpt99458_1}
        </span>,
        <span className={cpt99458_2 > 59 ? "text-success" : "text-danger"}>
          {cpt99458_2}
        </span>,
        <span className={cpt99458_3 > 79 ? "text-success" : "text-danger"}>
          {cpt99458_3}
        </span>,
        patient.billing?.cpt99458.reimbursableEvents,
        patient.program || "",
        patient.primaryCondition || "",
      ];
    });
  },
};
