import produce from "immer";
import { AnyAction } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { LOGOUT } from "@/library/constants";
import { NoteState } from "./types";
import * as C from "./constants";

export const INITIAL_STATE: NoteState = {
  notes: [],
  notesByPatientId: {},
  rejectedNoteIds: [],
  unresolvedNotesByPatientId: {},
  paginationConfig: {
    totalResources: 0,
    currentPage: 1,
    lastPage: 1,
  },
  notePageLimit: 50,
  selectedNotes: [],
  targetNoteId: undefined,
};

const reducer = produce((state: NoteState, action: AnyAction) => {
  switch (action.type) {
    case "persist/REHYDRATE": {
      if (action.key !== "notes") break;

      const { rejectedNoteIds } = action.payload || {};

      state.rejectedNoteIds = rejectedNoteIds || [];

      break;
    }

    case C.CREATE_NOTE: {
      const { note } = action.payload;
      state.notes.push(note);
      break;
    }
    case C.SET_NOTE_PAGE_LIMIT: {
      const { limit } = action.payload;
      state.notePageLimit = limit;
      break;
    }
    case C.SET_NOTES_PAGINATIONCONFIG: {
      const { paginationConfig } = action.payload;
      state.paginationConfig = paginationConfig;
      break;
    }

    case C.SET_NOTES: {
      const { notes } = action.payload;
      state.notes = notes;
      break;
    }
    case C.SELECT_NOTE: {
      const { note } = action.payload;
      state.selectedNotes.push(note);
      break;
    }
    case C.DESELECT_NOTE: {
      const { note } = action.payload;

      const index = state.selectedNotes.findIndex((n) => n.id === note.id);

      if (index > -1) state.selectedNotes.splice(index, 1);
      break;
    }
    case C.SET_TARGET_NOTE_ID: {
      const { id } = action.payload;
      state.targetNoteId = id;
      break;
    }

    case C.ADD_PATIENT_NOTE: {
      const { patientId, note } = action.payload;
      if (!state.notesByPatientId[patientId])
        state.notesByPatientId[patientId] = [];

      state.notesByPatientId[patientId].push(note);
      break;
    }

    case C.SET_PATIENT_NOTES: {
      const { patientId, notes } = action.payload;
      state.notesByPatientId[patientId] = notes;
      break;
    }

    case C.SET_UNRESOLVED_PATIENT_NOTES: {
      const { patientId, notes } = action.payload;
      state.unresolvedNotesByPatientId[patientId] = notes;
      break;
    }

    case C.RESOLVE_NOTES: {
      const { patientId, noteIds } = action.payload;
      const unresolvedNotes = state.unresolvedNotesByPatientId[patientId] || [];
      state.unresolvedNotesByPatientId[patientId] = unresolvedNotes.filter(
        (patientNote) => {
          const resolved = noteIds.includes(patientNote.id);
          if (!resolved) return patientNote;
        }
      );
      const allNotes = state.notesByPatientId[patientId] || [];
      state.notesByPatientId[patientId] = allNotes.map((patientNote) => {
        const resolved = noteIds.includes(patientNote.id);
        if (!resolved) patientNote.isComplete = true;
        return patientNote;
      });
      break;
    }

    case C.ADD_REJECTED_NOTE_ID: {
      const { noteId } = action.payload;

      state.rejectedNoteIds.push(noteId);

      break;
    }

    case LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}, INITIAL_STATE);

const config = {
  key: "notes",
  storage,
  blacklist: ["notes", "notesByPatientId", "unresolvedNotesByPatientId"],
};

export const noteReducer = persistReducer(config, reducer);
