import React, { MouseEvent, KeyboardEvent, useState, useEffect } from "react";
import { Placeholder } from "rsuite";
import cx from "clsx";
import { Patient } from "@/domain/patient/model/types";
import BannerMenu from "@/domain/patient/view/BannerMenu";
import { VideoVisitRequest } from "./icons/VideoVisitRequest";
import { CallRequest } from "./icons/CallRequest";
import { Assessment } from "./icons/Assessment";
import { HeartRate } from "./icons/HeartRate";
import { BloodPressure } from "./icons/BloodPressure";
import { BloodOxygenLevel } from "./icons/BloodOxygenLevel";
import { Weight } from "./icons/Weight";
import { BloodGlucose } from "./icons/BloodGlucose";
import { Temperature } from "./icons/Temperature";
import { Spirometry } from "./icons/Spirometry";
import styles from "./styles.module.scss";
import { Note } from "@/domain/notes/model/types";
import { useDispatch } from "react-redux";
import { dispatchUpdatePatient } from "../../redux/actions";
import { attachPatientRequestNotes } from "../../redux/middleware";

interface Props {
  patient: Patient;
  onClick: (event: MouseEvent | KeyboardEvent) => void;
  includeTimerOptions?: boolean;
  onVirtualVisit: () => void;
}

export const PatientObservations: React.FC<Props> = (props) => {
  const { onClick, patient, onVirtualVisit } = props;
  const { health: patientHealth } = patient || {};
  const [notes, setNotes] = useState([] as Note[]);
  const dispatch = useDispatch();

  const renderPlaceholder = () => (
    <div className="d-flex flex-row flex-wrap bd-highlight">
      {Array(10)
        .fill(
          <Placeholder.Graph
            active
            className={cx(styles.placeholderHeight, " p-1")}
          />
        )
        .map((placeholder, index) => (
          <div
            key={`placeholder-${index}`}
            className={cx("p-1", "bd-highlight", styles.obsItem)}
            onClick={onClick}
          >
            {placeholder}
          </div>
        ))}
    </div>
  );

  useEffect(() => {
    if (notes.length) dispatch(dispatchUpdatePatient({ ...patient, notes }));
  }, [notes]);

  if (!patient || !patientHealth) return renderPlaceholder();

  const commonProps = {
    patient,
    patientHealth,
    onClick,
  };

  return (
    <div
      className={cx("d-flex flex-row bd-highlight", styles.observationSection)}
    >
      <div className={styles.obsItems}>
        <VideoVisitRequest onVirtualVisit={onVirtualVisit} {...commonProps} />
        <CallRequest disabled {...commonProps} />
        <Assessment {...commonProps} />
        <HeartRate {...commonProps} />
        <BloodPressure {...commonProps} />
        <BloodOxygenLevel {...commonProps} />
        <Weight {...commonProps} />
        <BloodGlucose {...commonProps} />
        <Temperature {...commonProps} />
        <Spirometry {...commonProps} />
      </div>

      <div className={styles.bannerMenu}>
        <BannerMenu
          includeTimerOptions={props.includeTimerOptions}
          patient={props.patient}
        />
      </div>
    </div>
  );
};
