export const RPMTableHeader: string[] = [
  "Last Name",
  "First Name",
  "DOB",
  "MRN",
  "Patient Status",
  "Entity / Branch",
  "Provider",
  "Program",
  "Location",
  "Eligibility Date",
  "CPT99453",
  "CPT99454",
  "CPT99457",
  "CPT99458",
];

export const RPMComprehensiveTableHeader: string[] = [
  "Last Name",
  "First Name",
  "DOB",
  "ID",
  "MRN",
  "Patient Status",
  "Entity / Branch",
  "Provider",
  "Location",
  "CPT99453",
  "Start",
  "End",
  "Eligibility Date",
  "CPT99454",
  "Billing",
  "Contact",
  "Minutes",
  "Billing",
  "(1)",
  "(2)",
  "(3)",
  "Billing",
  "Program",
  "Diagnosis",
];
export const RPMComprehensiveCSVHeader: string[] = [
  "Last Name",
  "First Name",
  "DOB",
  "ID",
  "MRN",
  "Patient Status",
  "Entity / Branch",
  "Provider",
  "Location",
  "CPT99453",
  "CPT99454 Start",
  "CPT99454 End",
  "CPT99454 Eligibility Date",
  "CPT99454",
  "CPT99454 Billing",
  "CPT99457 Contact",
  "CPT99457 Minutes",
  "CPT99457 Billing",
  "CPT99458 1",
  "CPT99458 2",
  "CPT99458 3",
  "CPT99458 Billing",
  "Program",
  "Diagnosis",
];

export const AlertTableHeader: string[] = [
  "Last Name",
  "First Name",
  "DOB",
  "MRN",
  "Primary DX",
  "Secondary DX",
  "Entity / Branch",
  "Provider",
  "Care Team Member",
  "Red Alerts",
  "Yellow Attentions",
];

export enum ReportTypes {
  rpm = "RPM",
  rpmComprehensive = "RPM COMPREHENSIVE",
  alert = "ALERT",
  outcomes = "OUTCOMES",
  patientStatus = "PATIENT STATUS",
}

export enum ReportTypesLabel {
  rpm = "RPM Billing Report Summary",
  rpmComprehensive = "RPM Billing Report Comprehensive",
  alert = "RPM Alert",
  outcomes = "RPM Outcomes",
  patientStatus = "Patient Status",
}
