import React, { useMemo, useState } from "react";
import { TooltipFormatterContextObject } from "highcharts";

import LineChart from "@/components/charts/HighChart.component";
import {
  COLORS,
  makeChartOptions,
} from "@/domain/patient/view/charts/constants";
import { parseTooltipDate } from "@/domain/patient/view/tabs/monitoring/helpers";
import {
  getAlertLevelColor,
  getSeverityAlertLevel,
} from "@/domain/notes/model/constants";
import { Props } from "./types";
import {
  selectMonitoringStartDate,
  selectMonitoringEndDate,
} from "@/domain/patient/redux/selectors";
import { useSelector } from "react-redux";
import {
  ObservationsUnitAbbrevation,
  ObservationType,
} from "@/domain/observations/types";
import { displayObsUnit } from "@/domain/observations/helpers";

export const PulseOximetryChart: React.FC<Props> = (props) => {
  const { pulseOximetries, patientDetail } = props;
  const isoStartDate = useSelector(selectMonitoringStartDate);
  const isoEndDate = useSelector(selectMonitoringEndDate);
  const [isPrinting, setIsPrinting] = useState(false);

  const chartConfig = useMemo(() => {
    return makeChartOptions({
      yTitle: displayObsUnit(ObservationType.PulseOximetry) as string,
      events: {
        beforePrint: async () => {
          setIsPrinting(true);
        },
        afterPrint: () => {
          setIsPrinting(false);
        },
      },
      legend: {
        align: "center",
        layout: "vertical",
        y: 10,
      },
      xAxis: {
        min: isoStartDate,
        max: isoEndDate,
      },
      tooltip: {
        useHTML: true,
        valueSuffix: ObservationsUnitAbbrevation.PULSE_OXIMETRY,
        formatter: function (this: TooltipFormatterContextObject) {
          return parseTooltipDate(
            ObservationType.PulseOximetry,
            this,
            ObservationsUnitAbbrevation.PULSE_OXIMETRY
          );
        },
      },
      series: [
        {
          name: "Pulse Oximetry",
          color: COLORS.primary.line,
          data: pulseOximetries.map((po) => {
            const { severityScore } = po;
            return {
              x: Date.parse(
                new Date(
                  po.timeOfObservation ? po.timeOfObservation : po._meta.created
                ).toISOString()
              ),
              y: po.value,
              marker: {
                fillColor: getAlertLevelColor(
                  getSeverityAlertLevel(severityScore)
                ),
              },
            };
          }),
        },
      ],
      caption: {
        text: isPrinting ? patientDetail : "",
      },
      time: {
        useUTC: false,
      },
    });
  }, [pulseOximetries, patientDetail, isPrinting]);

  return (
    <LineChart
      containerProps={{ id: "pulse-oximetry-chart" }}
      options={chartConfig.options}
    />
  );
};

export default PulseOximetryChart;
