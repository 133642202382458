import { FunctionComponent } from "react";
import cx from "clsx";
import SearchIcon from "@mui/icons-material/Search";

import styles from "./styles.module.scss";

type SearchComponentProps = {
  onSearch: (query: {}) => any;
  pageLimit: number;
  value?: string;
  placeholder?: string;
  containerClassName?: string;
};
export const Search: FunctionComponent<SearchComponentProps> = (props) => {
  const { pageLimit, onSearch, containerClassName, children } = props;

  const execQuery = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    onSearch({
      name: value,
      limit: pageLimit,
    });
  };

  const isPatientSlidingPaneEnabled =
    _env_.ENABLE_PATIENT_SLIDING_PANE === "true";

  return (
    <div className={cx(styles.search, containerClassName)}>
      {!isPatientSlidingPaneEnabled && (
        <>
          <h6 className={styles.label}>Search</h6>
          <div className={styles.content}>
            <input
              value={props.value}
              type="text"
              className={cx(styles.searchBox, "form-control search")}
              placeholder={
                props.placeholder
                  ? props.placeholder
                  : "Enter name or display name"
              }
              onChange={execQuery}
            />
          </div>
          {children}
        </>
      )}
      {isPatientSlidingPaneEnabled && (
        <label className={cx(styles["search-box-label"])}>
          <div className={cx(styles["search-icon"])}>
            <SearchIcon />
          </div>
          <input
            value={props.value}
            type="text"
            className={cx(
              "form-control",
              "form-control-with-icon",
              styles["search-box"]
            )}
            placeholder={
              props.placeholder ? props.placeholder : "Search by ID/MRN"
            }
            onChange={execQuery}
          />
        </label>
      )}
    </div>
  );
};
