import React, { useState, useEffect } from "react";
import RSModal from "rsuite/Modal";
import { FormikHelpers } from "formik";

import { KitShipmentForm } from "@/domain/kits/view/KitShipmentForm";
import { KitShipmentFormValues } from "@/domain/kits/view/KitShipmentForm/types";
import { Patient } from "@/domain/patient/model/types";
import { DeviceModel } from "@/domain/kits/models/device.model";
import {
  PatientDevice,
  ServiceLevel,
} from "@/domain/kits/models/device.interface";
import { Workflows } from "../KitShipmentForm/constants";

export type Props = {
  open: boolean;
  onSubmit: (
    values: KitShipmentFormValues,
    form: FormikHelpers<KitShipmentFormValues>
  ) => void;
  onClose: () => void;
  patient: Patient;
};

export const KitReturnModal: React.FC<Props> = ({
  open,
  onSubmit,
  onClose,
  patient,
}) => {
  const [patientDevice, setPatientDevice] = useState<PatientDevice>();

  useEffect(() => {
    const fetchPatientDevices = async () => {
      if (!patient) return;
      const deviceModels = await DeviceModel.fetchByPatientId(patient.id!);
      const devices = deviceModels.map((dm) => dm.pluckAll());
      if (devices.length) {
        setPatientDevice(devices[0]);
      }
    };

    fetchPatientDevices();
  }, []);

  const getServiceLevel = (): ServiceLevel | undefined => {
    return patient.deviceInfo && patient.deviceInfo?.length > 0
      ? patient.deviceInfo[0].serviceLevel
      : undefined;
  };

  return (
    <>
      {patientDevice && (
        <RSModal
          size="lg"
          backdrop
          overflow={false}
          open={open}
          onClose={onClose}
        >
          <RSModal.Header closeButton>
            <RSModal.Title>Return kit and complete patient</RSModal.Title>
          </RSModal.Header>
          <RSModal.Body>
            <KitShipmentForm
              workflow={Workflows.return}
              onSubmit={onSubmit}
              onCancel={onClose}
              patient={patient}
              serviceLevel={getServiceLevel()}
              selectedDevice={patientDevice}
            />
          </RSModal.Body>
        </RSModal>
      )}{" "}
    </>
  );
};
