import { FunctionComponent } from "react";
import { IconButton } from "rsuite";
import cx from "clsx";
import FilterListIcon from "@mui/icons-material/FilterList";

import styles from "./styles.module.scss";

type FilterProps = {
  filterText: string;
  containerClassName?: string;
  openPane: () => void;
};
export const FilterButton: FunctionComponent<FilterProps> = (props) => {
  const { filterText, containerClassName, openPane } = props;

  return (
    <div className={cx(styles["filter-icon-parent"], containerClassName)}>
      <div className={cx(styles["filter-icon"])}>
        <FilterListIcon />
      </div>
      <IconButton
        appearance="ghost"
        onClick={() => {
          openPane();
        }}
        placement="left"
      >
        <span className={cx(styles["filter-button-text"])}>{filterText}</span>
      </IconButton>
    </div>
  );
};
